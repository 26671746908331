import React from 'react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import { IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';

import ProductDetails from 'components/cards/shared/ProductDetails';
import { SplitedPrice } from 'components/prices';
import { Icon } from 'components/shared';
import FlrQuantity from 'components/shared/form-elements/quantity/FlrQuantity';
import { TextBody2 } from 'components/shared/text';
import { GTM } from 'controllers';
import { CartItem, getPriceByQty } from 'models';
import messages from 'translations/cart/expired';
import { getProductImage } from 'utils/helpers';
import { Text } from 'components/layout/content/Text';

import styles from './styles';

interface IProps {
  removeDisabled?: boolean;
  expired?: boolean;
  max?: number;
  item: CartItem;
  onChangeAmount?: (qty: number) => void;
  onRemove?: (i?: any) => void;
  withDiscount?: boolean;
  withItemRemove?: boolean;
  classes: any;
  refreshQtyFromProps?: boolean;
}

const CartGroupCardItem: React.FC<IProps> = ({
  expired,
  removeDisabled,
  max,
  item,
  onChangeAmount,
  onRemove,
  withDiscount,
  classes,
  refreshQtyFromProps
}) => {
  const handleRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onRemove) {
      onRemove();
      GTM.trackRemoveItemFromCart(item);
    }
    e.stopPropagation();
  };

  const onDecrementRemove = () => {
    if (onRemove) {
      GTM.trackRemoveQtyOfItemFromCart(item, 0);
      onRemove();
    }
  };

  const handleChange = (qty: number) => {
    if (qty < item.qty) {
      GTM.trackRemoveQtyOfItemFromCart(item, qty);
    } else if (qty > item.qty) {
      GTM.trackChangeQtyInCart(item, qty, getPriceByQty(qty, item.offer.price));
    }
    if (onChangeAmount) {
      onChangeAmount(qty);
    }
  };

  const isArchived = !item.offer || item.product.archived;
  const priceDiff = item.originalPrice ? Math.round((item.originalPrice - item.price) * 100) / 100 : 0;
  const stockDeficit = item.originalRequest.qty - item.offer.inStock;
  const isOutOfStock = item.offer && item.offer.inStock < 1;
  const isPreOrder = item.offer.preOrder || item.offer.extraPrice;
  const isSpecialOrder = item.special;
  const hasAdditionalInfo =
    expired && (isArchived || priceDiff !== 0 || stockDeficit > 0 || isOutOfStock || isPreOrder);

  return (
    <div className={classes.tableItem}>
      <div className={classes.tableItemInner}>
        <div className={classes.productImageCell}>
          <img src={getProductImage(item.product)} alt={item.product.displayName} className={classes.productImage} />
        </div>
        <div className={clsx(classes.container, { [classes.gray]: isPreOrder })}>
          <ProductDetails
            product={item.product}
            className={clsx(classes.productName, { [classes.disabled]: isArchived }, 'nowrap')}
            classNameTitle={classes.title}
            marginRight="0"
          />
          <div className={clsx(classes.priceContainer, { [classes.specialOrderSign]: isSpecialOrder })}>
            {isSpecialOrder ? <Icon opacity={1} type="fire" size={20} style={{ marginRight: 6 }} /> : null}
            <SplitedPrice
              value={item.price}
              fontColor={isArchived || isPreOrder ? 'helperBlack' : 'textSecondary'}
              fontSize="large"
              fontSizeDecimal={'h5'}
              prefix={isPreOrder ? '~' : ''}
            />
          </div>
          <div className={classes.quantityContainer}>
            {isArchived ? (
              <FlrQuantity value={item.qty} disabled />
            ) : (
              <FlrQuantity
                dense
                value={item.qty}
                inPackageCount={item.product.inPackageCount}
                min={item.getMinQty}
                onDecrement={item.qty === item.product.inPackageCount ? onDecrementRemove : undefined}
                max={max || item.qty}
                onChange={handleChange}
                disableMaxLimit={!isPreOrder}
                refreshQtyFromProps={refreshQtyFromProps}
              />
            )}
          </div>
          <div className={classes.inPackageCountContainer}>
            <Text fontSize="body1" fontColor={isArchived || isPreOrder ? 'helperBlack' : 'textSecondary'}>
              {item.product.inPackageCount}
            </Text>
          </div>
          <div className={classes.discountContainer}>
            {withDiscount && (
              <SplitedPrice
                fontColor={isArchived || isPreOrder ? 'helperBlack' : 'textSecondary'}
                fontSize={'subtitle1'}
                value={item.discount}
              />
            )}
          </div>
          <div className={classes.orderItemSubtotal}>
            <SplitedPrice
              value={item.total}
              fontColor={isArchived || isPreOrder ? 'helperBlack' : 'textSecondary'}
              fontSize="large"
              fontSizeDecimal="h5"
              prefix={isPreOrder ? '~' : ''}
            />
          </div>
          <IconButton onClick={(e) => !removeDisabled && handleRemove(e)} size="large" className={classes.removeIcon}>
            <Icon type="trash" size={20} opacity={1} />
          </IconButton>
        </div>
        {hasAdditionalInfo && (
          <div className={classes.productInfoCell}>
            {priceDiff !== 0 && (
              <div className={classes.priceDiffContainer}>
                <TextBody2 className={priceDiff > 0 ? classes.green : classes.red}>
                  <FormattedMessage
                    tagName={React.Fragment}
                    values={{
                      amount: Math.abs(priceDiff),
                      price: (...chunks: any[]) => {
                        const price = parseFloat(chunks.pop());
                        return (
                          <SplitedPrice
                            key={`cart-item-price-change-${item.offer.id}`}
                            value={price}
                            bold={false}
                            decimalSameSize={true}
                            fontSize={'body2'}
                          />
                        );
                      }
                    }}
                    {...messages[priceDiff > 0 ? 'priceDecreased' : 'priceIncreased']}
                  />
                </TextBody2>
              </div>
            )}
            {stockDeficit > 0 ? (
              <TextBody2 className={classes.red}>
                <FormattedMessage {...messages.qtyDecreased} values={{ amount: stockDeficit }} />
              </TextBody2>
            ) : null}
            {isOutOfStock ? <TextBody2 color={'error'}>{messages.outOfStock.defaultMessage}</TextBody2> : null}
            {isOutOfStock && isPreOrder ? (
              <div className={classes.outOfStockContainer}>
                <Icon type={'box'} size={24} className={classes.black} />
                <TextBody2>{messages.preOrderAvailable.defaultMessage}</TextBody2>
              </div>
            ) : null}
            {isArchived ? <TextBody2 color={'error'}>{messages.notAvailable.defaultMessage}</TextBody2> : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles<any>(styles)(CartGroupCardItem);
