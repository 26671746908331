import clsx from 'clsx';
import { IconButton, Link } from '@mui/material';

import Icon from 'components/shared/Icon';

import classes from './SocialLinks.module.scss';

export interface ISocialIcon {
  icon: string;
  title: string;
  link: string;
  disabled?: boolean;
}
const chatId = 'u816689430-1596692233vid50ce5bac-86f3-4129-a85f-f7cf6c85edbb';
const socialIconsDefault: ISocialIcon[] = [
  { icon: 'viber', title: 'Viber', link: `viber://pa?chatURI=floroteka&context=${chatId}` },
  { icon: 'telegram', title: 'Telegram', link: 'https://t.me/FlorotekaBot' },
  { icon: 'call', title: 'WhatsApp', link: 'whatsapp://send?phone=+120345678910', disabled: true }
];

interface IProps {
  icons?: ISocialIcon[];
  dark?: boolean;
  classNameWrapper?: string;
}

const SocialLinks = ({ icons = socialIconsDefault, dark, classNameWrapper }: IProps) => (
  <div className={classNameWrapper}>
    {icons.map((item, index) => (
      <IconButton
        className={clsx(classes.socialIconButton, {
          [classes.socialIconButtonDark]: dark
        })}
        key={`social${index}`}
        component={Link}
        href={item.link}
        disabled={item.disabled}
        size="large"
        target="_blank"
      >
        <Icon type={item.icon} size={24} opacity={dark ? 1 : 0.8} />
      </IconButton>
    ))}
  </div>
);

export { SocialLinks };
