import { OfficesMap } from 'components/shared/contacts/OfficesMap';
import Icon from 'components/shared/Icon';
import { TextBody1, TextSubTitle, TitleH1, TitleH2 } from 'components/shared/text';
import { IPhoneContact, phoneDefaults } from 'shared/constants';
import messages from 'translations/main/contacts';

import { WorkingHours } from '../WorkingHours';
import { ContactsForm } from './ContactsForm';

import classes from './MainContacts.module.scss';

const MainContacts = () => {
  return (
    <section>
      <div className={classes.root}>
        <div className={classes.mapColumn}>
          <div className={classes.wrapper}>
            <div className={classes.contactsWrapper}>
              <TitleH1 className={classes.title} color={'inherit'}>
                {messages.title.defaultMessage}
              </TitleH1>

              <div className={classes.contactsList}>
                <div className={classes.contactsItem}>
                  <Icon
                    className={classes.contactsItemIcon}
                    type="phoneContact"
                    width={20}
                    height={20}
                    withBackground
                    opacity={1}
                  />
                  <TitleH2 className={classes.contactsItemTitle}>{messages.phoneLabel.defaultMessage}</TitleH2>
                  {phoneDefaults
                    ? phoneDefaults.map((phoneItem: IPhoneContact, index: number) => (
                        <div className={classes.contactsPhone} key={index}>
                          <TextBody1 key={index} className={classes.city}>
                            {phoneItem.additionalText}
                          </TextBody1>
                          <a href={`tel:${phoneItem.phone}`}>
                            <TextBody1 key={index}>{phoneItem.phone}</TextBody1>
                          </a>
                        </div>
                      ))
                    : null}
                </div>

                <div className={classes.contactsItem}>
                  <Icon
                    className={classes.contactsItemIcon}
                    type="marker"
                    width={14}
                    height={20}
                    withBackground
                    opacity={1}
                  />
                  <TitleH2 className={classes.contactsItemTitle}>{messages.addressLabel.defaultMessage}</TitleH2>
                  <div className={classes.cities}>
                    <div className={classes.cityItem}>
                      <TextBody1>м. Київ</TextBody1>
                      <TextBody1>вул. Старосільська 1</TextBody1>
                    </div>
                    <div className={classes.cityItem}>
                      <TextBody1>м. Львів</TextBody1>
                      <TextBody1>вул. Грабовського, 11</TextBody1>
                    </div>
                  </div>
                </div>

                <div className={classes.contactsItem}>
                  <Icon
                    className={classes.contactsItemIcon}
                    type="clock"
                    width={21}
                    height={21}
                    withBackground
                    opacity={1}
                  />
                  <TitleH2 className={classes.contactsItemTitle}>{messages.workHoursLabel.defaultMessage}</TitleH2>
                  <WorkingHours withoutIcon />
                </div>
              </div>
            </div>
          </div>
          <OfficesMap />
        </div>

        <div className={classes.contactForm}>
          <TitleH2 className={classes.formTitle}>{messages.formTitle.defaultMessage}</TitleH2>
          <TextSubTitle color={'inherit'} className={classes.formSubTitle} component={'p'}>
            {messages.formCaption.defaultMessage}
          </TextSubTitle>

          <ContactsForm />
        </div>
      </div>
    </section>
  );
};

export { MainContacts };
