import { FC, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { connect, MapDispatchToProps, MapStateToProps, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { generatePath } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import clsx from 'clsx';
import { debounce } from 'lodash';
import groupBy from 'lodash/groupBy';
import {
  Button,
  CircularProgress,
  ClickAwayListener,
  Grid,
  Hidden,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { withStyles } from '@mui/styles';

import Icon from 'components/shared/Icon';
import { Link2, TextSubTitle } from 'components/shared/text';
import {
  DetailedProduct,
  IFilterOptions,
  IFilterOptionsRequest,
  IFilterProductCatalogCategory,
  IFilterProductType,
  searchProductAttributes
} from 'models';
import { prepareFilterCategoriesStateChildren } from 'pages/catalog/layout/left-bar/options/options-tools';
import { baseUrl, defaultCatalogProductType } from 'shared/constants';
import { countriesMap } from 'shared/countriesMap';
import { ATTRIBUTE_COUNTRY_PATH, ATTRIBUTE_HEIGHT_PATH } from 'shared/filters';
import {
  catalogSetFilter,
  fetchCatalogAsync,
  filterAndSortCatalog,
  ICatalogData,
  IFilterProps,
  IFiltersData,
  ISearchData,
  searchAsync,
  selectProductAsync,
  toggleDetails,
  unselectProduct
} from 'store/catalog/actions';
import {
  getCatalogData,
  getCatalogFilters,
  getCatalogSort,
  getSearchDataData,
  getSearchDataLoadedState,
  getSearchDataLoadingState
} from 'store/catalog/selectors';
import { fetchFilterOptionsAsync } from 'store/filter-options/actions';
import { getFilterOptionsData } from 'store/filter-options/selectors';
import { IApplicationState } from 'store/reducers';
import messages from 'translations/catalog/search';
import messagesHeader from 'translations/layout/header';
import { filterJsonToString, filtersToString, filterStringToJson } from 'utils/filters';

import styles from './styles';

interface ISearchItem {
  label?: string;
  name: string;
  type: string;
  typeName?: string;
  subType?: string;
  slug?: string; // link
  productId?: string; // link
}

interface IOwnProps {
  // put own props here
  classes?: any;
  types: false | IFilterProductType[];
  categoryType: string;
  categoryTypeData: any;
  toggleDrawer?: () => void;
  mobileDevice?: boolean;
}

interface IStateProps {
  filterOptionsData: IFilterOptions;
  searchData: ISearchData;
  searchDataLoading: boolean;
  searchDataLoaded: boolean;
  sort: string;
  locale: string;
  catalogDocs: ICatalogData;
  catalogFilters: IFiltersData;
}

interface IDispatchProps {
  unselectProduct: typeof unselectProduct;
  selectProduct: typeof selectProductAsync.request;
  filterAndSortCatalog: typeof filterAndSortCatalog;
  fetchSearchData: typeof searchAsync.request;
}

type IProps = IOwnProps & IStateProps & IDispatchProps;

const typeIcon = {
  history: 'history',
  product: 'search',
  attribute: 'listMarked'
};

const pathes = {
  'attributes.catalogCategory': 'category',
  'attributes.height': 'height'
};

const SearchControl: FC<IProps> = ({
  categoryType,
  filterOptionsData,
  unselectProduct: deselectProduct,
  selectProduct,
  searchData,
  searchDataLoading,
  searchDataLoaded,
  fetchSearchData,
  sort,
  locale,
  filterAndSortCatalog: catalogFiltersAndSortUpdate,
  types,
  classes,
  toggleDrawer,
  mobileDevice,
  catalogDocs,
  catalogFilters
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme: Theme = useTheme();
  const screenDevice = useMediaQuery(theme.breakpoints.down('md'));

  const [options, setOptions] = useState<ISearchItem[]>([{ label: '', name: '', type: '', slug: '', productId: '' }]);

  const [isResultItem, setIsResultItem] = useState(false);
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState<ISearchItem | null>((options && options[0]) || null);
  const [searchHistory, setSearchHistory] = useState(JSON.parse(localStorage.getItem('searchHistory') || '[]'));

  const refInput = useRef<HTMLInputElement>(null);

  const handleOpenResults = () => {
    setTimeout(() => setOpen(true), 0);
  };
  const handleCloseResults = (e: any) => {
    if (e.target.id !== 'clear-history') {
      setOpen(false);
    }
  };

  const fetchSearchDataThrottled = useMemo(
    () =>
      debounce((request: { input: string; categoryType: string }, callback: (results?: ISearchItem[]) => void) => {
        fetchSearchData({ searchString: request.input.replace('+', '').trim(), productTypeCode: request.categoryType });
      }, 500),
    [fetchSearchData]
  );

  const handleFetchData = () => {
    handleOpenResults();
    if (inputValue) {
      fetchSearchData({ searchString: inputValue.trim(), productTypeCode: categoryType });
    }
  };

  useEffect(() => {
    const sortedOptions: ISearchItem[] = Object.values(searchData.resultsOrder).reduce(
      (acc: ISearchItem[], orderKey: string) => {
        if (orderKey === 'product' && searchData.results.products && searchData.results.products.length) {
          const temp = searchData.results.products.map((product) => {
            let searchResName = product.fullName;
            if (product.attributes) {
              searchProductAttributes.forEach((attrName) => {
                if (product.attributes[attrName]) {
                  searchResName = `${searchResName} | `;
                  searchResName = `${searchResName}${product.attributes[attrName].value}`;
                }
              });
            }
            return {
              name: searchResName,
              type: 'product',
              slug: product.alias,
              productId: product.id
            };
          });
          return [...acc, ...temp];
        }

        if (searchData.results[orderKey] && searchData.results[orderKey].length) {
          const res = searchData.results[orderKey].map((resultsItem: any) => {
            return {
              label:
                orderKey === 'country' && countriesMap[resultsItem.value]
                  ? countriesMap[resultsItem.value].name
                  : resultsItem.label,
              name: resultsItem.value,
              type: `attributes.${orderKey}`,
              typeName: resultsItem.name,
              slug: resultsItem.alias
            };
          });
          return [...acc, ...res];
        }
        return acc;
      },
      []
    );
    setOptions(sortedOptions || []);
  }, [searchData, categoryType]);

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    if (!isResultItem && inputValue.length > 1) {
      fetchSearchDataThrottled({ input: inputValue, categoryType }, (results?: ISearchItem[]) => {
        if (active) {
          setOptions(results || []);
        }
      });
    } else {
      setIsResultItem(false);
    }

    return () => {
      active = false;
    };
  }, [inputValue, fetchSearchDataThrottled, categoryType, isResultItem]);

  const selectItem = (event: object, valueItem: ISearchItem, reason: string) => {
    let isResultItemTemp = false;

    if (!valueItem) {
      return;
    }
    if (reason === 'createOption') {
      setOpen(true);
      return;
    }

    const isHistoryItem = valueItem.type === 'history';
    const isProductItem = valueItem.type === 'product';

    if (isHistoryItem) {
      handleOpenResults();
      setInputValue(valueItem.label || valueItem.name);
    }

    const existsIndex = searchHistory.findIndex((item: ISearchItem) =>
      isHistoryItem ? item.name === valueItem.name : item.name === inputValue
    );
    if (existsIndex > -1) {
      searchHistory.splice(existsIndex, 1);
    }

    const historyLabel = isHistoryItem ? valueItem.name || valueItem : inputValue;
    const newSearchHistory = [...searchHistory];

    if (historyLabel) {
      newSearchHistory.unshift({ label: historyLabel, name: historyLabel, type: 'history' });
    }
    if (searchHistory.length > 2) {
      newSearchHistory.pop();
    }

    setSearchHistory(newSearchHistory);
    localStorage.setItem('searchHistory', JSON.stringify(newSearchHistory));

    let filters: IFilterProps = [];
    if (!isHistoryItem && !isProductItem) {
      deselectProduct();

      const path = pathes[valueItem.type] ? pathes[valueItem.type] : valueItem.type;
      const rule = valueItem.type === ATTRIBUTE_HEIGHT_PATH ? 'range' : 'include';
      const filterValue =
        valueItem.type === ATTRIBUTE_HEIGHT_PATH ? `${valueItem.name}-${valueItem.name}` : valueItem.name;

      filters = [
        {
          path,
          rule,
          value: filterValue,
          label: valueItem.label || valueItem.name || filterValue
        }
      ];

      if (path === ATTRIBUTE_COUNTRY_PATH && countriesMap[filterValue]) {
        filters[0].label = countriesMap[filterValue].name;
      }

      // prepare children items if exists
      if (path === 'category') {
        const itemCategory = findCategory(filterValue, filterOptionsData.catalogCategory);
        if (itemCategory) {
          filters = prepareFilterCategoriesStateChildren(itemCategory, true, []);
        }
      }
    }

    let product: DetailedProduct | undefined;
    if (isProductItem) {
      product = searchData.results.products
        ? searchData.results.products.find((item) => valueItem.productId === item.id)
        : undefined;
      if (product) {
        selectProduct(product);
        if (mobileDevice) {
          dispatch(toggleDetails(true));
        }
      }
    }

    if (!isHistoryItem) {
      isResultItemTemp = true;
      const typeObject = types && types.find((item) => item.code === categoryType);
      // check if chosen product is in the filtered catalog
      const isProductExist = catalogDocs.rows.some((arr: any) => arr.some((i: any) => product && i.id === product.id));

      let str = '';
      if (filters.length && filters.some((i) => i.path === 'category')) {
        const filterMap = filtersToString(filters.filter((i) => i.path === 'category'));
        str = filterJsonToString(filterMap);
      }
      if (isProductExist) {
        const {
          inStock,
          preOrder,
          discount,
          supplier: filterSupplier,
          specialOffers,
          transitOffers,
          specialTransitOffers,
          deliveryRegion,
          eventPrice
        } = catalogFilters;

        str = `${str ? str + ';' : ''}${inStock ? 'inStock=1;' : ''}${preOrder ? 'preOrder=1;' : ''}${
          discount ? 'discount=1;' : ''
        }${deliveryRegion ? 'deliveryRegion=' + deliveryRegion + ';' : ''}${
          filterSupplier ? 'supplier=' + filterSupplier + ';' : ''
        }${specialOffers ? 'specialOffers=1;' : ''}${transitOffers ? 'transitOffers=1;' : ''}${
          specialTransitOffers ? 'specialTransitOffers=1;' : ''
        }${eventPrice ? `eventPrice=${eventPrice};` : ''}`;
      } else if (isProductItem && product) {
        if (product.stockStatuses) {
          const { supplier: filterSupplier, deliveryRegion } = catalogFilters;
          str = `${str ? str + ';' : ''}${deliveryRegion ? 'deliveryRegion=' + deliveryRegion + ';' : ''}${
            filterSupplier ? 'supplier=' + filterSupplier + ';' : ''
          }`;
          const { inStock, preOrder, special, specialTransit, transit, ...dynamicStatuses } = product.stockStatuses;
          const dynamicStatusesKeys = Object.keys(dynamicStatuses);
          if (dynamicStatuses && dynamicStatusesKeys.length) {
            str = `${str}eventPrice=` + dynamicStatusesKeys.join(',') + ';';
          } else if (specialTransit) {
            str = `${str}specialTransitOffers=1;`;
          } else if (special) {
            str = `${str}specialOffers=1;`;
          } else if (transit) {
            str = `${str}transitOffers=1;`;
          } else if (inStock) {
            str = `${str}inStock=1;`;
          } else {
            str = `${str}preOrder=1;`;
          }
        }
        str = `product=${product.code};${str}`;
      }

      // @ts-ignore
      const productType = typeObject?.alias || defaultCatalogProductType;
      const path = generatePath(`${baseUrl}/catalog/:productType/:filterUrl?`, {
        productType,
        filterUrl: str || undefined
      });

      // TODO jesus christ, please God, bless me to refactor this
      // if product doesn't exist then reset filters and fetch catalog
      if (!isProductExist) {
        if (isProductItem && product) {
          const parsedFilters = filterStringToJson(str, undefined);

          const requestFilters = {
            sort,
            locale,
            inStock: parsedFilters.inStock,
            filters: undefined,
            watchOnly: false,
            preOrder: parsedFilters.preOrder,
            discount: false,
            specialOffers: parsedFilters.specialOffers,
            transitOffers: parsedFilters.transitOffers,
            specialTransitOffers: parsedFilters.specialTransitOffers,
            deliveryRegion: parsedFilters.deliveryRegion,
            supplier: parsedFilters.supplier,
            eventPrice: parsedFilters.eventPrice
          };
          dispatch(catalogSetFilter(requestFilters));
          dispatch(fetchCatalogAsync.request(requestFilters));
          dispatch(fetchFilterOptionsAsync.request(requestFilters as IFilterOptionsRequest));
        } else {
          dispatch(
            catalogSetFilter({
              sort,
              locale,
              inStock: undefined,
              fast: filters,
              filters: [],
              watchOnly: false,
              preOrder: false,
              discount: false,
              specialOffers: false,
              transitOffers: false,
              specialTransitOffers: false,
              deliveryRegion: undefined
            })
          );
          dispatch(fetchCatalogAsync.request({ productType }));
          dispatch(
            fetchFilterOptionsAsync.request({
              productType,
              inStock: undefined,
              preOrder: false,
              discount: false,
              specialOffers: false,
              transitOffers: false,
              specialTransitOffers: false,
              deliveryRegion: undefined
            } as IFilterOptionsRequest)
          );
        }
      }

      handleClearAndClose();
      history.push(path);

      if (toggleDrawer) {
        toggleDrawer();
      }
    }

    setIsResultItem(isResultItemTemp);
  };

  const handleClearAndClose = () => {
    setInputValue('');

    setTimeout(() => {
      setInputValue('');
      setValue(null);
      setOpen(false);
    }, 0);

    const inputContainer = refInput && refInput.current;

    if (inputContainer) {
      const input = inputContainer.querySelector('input');
      if (input) {
        input.blur();
      }
    }
  };

  const clearSearchHistory = () => {
    setSearchHistory([]);
    localStorage.removeItem('searchHistory');
  };

  const optionsReady =
    options && options.length
      ? options
      : inputValue && searchDataLoaded && !isResultItem && !searchDataLoading
      ? [{ label: messages.searchEmptyResult.defaultMessage }]
      : [];

  const grouped = groupBy(optionsReady, (item: ISearchItem) => item.type);

  return (
    <>
      <ClickAwayListener onClickAway={handleCloseResults}>
        <Autocomplete
          freeSolo
          disableCloseOnSelect
          disableClearable
          loading={searchDataLoading}
          openOnFocus
          className={classes.controlContainer}
          open={screenDevice ? false : open}
          classes={{
            paper: classes.ListPaper
          }}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.label || option.name)}
          filterOptions={(x) => x}
          options={[...searchHistory, ...optionsReady]}
          includeInputInList
          renderInput={(params) => (
            <TextField
              {...params}
              ref={refInput}
              autoFocus={!!toggleDrawer}
              onBlur={() => setOpen(false)}
              onFocus={() => setOpen(true)}
              onChange={(a) => {
                const newInputValue = a.target.value;
                setInputValue(newInputValue);
                setOpen(!!newInputValue);
              }}
              value={inputValue}
              variant="outlined"
              fullWidth
              placeholder={'Знайти “Тюльпан Жовта Стріла”'}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {searchDataLoading ? (
                      <CircularProgress className={classes.loader} color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                )
              }}
            />
          )}
          value={value}
          onChange={selectItem}
          groupBy={(option) => option.type}
          renderGroup={({ children, group }) => (
            <Grid key={group || Math.random()} className={classes.groupItemContainer}>
              {group && (
                <TextSubTitle className={classes.groupItem} color={'inherit'}>
                  {(messages[`searchItem_${group}`] && messages[`searchItem_${group}`].defaultMessage) || group}

                  {group === 'history' && (
                    <Link2
                      className={classes.clearHistory}
                      onClick={clearSearchHistory}
                      color={'inherit'}
                      id="clear-history"
                    >
                      {messages.clearHistory.defaultMessage}
                    </Link2>
                  )}
                </TextSubTitle>
              )}
              {children}
            </Grid>
          )}
          renderOption={(props: any, option, { inputValue: inputValueLocal }: any) => {
            const label = option.label || option.name;
            const matches = match(label, inputValueLocal);
            const parts = parse(label, matches);

            return (
              <Grid className={classes.optionItem} container alignItems="center" {...props}>
                <Grid item className={classes.itemIcon}>
                  {option.type && (
                    <Icon type={typeIcon[option.type] ? typeIcon[option.type] : 'listMarked'} size={24} offset={8} />
                  )}
                </Grid>
                <Grid item xs>
                  <Typography variant="body2" color="inherit">
                    {parts.map((part: any, index: number) => (
                      <span key={index} className={clsx({ [classes.optionItemHighlight]: part.highlight })}>
                        {part.text}
                      </span>
                    ))}
                  </Typography>
                </Grid>
              </Grid>
            );
          }}
        />
      </ClickAwayListener>

      <Hidden mdDown>
        <Button className={classes.buttonSearch} onClick={handleFetchData} variant={'contained'} color={'primary'}>
          <Icon type={'search'} size={24} opacity={1} />
          <span className={'btn-label'}>{messagesHeader.search.defaultMessage}</span>
        </Button>
      </Hidden>

      <Hidden mdUp>
        {Object.keys(grouped).map((groupKey) => {
          const group = grouped[groupKey];

          return (
            <Grid key={groupKey || Math.random()} className={classes.groupItemContainer}>
              <TextSubTitle className={classes.groupItem} color={'inherit'}>
                {groupKey === 'undefined'
                  ? ''
                  : (messages[`searchItem_${groupKey}`] && messages[`searchItem_${groupKey}`].defaultMessage) ||
                    groupKey}

                {groupKey === 'history' && (
                  <Link2
                    className={classes.clearHistory}
                    onClick={clearSearchHistory}
                    color={'inherit'}
                    id="clear-history"
                  >
                    {messages.clearHistory.defaultMessage}
                  </Link2>
                )}
              </TextSubTitle>

              {group.map((option: any, index) => {
                const label = option.label || option.name;
                const matches = match(label, inputValue);

                const parts = parse(label, matches);

                return (
                  <Grid
                    key={option.slug || index}
                    className={classes.optionItem}
                    container
                    alignItems="center"
                    onClick={() => selectItem({}, option, '')}
                  >
                    <Grid item className={classes.itemIcon}>
                      {option.type && (
                        <Icon
                          type={typeIcon[option.type] ? typeIcon[option.type] : 'listMarked'}
                          size={24}
                          offset={8}
                        />
                      )}
                    </Grid>
                    <Grid item xs>
                      <Typography className={classes.searchItemMobile} variant="body2" color="inherit">
                        {parts.map((part: any, indexOption: number) => (
                          <span key={indexOption} className={clsx({ [classes.optionItemHighlight]: part.highlight })}>
                            {part.text}
                          </span>
                        ))}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
      </Hidden>
    </>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IApplicationState> = (state: IApplicationState) => ({
  searchData: getSearchDataData(state),
  searchDataLoaded: getSearchDataLoadedState(state),
  searchDataLoading: getSearchDataLoadingState(state),
  filterOptionsData: getFilterOptionsData(state),
  locale: state.locale,
  sort: getCatalogSort(state),
  catalogDocs: getCatalogData(state),
  catalogFilters: getCatalogFilters(state)
});

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, IOwnProps> = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      unselectProduct,
      selectProduct: selectProductAsync.request,
      fetchSearchData: searchAsync.request,
      filterAndSortCatalog
    },
    dispatch
  )
});
function findCategory(code: string, array: IFilterProductCatalogCategory[]): IFilterProductCatalogCategory | null {
  let result: IFilterProductCatalogCategory | null = null;

  array.forEach((item) => {
    if (result) {
      return;
    }

    if (item.code === code) {
      result = item;
    }

    if (item.items) {
      findCategory(code, item.items || []);
    }
  });

  return result;
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles<any>(styles)(SearchControl as any));
