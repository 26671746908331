import * as React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import { Button, Hidden } from '@mui/material';

import Container from 'components/layout/Container';
import HeaderTopSettings from 'components/layout/header-main/top/HeaderTopSettings';
import { Help } from 'components/layout/header/Help';
import FlrCard from 'components/shared/card/FlrCard';
import { ReactComponent as LogoTextRight } from 'components/shared/icons/logo-textright-ru.svg';
import { PhoneLink } from 'components/shared/PhoneLink';
import { SocialLinks } from 'components/shared/SocialLinks';
import { Link2, TextBody1, TextBody2 } from 'components/shared/text';
import { baseUrl, devicePanelHeight } from 'shared/constants';
import messages from 'translations/auth/common';
import commonAuthMessages from 'translations/auth/common';
import { IThemed } from 'utils/styled';

import Header from '../../../components/layout/header-main';
import bgImage from '../assets/login-bg.jpg';

const formWidth = 464;
const formPadding = 50;

interface IProps {
  children: React.ReactNode;
  classes: any;
  registration?: boolean;
}

// Default locale - is a language of user's browser, locale should be passed from user's profile
const AuthPageWrapper: React.FC<IProps> = ({ children, classes, registration }) => {
  React.useEffect(() => {
    setTimeout(() => {
      const el = document.querySelector('.rngst_phone_button') as HTMLElement;
      if (el) {
        el.style.bottom = '80px';
      }
      return () => {
        if (el) {
          el.style.bottom = `${devicePanelHeight}px`;
        }
      };
    }, 500);
  });

  const formContent = (
    <React.Fragment>
      <FormPaper>{children}</FormPaper>
      <Hidden smDown>
        <div className={classes.underFormBlock}>
          {registration ? (
            <>
              <TextBody2 className={classes.bottomCaption}>{messages.bottomCaptionLogin.defaultMessage}</TextBody2>
              <Link2 className={classes.bottomLink} to={`${baseUrl}/login`}>
                {messages.bottomLinkLogin.defaultMessage}
              </Link2>
            </>
          ) : (
            <>
              <TextBody2 className={classes.bottomCaption}>
                {messages.bottomCaptionRegistration.defaultMessage}
              </TextBody2>
              <Link2 className={classes.bottomLink} to={`${baseUrl}/registration`}>
                {messages.bottomLinkRegistration.defaultMessage}
              </Link2>
            </>
          )}
        </div>
      </Hidden>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Hidden smDown>
        <div className={classes.root}>
          <StyledBg />
          <div className={classes.authTopBar}>
            <SocialLinks dark />
            <PhoneLink withPopup dark />
            <Help dark />
            <HeaderTopSettings dark onlyLang />
            {registration ? (
              <Button
                component={NavLink}
                to={`${baseUrl}/login`}
                className="button-reg"
                color={'primary'}
                variant={'contained'}
                size={'small'}
              >
                {commonAuthMessages.login.defaultMessage}
              </Button>
            ) : (
              <Button
                component={NavLink}
                to={`${baseUrl}/registration`}
                className="button-reg"
                color={'primary'}
                variant={'contained'}
                size={'small'}
              >
                {commonAuthMessages.register.defaultMessage}
              </Button>
            )}
          </div>
          <div className={classes.wrapper}>
            <div className={classes.LogoWrapper}>
              <div className={classes.LogoContainer}>
                <BigLogoLink to={`${baseUrl}/`}>
                  <LogoTextRight />
                </BigLogoLink>
                <TextBody1 className={classes.logoCaption}>
                  <FormattedHTMLMessage {...messages.logoCaption} />
                </TextBody1>
              </div>
            </div>
            <FormWrapper>{formContent}</FormWrapper>
          </div>
        </div>
      </Hidden>
      <Hidden smUp>
        <Header registration={!registration} className={classes.headerMobile} />
        <FormWrapper className={classes.bodyMobile}>{formContent}</FormWrapper>
      </Hidden>
    </React.Fragment>
  );
};
export default AuthPageWrapper;

// const LogoContainer = styled<any>(Container)``;

const FormWrapper = styled<any>(Container)(({ theme }: IThemed) => ({
  position: 'relative',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  justifyContent: 'center',

  '& label.MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
    top: -6
  },

  [`@media (min-width: ${theme.breakpoints.lg})`]: {
    padding: '74px 0'
  },

  [`@media (max-width: ${theme.breakpoints.lg})`]: {
    height: 'auto',
    minHeight: 'calc(100vh - 400px)',
    marginBottom: 40,
    alignItems: 'center'
  }
}));

const SideLogoLink = styled<any>(NavLink)(({ theme }: IThemed) => ({
  color: theme.colors.background
}));

const BigLogoLink = styled<any>(SideLogoLink)(({ theme }: IThemed) => ({
  [`@media (min-width: ${theme.breakpoints.md})`]: {
    display: 'block'
  }
}));

const StyledBg = styled<any>('div')(() => ({
  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  background: `linear-gradient(0deg, rgba(74, 50, 48, 0.3), rgba(74, 50, 48, 0.3)), url(${bgImage}) center center no-repeat`,
  backgroundBlendMode: 'multiply, normal',
  backgroundSize: 'cover'
}));

const FormPaper = styled<any>(FlrCard)(({ theme }: IThemed) => ({
  position: 'relative',
  height: 'auto',
  width: `${formWidth}px`,
  letterSpacing: '0.03em',
  font: theme.fonts.body,
  zIndex: 2,
  '&.MuiPaper-rounded': {
    letterSpacing: theme.letterSpacing,
    padding: '32px 40px',
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      width: '100%',
      padding: `${formPadding / 2}px`,
      boxShadow: 'none'
    }
  },
  h1: {
    marginTop: 0
  },

  [`@media (max-width: ${theme.breakpoints.sm})`]: {
    boxShadow: 'none'
  }
}));
