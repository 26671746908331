import Icon from 'components/shared/Icon';
import { TextBody2, TextSubTitle } from 'components/shared/text';
import messages from 'translations/main/features';

import classes from './features.module.scss';

interface IFeatureItem {
  icon: string;
  title: string;
  desc: string;
}

const featuresList: IFeatureItem[] = [
  {
    icon: 'delivery',
    title: messages.delivery.defaultMessage,
    desc: messages.deliveryDesc.defaultMessage
  },
  {
    icon: 'support',
    title: messages.support.defaultMessage,
    desc: messages.supportDesc.defaultMessage
  },
  {
    icon: 'bids',
    title: messages.onlineBidding.defaultMessage,
    desc: messages.onlineBiddingDesc.defaultMessage
  },
  {
    icon: 'discount',
    title: messages.discounts.defaultMessage,
    desc: messages.discountsDesc.defaultMessage
  },
  {
    icon: 'loyalty',
    title: messages.priceOffers.defaultMessage,
    desc: messages.priceOffersDesc.defaultMessage
  }
];

const MainFeatures = () => {
  return (
    <section className={classes.root}>
      <div className={classes.featuresContainer}>
        {featuresList.map((item, index) => (
          <div key={index} className={classes.featuresItem}>
            <Icon className={classes.featureIcon} size={40} opacity={1} type={item.icon} withBackground />
            <TextSubTitle className={classes.featuresItemTitle}>{item.title}</TextSubTitle>
            <TextBody2 className={classes.featuresItemDesc}>{item.desc}</TextBody2>
          </div>
        ))}
      </div>
    </section>
  );
};

export { MainFeatures };
