import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { isMobileDevice } from 'environment';
import { Button, Theme, useMediaQuery, useTheme } from '@mui/material';

import Icon from 'components/shared/Icon';
import { PhoneLink } from 'components/shared/PhoneLink';
import { SocialLinks } from 'components/shared/SocialLinks';
import { baseUrl } from 'shared/constants';
import { getAccountLoadingState, getUserAccount } from 'store/account/selectors';
import { getCartMobileTab } from 'store/cart/selectors';
import commonAuthMessages from 'translations/auth/common';

import UserNavMenu from '../header-main/components/UserNavMenu';
import HeaderSearch from '../header-main/search/HeaderSearch';
import HeaderTopSettings from '../header-main/top/HeaderTopSettings';
import HeaderTopFavorites from './HeaderTopFavorites';
import HeaderTopSkeleton from './HeaderTopSkeleton';
import { Help } from './Help';
import InCart from './InCart';
import UserInfo from './UserInfo';

import classes from './HeaderTop.module.scss';

const isMobile = isMobileDevice(window.navigator);

export interface ICatalogHeaderTop extends React.HTMLAttributes<HTMLDivElement> {
  main?: boolean;
  static?: boolean;
  registration?: boolean;
}

export const CatalogHeaderTop: React.FC<ICatalogHeaderTop> = (props) => {
  const [phoneLinkPopupOpen, setPhoneLinkPopupOpen] = useState<boolean>(false);
  const { main: isMain, static: isStatic, registration } = props;
  const isCatalog = useRouteMatch(`${baseUrl}/catalog/:type?/:filter?`);
  const theme: Theme = useTheme();
  const screenDevice = useMediaQuery(theme.breakpoints.down('md'));

  const userLoading = useSelector(getAccountLoadingState);
  const user = useSelector(getUserAccount);
  const cartTab = useSelector(getCartMobileTab);
  const isCart = window.location.pathname.startsWith(`${baseUrl}/cart`) && screenDevice && cartTab > -1;

  const onOpen = () => {
    setPhoneLinkPopupOpen(true);
  };

  const onClose = () => {
    setPhoneLinkPopupOpen(false);
  };

  const LogoFragment = ({ className }: { className?: string }) => (
    <div className={className}>
      {isMain && !isStatic ? (
        <span className={classes.headerLogo}>
          <Icon type={'logoFull'} className={classes.iconLogo} />
        </span>
      ) : (
        <NavLink to={`${baseUrl}/`} className={classes.headerLogo}>
          <Icon type={'logoFull'} className={classes.iconLogo} />
        </NavLink>
      )}
    </div>
  );

  return (
    <div className={clsx(classes.container, { [classes.device || '']: screenDevice, [classes.cart || '']: isCart })}>
      <div className={classes.headerInfo}>
        <SocialLinks />
        <PhoneLink withPopup containerClassName={classes.phoneLink} />
        <Help />
      </div>
      <LogoFragment className={classes.logo} />
      {user ? <UserInfo className={classes.headerSummary} /> : null}
      <div className={classes.headerRightBar}>
        {userLoading ? (
          <HeaderTopSkeleton />
        ) : (
          <>
            <div className={classes.headerMobilePhoneContainer}>
              <LogoFragment />
            </div>
            <div className={classes.rightBarContainer}>
              <div className={classes.rightBarMobile}>
                {isMobile ? <InCart mobile /> : null}
                <div className={clsx(classes.headerTopSettings, { [classes.hideHeaderTopSettings || '']: !!user })}>
                  <HeaderTopSettings onlyLang={!user} />
                </div>
                {user ? (
                  <>
                    <div className={classes.spacer} />
                    <div className={classes.rightBarActions}>
                      <PhoneLink
                        containerClassName={classes.headerMobilePhone}
                        onClose={onClose}
                        onOpen={onOpen}
                        triggerContent={
                          <Icon
                            type="customerSupport"
                            size={18}
                            opacity={1}
                            className={clsx('icon', { 'icon-selected': phoneLinkPopupOpen })}
                          />
                        }
                        position="bottom right"
                        withPopup
                      />
                      {user ? !isCatalog && screenDevice ? <HeaderSearch /> : <HeaderTopFavorites /> : null}
                      <UserNavMenu user={user} iconSize={24} />
                    </div>
                  </>
                ) : (
                  <div className={classes.authWrapper}>
                    {user ? !isCatalog && screenDevice ? <HeaderSearch /> : null : null}
                    <div className={classes.spacer} />
                    <Button
                      className={clsx(classes.buttonReg, classes.btnRegistration)}
                      href={`${baseUrl}/registration`}
                      color="primary"
                      variant="outlined"
                      size="small"
                    >
                      {commonAuthMessages.register.defaultMessage}
                    </Button>
                    {registration && !screenDevice ? (
                      <Button
                        className={classes.buttonReg}
                        href={`${baseUrl}/registration`}
                        color="primary"
                        variant="text"
                        size="small"
                      >
                        {commonAuthMessages.register.defaultMessage}
                      </Button>
                    ) : (
                      <Button href={`${baseUrl}/login`} color="primary" variant="contained" size="small">
                        {commonAuthMessages.login.defaultMessage}
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
