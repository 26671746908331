import { ChangeEvent, FC } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';

import { ExpandPanel } from 'components/shared';
import { FlrRadio } from 'components/shared/form-elements/FlrRadio';
import { ISelectOption } from 'components/shared/form-elements/select2/FlrSelect2';
import { IFilterProductType } from 'models';

import FilterLabel from './FilterLabel';

interface IProps {
  handleTypeChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label: string;
  classes: any;
  productType?: IFilterProductType;
  options: ISelectOption[];
  disabled?: boolean;
}

export const ProductTypeOptions: FC<IProps> = ({
  label: labelSection,
  classes,
  handleTypeChange,
  productType,
  options,
  disabled
}) => {
  const labelComp = <FilterLabel classes={classes} label={labelSection} defaultCaption={productType?.name || ''} />;

  return options && options.length ? (
    <ExpandPanel
      defaultExpanded={true}
      label={labelComp}
      className={classes.paddedExpandPanel}
      classNameSummary={classes.expansionSummary}
      classNameButton={classes.expansionButton}
    >
      <RadioGroup
        aria-label="status-filter"
        name="status-filter"
        value={productType?.code || ''}
        onChange={handleTypeChange}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<FlrRadio className={classes.statusFilterRadio} />}
            label={option.label}
            disabled={disabled || option.isDisabled}
          />
        ))}
      </RadioGroup>
    </ExpandPanel>
  ) : null;
};
