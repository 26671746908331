import React from 'react';
import styled from '@emotion/styled/macro';

import { PhonesListComponent } from 'components/shared/PhonesList';
import { WorkingHours } from 'components/shared/WorkingHours';
import { IThemed } from 'utils/styled';

import { IPhoneContact } from '../../../../shared/constants';
import SocialLinks from './SocialLinks';

interface IProps {
  phones: IPhoneContact[];
}

export const PhonesCard: React.FC<IProps> = ({ phones }) => (
  <Wrapper>
    <PhonesListComponent phones={phones} />
    <SocialLinks />
    <Divider />
    <WorkingHours />
  </Wrapper>
);

const Wrapper = styled<any>('section')(({ theme }: IThemed) => ({
  padding: '8px 16px',
  fontFamily: theme.fonts.headings,
  letterSpacing: theme.letterSpacing
}));

const Divider = styled<any>('div')(({ theme }: IThemed) => ({
  border: `1px solid ${theme.colors.lightGray}`,
  margin: '8px 0'
}));
