import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Slider from 'react-slick';
import { Button, Grid, Hidden, Theme, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';

import { TextSubTitle, TitleH1 } from 'components/shared/text';
import { baseUrl, defaultCatalogProductType } from 'shared/constants';
import { mainTopSlidersAsync } from 'store/main/actions';
import { getTopSlidersData } from 'store/main/selectors';
import messages from 'translations/main/top';

import env from '../../../environment';
import { ReactComponent as BrandName } from '../assets/floroteka.svg';

import classes from './MainTop.module.scss';

const defaultSettings = {
  dots: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
  // TODO test in future for mobile lag
  // touchThreshold: 5,
  autoplay: true,
  autoplaySpeed: 5000,
  initialSlide: 0,
  nextArrow: <Fragment />,
  prevArrow: <Fragment />
};

const MainTop = () => {
  const dispatch = useDispatch();
  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const topSlider = useSelector(getTopSlidersData);

  useEffect(() => {
    dispatch(mainTopSlidersAsync.request());
  }, []);

  const sliderContent =
    topSlider &&
    topSlider
      .sort((i, j) => i.order - j.order)
      .map((item) => {
        const imageLink =
          item.image.indexOf('/') > -1
            ? item.image
            : `${env.apiUrl}/static/slider-images/${isMobile ? item.mobileImage : item.image}`;
        return (
          <div className={classes.slideContainer} key={item.id}>
            <Grid
              container
              className={classes.root}
              component={'section'}
              // TODO comment for test on mobile devices
              // style={{ backgroundImage: `url(${imageLink})` }}
            >
              <Grid item container className={classes.imageContainer}>
                <img className={classes.image} src={imageLink} alt={item.header} />
              </Grid>
              <Grid item container className={classes.container}>
                <Grid item xs={1} implementation="css" smDown component={Hidden} />
                <Grid item className={classes.content}>
                  {item.header ? (
                    <TitleH1 className={classes.title} style={{ color: item.color }}>
                      {item.header}
                    </TitleH1>
                  ) : (
                    <BrandName className={classes.brandName} />
                  )}
                  <TextSubTitle className={classes.subTitle}>
                    {typeof item.description !== 'string' ? (
                      item.description.map((description, i) => (
                        <div style={{ color: item.color }} key={i}>
                          {description}
                        </div>
                      ))
                    ) : item.description ? (
                      <div style={{ color: item.color }}>{item.description}</div>
                    ) : null}
                  </TextSubTitle>
                  <Button
                    color="primary"
                    size={'large'}
                    variant={'contained'}
                    component={NavLink}
                    to={item.href ? `${baseUrl}${item.href}` : `${baseUrl}/catalog/${defaultCatalogProductType}`}
                    className={classes.catalogButton}
                  >
                    <span>{item.btnLabel || messages.buttonMobile.defaultMessage}</span>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        );
      });

  return (
    <div className={classes.wrapper}>
      {topSlider && topSlider.length ? <Slider {...defaultSettings}>{sliderContent}</Slider> : null}
    </div>
  );
};

export { MainTop };
