import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { Footer } from 'components/layout/footer';
import Header from 'components/layout/header-main';

export interface IStaticPageProps {
  staticHtml: any;
}

const BasicPageHtml: React.FC<IStaticPageProps> = ({ staticHtml }) => {
  const classes = useStyles();

  return (
    <>
      <Header main static />
      <div className={classes.root}>{staticHtml}</div>
      <Footer />
    </>
  );
};

export default BasicPageHtml;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(8)
    }
  })
);
