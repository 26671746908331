import { FC } from 'react';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';

import { DetailedProduct, IOfferDiscountDetails, Offer, Product } from 'models';

import DeliveryCardHeader from './DeliveryCardHeader';
import DeliveryCardInnerContainer from './DeliveryCardInnerContainer';
import styles from './styles';

interface IProps {
  classes: any;
  discount: number;
  discountDetails: IOfferDiscountDetails[];
  offer: Offer;
  onPurchase: (offer: Offer, qty: number) => void;
  setQtyState: (value: number) => void;
  selectedProduct: DetailedProduct | Product | null;
}

const calculateSpecialPriceDiscount = (selectedProduct: DetailedProduct | Product | null): number => {
  if (!selectedProduct) {
    return 0;
  }

  const { regularPrice, specialPrice } = selectedProduct;
  const minPrice = regularPrice && !isNaN(Number(Object.values(regularPrice)[0])) && Number(Object.values(regularPrice)[0]) > 0
    ? Number(Object.values(regularPrice)[0])
    : 0;

  const specialPriceDiscount =
    specialPrice && !isNaN(Number(Object.values(specialPrice)[0])) ? Number(Object.values(specialPrice)[0]) : 0;

  if (!minPrice || !specialPriceDiscount || isNaN(minPrice) || isNaN(specialPriceDiscount)) {
    return 0;
  }

  return specialPriceDiscount < minPrice
    ? parseFloat((100 * (1 - specialPriceDiscount / minPrice)).toFixed(0))
    : 0;
};

const DeliveryCard: FC<IProps> = ({
  classes,
  discount,
  discountDetails,
  offer,
  onPurchase,
  setQtyState,
  selectedProduct
}) => {
  const specialPriceDiscount = calculateSpecialPriceDiscount(selectedProduct);
  return (
    <Grid
      className={clsx(classes.deliveryItem, {
        [classes.deliveryItemPreOrder]: offer.preOrder
      })}
      container
      item
      xs={12}
    >
      <DeliveryCardHeader
        warehouse={offer.warehouse}
        isPreorder={offer.preOrder || offer.extraPrice}
        special={offer.special}
        discount={discount}
        discountDetails={discountDetails}
        specialPriceDiscount={specialPriceDiscount}
      />

      <Grid className={classes.card} item container>
        <DeliveryCardInnerContainer offer={offer} onPurchase={onPurchase} setQtyStateProp={setQtyState} />
      </Grid>
    </Grid>
  );
};

export default styles(DeliveryCard);
