import { FC } from 'react';
import Popup, { Position } from 'reactjs-popup';

import { PhonesCard } from 'components/layout/header-main/top/PhonesCard';
import ButtonCommon from 'components/layout/header-main/top/StyledButtonCommon';
import { IPhoneContact, phoneDefaults } from 'shared/constants';
import { defaultMaterialTheme } from 'utils/styled';

import { TextBody2 } from './text';

import classes from './PhoneLink.module.scss';

interface IProps {
  withPopup?: boolean;
  dark?: boolean;
  triggerContent?: JSX.Element;
  containerClassName?: string;
  position?: Position | Position[];
  onOpen?: () => void;
  onClose?: () => void;
}

const PhoneLink: FC<IProps> = ({
  withPopup = false,
  dark,
  triggerContent,
  containerClassName,
  position = 'bottom left',
  onOpen,
  onClose
}) => {

  const mainPhone = phoneDefaults[0].phone;
  const iconLink = (
    <ButtonCommon dark={dark} className={classes.button}>
      {withPopup ? (
        <TextBody2 color={'inherit'}>{mainPhone}</TextBody2>
      ) : (
        <div className={classes.wrapper}>
          {phoneDefaults.map((phoneItem: IPhoneContact, index: number) => (
            <div key={index} className={classes.phoneItem}>
              <span>{phoneItem.additionalText}</span>
              <a href={`tel:${phoneItem.phone}`}>
                <TextBody2 color={'inherit'}>{phoneItem.phone}</TextBody2>
              </a>
            </div>
          ))}
        </div>
      )}
    </ButtonCommon>
  );

  return (
    <div className={containerClassName}>
      {withPopup ? (
        <Popup
          on={['click']}
          closeOnDocumentClick
          contentStyle={{
            width: 'fit-content',
            padding: 0,
            borderRadius: 3,
            zIndex: defaultMaterialTheme.zIndex.modal
          }}
          onOpen={onOpen}
          onClose={onClose}
          trigger={() => (
            <ButtonCommon dark={dark}>
              <TextBody2 color={'inherit'}>{triggerContent || mainPhone}</TextBody2>
            </ButtonCommon>
          )}
          position={position as any}
        >
          <PhonesCard phones={phoneDefaults} />
        </Popup>
      ) : (
        iconLink
      )}
    </div>
  );
};

export { PhoneLink };
