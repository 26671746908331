import { FC } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import { TitleH2 } from 'components/shared/text';

import classes from './CartLeftSideBlockHeader.module.scss';

interface IProps {
  title: MessageDescriptor;
}

const CartLeftSideBlockHeader: FC<IProps> = ({ title }) => {
  return (
    <TitleH2 className={classes.title} color={'inherit'}>
      <FormattedMessage {...title} />
    </TitleH2>
  );
};

export default CartLeftSideBlockHeader;
