import { FC } from 'react';
import clsx from 'clsx';
import { isMobileDevice } from 'environment';
import { upperFirst } from 'lodash';
import { IconButton } from '@mui/material';

import { SplitedPrice } from 'components/prices';
import FlrQuantity from 'components/shared/form-elements/quantity/FlrQuantity';
import Icon from 'components/shared/Icon';
import { TextBody2, TextCaption } from 'components/shared/text';
import { CartItem } from 'models';
import messages from 'translations/cart/common';

import { INFINITE } from '../../../shared/constants';
import { getMinMaxPreOrderDates } from '../../../utils/time';
import { Text } from '../../layout/content/Text';

import classes from './MiniCartDetailsCardOrder.module.scss';

interface IProps {
  item: CartItem;
  onChange: (qty: number) => void;
  onRemove: () => void;
  isEdit?: boolean;
}

const isMobile = Boolean(isMobileDevice(window.navigator));

const MiniCartDetailsCardItemOrder: FC<IProps> = ({ item, onChange, onRemove, isEdit }) => {
  const { preOrder, extraPrice } = item.offer;
  const isSpecialOrder = item.special;

  const warehouseName =
    item.offer && item.offer.preOrder ? 'Флоротека' : item.offer.warehouse ? item.offer.warehouse.displayName : '';
  const deliveryOptions = getMinMaxPreOrderDates([item.product.id], preOrder);
  return (
    <>
      <div className={classes.container}>
        <div className={clsx(classes.warehouseContainer, { [classes.warehouseWrapper]: isEdit })}>
          <TextCaption className={clsx(classes.warehouseName, { [classes.warehouseNameActive]: isEdit })}>
            {warehouseName}
          </TextCaption>
          <div className={classes.deliveryContainer}>
            {!isMobile ? <Icon type="delivery" size={24} offset={9} /> : null}
            <Text className={classes.deliveryDate}>
              {upperFirst(deliveryOptions[0] ? deliveryOptions[0].format('dd DD.MM') : '')}
            </Text>
            <span className={classes.deliveryLabel}>
              {' - '}
              {preOrder ? INFINITE : upperFirst(deliveryOptions[1] ? deliveryOptions[1].format('dd DD.MM') : '')}
            </span>
          </div>
        </div>
        {isMobile ? (
          <>
            <div className={classes.priceWrapper}>
              <TextBody2 color={'textSecondary'}>{messages.pricePerItem.defaultMessage}</TextBody2>
              <div className={classes.priceContainer}>
                {isSpecialOrder ? <Icon opacity={1} type="fire" size={18} style={{ marginRight: 6 }} /> : null}
                <SplitedPrice
                  value={item.clientPrice}
                  fontColor={'textSecondary'}
                  fontSize={'body2'}
                  decimalSameSize
                  className={classes.price}
                />
              </div>
            </div>
            <div className={classes.quantityWrapper}>
              <FlrQuantity
                isGap
                value={item.qty}
                inPackageCount={item.product.inPackageCount}
                min={item.getMinQty}
                onDecrement={item.qty === item.product.inPackageCount ? onRemove : undefined}
                max={item.offer.inStock || item.qty}
                onChange={onChange}
                disableMaxLimit={!preOrder && !extraPrice}
                hideKeyboard={isMobile}
                isSelect
                classNameButton={classes.button}
              />
              <TextBody2 color={'textSecondary'} className={classes.inPackageCountLabel}>
                {item.product.inPackageCount}&nbsp;{messages.inPackageCountItem.defaultMessage}
              </TextBody2>
              <IconButton onClick={onRemove} size="large">
                <Icon type={'trash'} size={20} opacity={1} />
              </IconButton>
            </div>
          </>
        ) : (
          <div className={classes.desktopContainer}>
            <div className={classes.labels}>
              <TextBody2 color={'textSecondary'} className={classes.pricePerItemLabel}>
                {messages.pricePerItem.defaultMessage}
              </TextBody2>
              <TextBody2 color={'textSecondary'} className={classes.qtyInItemsLabel}>
                {messages.qtyInItems.defaultMessage}
              </TextBody2>
              <TextBody2 color={'textSecondary'} className={classes.qtyInPackageLabel}>
                {messages.qtyInPackage.defaultMessage}
              </TextBody2>
              <span className={classes.trash} />
            </div>
            <div className={classes.content}>
              <SplitedPrice
                value={item.clientPrice}
                fontColor={'textSecondary'}
                fontSize={'body2'}
                decimalSameSize
                className={classes.price}
              />
              <FlrQuantity
                classNameContainer={classes.qtyContainer}
                classNameWrapper={classes.autoCompleteContainer}
                isGap
                value={item.qty}
                inPackageCount={item.product.inPackageCount}
                min={item.getMinQty}
                onDecrement={item.qty === item.product.inPackageCount ? onRemove : undefined}
                max={item.offer.inStock || item.qty}
                onChange={onChange}
                disableMaxLimit={!preOrder && !extraPrice}
                classNameButton={classes.button}
              />
              <TextBody2 color={'textSecondary'} className={classes.inPackageCountLabel}>
                {item.product.inPackageCount}
              </TextBody2>
              <IconButton onClick={onRemove} size="large" className={classes.trashIcon}>
                <Icon type={'trash'} size={20} opacity={1} />
              </IconButton>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MiniCartDetailsCardItemOrder;
