import React, { useEffect } from 'react';
import { connect, MapDispatchToProps, MapStateToProps, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { generatePath } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { isMobileDevice } from 'environment';
import { Button, Theme, useMediaQuery } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useTheme, withStyles } from '@mui/styles';

import { AsideDeviceFooter, AsideDeviceHeader } from 'components/layout/aside-device';
import SidePanelsLayout from 'components/layout/side-panels-layout';
import { Link2, TextBody2 } from 'components/shared/text';
import { useTrackInitialPageLoad } from 'controllers';
import { IFilterOptions, IFilterOptionsRequest, IPreferencesData, Product, User } from 'models';
import {
  baseUrl,
  hideLeftPanelWidth,
  hideRightPanelWidth,
  productCardHeight,
  productCardHeightMobile,
  productCardHeightMobileLogged,
  tableViewRowHeight,
  tableViewRowMobileHeight
} from 'shared/constants';
import { getUserAccount, getUserPreferences } from 'store/account/selectors';
import { closeMiniCart, openMiniCart } from 'store/cart/actions';
import {
  catalogSetFilter,
  fetchCatalogAsync,
  filterAndSortCatalog,
  ICatalogData,
  ICatalogLayout,
  IFiltersData,
  ISelectedProduct,
  selectProductAsync,
  toggleDetails,
  toggleFilters,
  unselectProduct
} from 'store/catalog/actions';
import {
  getCatalogData,
  getCatalogDocsShown,
  getCatalogFilters,
  getCatalogLayout,
  getCatalogLoadingState,
  getCatalogSort,
  getCatalogSortedState,
  getSelectedProductData
} from 'store/catalog/selectors';
import { fetchFilterOptionsAsync } from 'store/filter-options/actions';
import { getFilterOptionsData, getFilterOptionsLoadedState } from 'store/filter-options/selectors';
import { IApplicationState } from 'store/reducers';
import { filtersMessages } from 'translations/catalog/filters';
import { filterJsonToString, filtersToString } from 'utils/filters';
import { usePrevious } from 'utils/hooks';

import { updatePreferencesAccountAsync } from '../../store/account/actions';
import { DeliveryModal, IDeliveryState } from './layout/delivery-modal/DeliveryModal';
import FilterAside from './layout/left-bar';
import RightBar from './layout/right-bar';
import { ProductCatalogMemoized } from './products-catalog/index';
import styles from './styles';

const isMobile = isMobileDevice(window.navigator);

const getCardHeight = (isMobile: boolean, isTable: boolean, isUser: boolean) => {
  if (isMobile) {
    if (isTable) {
      return tableViewRowMobileHeight;
    }
    return productCardHeightMobile + (isUser ? productCardHeightMobileLogged : 0);
  }
  if (isTable) {
    return tableViewRowHeight;
  }
  return productCardHeight;
};

interface IOwnProps {
  // put own props here
}

interface IStateProps {
  user: User | null;
  layout: ICatalogLayout;
  locale: string;
  catalog: ICatalogData;
  sort: string;
  filters: IFiltersData;
  selectedProduct: ISelectedProduct;
  catalogFilterOptionsLoaded: boolean;
  filterOptionsData: IFilterOptions;
  catalogLoading: boolean;
  catalogSorted: boolean;
  docsShown: number;
  classes?: any;
  accountPreferences: IPreferencesData | undefined;
}

interface IDispatchProps {
  loadFilterOptions: typeof fetchFilterOptionsAsync.request;
  filterAndSortCatalog: typeof filterAndSortCatalog;
  selectProduct: typeof selectProductAsync.request;
  unselectProduct: typeof unselectProduct;
  closeMiniCart: typeof closeMiniCart;
  openMiniCart: typeof openMiniCart;
  toggleFilters: typeof toggleFilters;
  toggleDetails: typeof toggleDetails;
  updateAccount: typeof updatePreferencesAccountAsync.request;
  loadCatalog: typeof fetchCatalogAsync.request;
}

type IProps = IOwnProps & IStateProps & IDispatchProps;

const CatalogPage: React.FC<IProps> = (props) => {
  const {
    user,
    catalog,
    layout,
    filters,
    sort,
    docsShown,
    locale,
    catalogFilterOptionsLoaded,
    filterOptionsData,
    catalogLoading,
    catalogSorted,
    filterAndSortCatalog: catalogFiltersAndSortUpdate,
    loadFilterOptions,
    selectedProduct,
    toggleFilters: catalogToggleFilters,
    toggleDetails: catalogToggleDetails,
    selectProduct,
    updateAccount,
    accountPreferences,
    loadCatalog,
    classes
  } = props;
  useTrackInitialPageLoad();
  const dispatch = useDispatch();
  const theme: Theme = useTheme();
  const screenFullHD = useMediaQuery(theme.breakpoints.up('xl'));
  const screenHD = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
  const hideRightPanel = useMediaQuery(theme.breakpoints.down(hideRightPanelWidth));
  const hideLeftPanel = useMediaQuery(theme.breakpoints.down(hideLeftPanelWidth));

  const history = useHistory();
  const { filter: filterParam, type: productType } = useParams<{ filter: string; type: string }>();
  const { productType: filtersProductType, fast, inStock: inStockCurrent } = filters;
  const isTableLayout: boolean = window.localStorage.getItem('layoutType') === 'table';

  // const filterParamObject: IParamFilterObject = React.useMemo(() => {
  //   return filterParam
  //     ? filterStringToJson(filterParam, filterOptionsData && filterOptionsData.catalogCategory)
  //     : {
  //         fast: [],
  //         inStock: undefined,
  //         product: undefined,
  //         preOrder: undefined,
  //         supplier: undefined,
  //         discount: undefined,
  //         specialOffers: undefined,
  //         transitOffers: undefined,
  //         specialTransitOffers: undefined,
  //         deliveryRegion: undefined
  //       };
  // }, [filterParam, filterOptionsData]);

  // const { supplier: supplierParam, deliveryRegion: deliveryRegionParam } = filterParamObject;
  const { supplier: supplierParam, deliveryRegion: deliveryRegionParam } = filters;

  const catalogDocs = catalog && catalog.docs;
  const selectedProductCode = selectedProduct && selectedProduct.code;
  // * remove after check select product on reload page and on add product to cart
  // const { product: productCode } = filterParamObject;

  // if (catalogDocs && productCode) {
  //   if (productCode && !catalogLoading && selectedProductCode !== productCode && catalogDocs) {
  //     const product: Product | undefined = catalogDocs.find(item => item.code === productCode);
  //     if (product) {
  //       console.log('select product when update catalog params', productCode, selectedProductCode);
  //       selectProduct({
  //         ...product,
  //         supplier: supplierParam || ''
  //       } as any);
  //       if (isMobile && !layout.rightOpened) {
  //         catalogToggleDetails(true);
  //       }
  //     }
  //   }
  // }

  useEffect(() => {
    // const { product: productCode } = filterParamObject;
    const { product: productCode } = filters;

    if (catalogDocs && productCode) {
      if (productCode && !catalogLoading && selectedProductCode !== productCode && catalogDocs) {
        const product: Product | undefined = catalogDocs.find((item) => item.code === productCode);
        if (product) {
          selectProduct({
            ...product,
            supplier: supplierParam || '',
            deliveryRegion: deliveryRegionParam || ''
          } as any);
          if (isMobile && !layout.rightOpened) {
            catalogToggleDetails(true);
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [filters, catalogDocs, catalogLoading]);

  // -> update filters from url
  const fastLength = !!fast.length;
  const fastFiltersPrev = usePrevious(filters.fast);
  useEffect(() => {
    const isFilterOptionsExists = !!filterOptionsData.attributes.length;
    if (filterParam) {
      if (catalogFilterOptionsLoaded && isFilterOptionsExists) {
        const {
          fast,
          inStock,
          preOrder,
          supplier,
          discount,
          specialOffers,
          transitOffers,
          specialTransitOffers,
          deliveryRegion
        } = filters;

        // * resort catalog when user select new category from header menu
        const currentCategory = filters.fast?.filter((f) => f.path === 'category').map((f) => f.value);
        const prevCategory = fastFiltersPrev?.filter((f) => f.path === 'category').map((f) => f.value);
        if (prevCategory && currentCategory && currentCategory.length === 1 && currentCategory[0] !== prevCategory[0]) {
          catalogFiltersAndSortUpdate({
            sort,
            fast,
            locale,
            inStock: !!inStock,
            preOrder: !!preOrder,
            discount: !!discount,
            supplier,
            specialOffers: !!specialOffers,
            transitOffers: !!transitOffers,
            specialTransitOffers: !!specialTransitOffers,
            deliveryRegion
          });
        }

        // only if filters changed, not a product or etc
        // if (!!inStock !== Boolean(inStockCurrent)) {
        //   catalogFiltersAndSortUpdate({
        //     sort,
        //     fast,
        //     locale,
        //     inStock: !!inStock,
        //     preOrder: !!preOrder,
        //     discount: !!discount,
        //     supplier,
        //     specialOffers: !!specialOffers,
        //     transitOffers: !!transitOffers,
        //     specialTransitOffers: !!specialTransitOffers,
        //     deliveryRegion
        //   });
        // }

        // inStock can be undefined
        if (!!inStock !== Boolean(inStockCurrent) || productType !== filtersProductType) {
          if (productTypeAccumulated) {
            dispatch(
              catalogSetFilter({
                productType: productTypeAccumulated,
                inStock: !!inStock,
                preOrder: !!preOrder,
                discount: !!discount,
                supplier,
                specialOffers: !!specialOffers,
                transitOffers: !!transitOffers,
                specialTransitOffers: !!specialTransitOffers,
                deliveryRegion
              })
            );
            // ! check on staging how it will work without this reloading filter options
            // loadFilterOptions({
            //   productType: productTypeAccumulated,
            //   inStock: !!inStock,
            //   preOrder: !!preOrder,
            //   discount: !!discount,
            //   supplier,
            //   specialOffers: !!specialOffers,
            //   transitOffers: !!transitOffers,
            //   specialTransitOffers: !!specialTransitOffers,
            //   deliveryRegion
            // } as IFilterOptionsRequest);
          }
        }
      }
    } else if (productType !== filtersProductType) {
      // * reload catalog when user select new product type in main menu
      dispatch(catalogSetFilter({ productType }));
      // comment for now to fix the problem with double filters requests
      // loadFilterOptions({
      //   productType: productTypeAccumulated,
      //   inStock: false
      // } as IFilterOptionsRequest);
      dispatch(fetchCatalogAsync.request({ productType }));
    }

    if (!filterParam && fastLength) {
      catalogFiltersAndSortUpdate({ sort, fast: [], locale, inStock: false });
    }

    // * update filters when changed productType (user navigate tru the main menu)
    if (
      // !filterParam &&
      // !fastLength &&
      filtersProductType &&
      productType !== filtersProductType &&
      isFilterOptionsExists
    ) {
      loadFilterOptions({
        productType: productTypeAccumulated,
        inStock: false
      } as IFilterOptionsRequest);
      dispatch(fetchCatalogAsync.request({ productType }));
    }
    // eslint-disable-next-line
  }, [
    // filterParamObject,
    filters,
    filterOptionsData,
    filterParam,
    productType,
    sort,
    locale,
    catalogFiltersAndSortUpdate,
    catalogFilterOptionsLoaded
  ]);
  // <- update filters from url

  // update catalog on filters change, ignore if catalogLoading
  const productTypeAccumulated = productType || filtersProductType;

  // sort catalog on docs loaded, ignore if catalogSorted
  useEffect(() => {
    if (catalogSorted || catalogLoading) {
      return;
    }
    // TODO leave for testing
    // catalogFiltersAndSortUpdate({
    //   sort,
    //   fast: filters.fast,
    //   locale,
    //   inStock: filters.inStock,
    //   preOrder: filters.preOrder,
    //   discount: filters.discount,
    //   supplier: filters.supplier,
    //   specialOffers: filters.specialOffers,
    //   transitOffers: filters.transitOffers,
    //   specialTransitOffers: filters.specialTransitOffers,
    //   deliveryRegion: filters.deliveryRegion
    // inStock: filterParamObject.inStock,
    // preOrder: filterParamObject.preOrder,
    // discount: filterParamObject.discount,
    // supplier: filterParamObject.supplier,
    // specialOffers: filterParamObject.specialOffers,
    // transitOffers: filterParamObject.transitOffers,
    // specialTransitOffers: filterParamObject.specialTransitOffers,
    // deliveryRegion: filterParamObject.deliveryRegion
    // });
    // eslint-disable-next-line
  }, [catalog.docs]);

  const beautifyUrl = () => {
    const {
      inStock,
      productType,
      preOrder,
      discount,
      supplier: filterSupplier,
      specialOffers,
      transitOffers,
      specialTransitOffers,
      deliveryRegion,
      eventPrice
    } = filters;

    const queryStr = `${inStock ? 'inStock=1;' : ''}${preOrder ? 'preOrder=1;' : ''}${discount ? 'discount=1;' : ''}${
      filterSupplier ? 'supplier=' + filterSupplier + ';' : ''
    }${deliveryRegion ? 'deliveryRegion=' + deliveryRegion + ';' : ''}${specialOffers ? 'specialOffers=1;' : ''}${
      transitOffers ? 'transitOffers=1;' : ''
    }${specialTransitOffers ? 'specialTransitOffers=1;' : ''}${eventPrice ? 'eventPrice=' + eventPrice + ';' : ''}`;

    const path = generatePath(`${baseUrl}/catalog/:productType/:filterUrl?`, {
      productType,
      filterUrl: queryStr
    });

    return path;
  };

  const handleSelectProduct = (product: Product) => {
    const {
      selectProduct: select,
      unselectProduct: unselect,
      selectedProduct: selected,
      closeMiniCart: closeCart,
      openMiniCart: openCart
    } = props;

    const filterMap = filtersToString(filters.fast);
    let str = filterJsonToString(filterMap);

    const {
      inStock,
      preOrder,
      discount,
      supplier: filterSupplier,
      productType,
      specialOffers,
      transitOffers,
      specialTransitOffers,
      deliveryRegion,
      eventPrice
    } = filters;
    str = `${inStock ? 'inStock=1;' : ''}${preOrder ? 'preOrder=1;' : ''}${discount ? 'discount=1;' : ''}${
      filterSupplier ? 'supplier=' + filterSupplier + ';' : ''
    }${deliveryRegion ? 'deliveryRegion=' + deliveryRegion + ';' : ''}${specialOffers ? 'specialOffers=1;' : ''}${
      transitOffers ? 'transitOffers=1;' : ''
    }${specialTransitOffers ? 'specialTransitOffers=1;' : ''}${
      eventPrice ? 'eventPrice=' + eventPrice + ';' : ''
    }${str}`;

    if (selected && selected.id === product.id && (screenHD || screenFullHD)) {
      /** Only for authorized user */
      if (user) {
        openCart();
        unselect();
      }
    } else {
      str = `product=${product.code};${str}`;
      closeCart();
      select(product);
    }

    const path = generatePath(`${baseUrl}/catalog/:productType/:filterUrl?`, {
      productType,
      filterUrl: str || undefined
    });
    history.push(path);
  };

  const handleFiltersAsideClose = () => catalogToggleFilters(false);

  const handleDetailsAsideClose = () => {
    catalogToggleDetails(false);
    props.unselectProduct();
  };

  const clearFilters = () => {
    catalogFiltersAndSortUpdate({ fast: [], inStock: true });
    history.push(beautifyUrl());
  };

  const setRegionAndSupplier = ({ deliveryRegion, supplier, doNotShowDeliveryModal }: IDeliveryState) => {
    updateAccount({
      ...accountPreferences,
      deliveryRegion,
      supplier,
      doNotShowDeliveryModal
    });
    loadFilterOptions({ ...filters, deliveryRegion, supplier });

    loadCatalog({ ...filters, deliveryRegion, supplier });
    catalogFiltersAndSortUpdate({ ...filters, deliveryRegion, supplier });

    const { inStock, productType, preOrder, discount, specialOffers, transitOffers, specialTransitOffers, eventPrice } =
      filters;
    const filterMap = filtersToString(filters.fast);
    let str = filterJsonToString(filterMap);

    if (inStock) {
      str = `inStock=${+inStock};${str}`;
    }

    str = `${preOrder ? 'preOrder=1;' : ''}${discount ? 'discount=1;' : ''}${
      supplier ? 'supplier=' + supplier + ';' : ''
    }${deliveryRegion ? 'deliveryRegion=' + deliveryRegion + ';' : ''}${specialOffers ? 'specialOffers=1;' : ''}${
      transitOffers ? 'transitOffers=1;' : ''
    }${specialTransitOffers ? 'specialTransitOffers=1;' : ''}${
      eventPrice ? 'eventPrice=' + eventPrice + ';' : ''
    }${str}`;
    const url = generatePath(`${baseUrl}/catalog/:productType/:filterUrl?`, {
      productType: productType || filters.productType,
      filterUrl: str || undefined
    });
    history.push(url);

    if (selectedProduct && selectedProduct.id) {
      selectProduct({ ...selectedProduct, supplier } as Product);
    }
  };

  useEffect(() => {
    if (screenHD || screenFullHD) {
      catalogToggleFilters(true);
      catalogToggleDetails(true);
    }
    if (hideRightPanel) {
      catalogToggleDetails(false);
    }
    if (hideLeftPanel) {
      catalogToggleFilters(false);
    }
    // eslint-disable-next-line
  }, [screenHD, screenFullHD, hideRightPanel, hideLeftPanel, catalogToggleFilters, catalogToggleDetails]);
  const cardHeight = getCardHeight(isMobile, isTableLayout, !!user);

  return (
    <SidePanelsLayout
      hideMargin
      catalogPanel
      noScroll
      trackAnchor={'catalog'}
      fullScreen={true}
      leftPanelToggleable={!screenFullHD}
      leftPanelOpened={layout.leftOpened}
      onLeftPanelClose={handleFiltersAsideClose}
      leftPanel={(panelProps: any) => (
        <React.Fragment>
          <AsideDeviceHeader
            catalogHeader
            onClose={handleFiltersAsideClose}
            title={filtersMessages.filters.defaultMessage}
            action={filters.fast.length > 0 ? <Link2 onClick={clearFilters}>Очистити</Link2> : undefined}
          />
          <FilterAside
            onClose={handleFiltersAsideClose}
            productType={productType}
            filters={filters}
            loggedIn={user !== null}
            totalProducts={catalog.docs || 0}
            loadFilterOptions={loadFilterOptions}
            {...panelProps}
          />
          <AsideDeviceFooter
            classNameWrapper={classes.footer}
            action={
              <Button color={'primary'} onClick={handleFiltersAsideClose}>
                Показати
              </Button>
            }
            info={
              <TextBody2 className={classes.totalShown}>
                Знайдено:&nbsp;
                {catalogLoading || !catalogSorted ? (
                  <Skeleton variant="rectangular" width="40px" />
                ) : (
                  <b>{docsShown}</b>
                )}
              </TextBody2>
            }
          />
        </React.Fragment>
      )}
      onRightPanelClose={handleDetailsAsideClose}
      rightPanelToggleable={!screenFullHD}
      rightPanelOpened={layout.rightOpened}
      rightPanel={() => (
        <div className={classes.rightPanel}>
          <RightBar onClose={handleDetailsAsideClose} />
        </div>
      )}
    >
      <ProductCatalogMemoized
        productRows={catalog.rows}
        selected={selectedProduct}
        onSelectProduct={handleSelectProduct}
        withPrice={user !== null}
        noAlphabet={isMobile}
        cardHeight={cardHeight}
        filterAndSortCatalog={catalogFiltersAndSortUpdate}
        locale={locale}
      />
      <DeliveryModal
        filters={filters}
        regions={filterOptionsData.regions}
        suppliers={filterOptionsData.suppliers}
        setRegionAndSupplier={setRegionAndSupplier}
        selectedDeliveryRegion={filters.deliveryRegion || accountPreferences?.deliveryRegion}
        showDeliveryModal={
          (!accountPreferences?.supplier || !accountPreferences?.deliveryRegion) &&
          !accountPreferences?.doNotShowDeliveryModal
        }
      />
    </SidePanelsLayout>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IApplicationState> = (state: IApplicationState) => ({
  user: getUserAccount(state),
  layout: getCatalogLayout(state),
  locale: state.locale,
  catalog: getCatalogData(state),
  filters: getCatalogFilters(state),
  sort: getCatalogSort(state),
  selectedProduct: getSelectedProductData(state),
  catalogFilterOptionsLoaded: getFilterOptionsLoadedState(state),
  filterOptionsData: getFilterOptionsData(state),
  catalogLoading: getCatalogLoadingState(state),
  catalogSorted: getCatalogSortedState(state),
  docsShown: getCatalogDocsShown(state),
  accountPreferences: getUserPreferences(state)
});

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, IOwnProps> = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      filterAndSortCatalog,
      selectProduct: (product: Product) => selectProductAsync.request(product),
      unselectProduct,
      closeMiniCart,
      openMiniCart,
      loadFilterOptions: fetchFilterOptionsAsync.request,
      toggleFilters,
      toggleDetails,
      updateAccount: updatePreferencesAccountAsync.request,
      loadCatalog: fetchCatalogAsync.request
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles<any>(styles)(CatalogPage as any));
