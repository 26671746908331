import { FC } from 'react';
import GoogleMapReact from 'google-map-react';

import Icon from 'components/shared/Icon';
import mapColorOptions from 'styles/map/default';

import classes from './OfficesMap.module.scss';

export interface ICoordinates {
  lat: number;
  lng: number;
}

export interface IFooterMapProps {
  center?: ICoordinates;
  zoom?: number;
}

const OfficesMap: FC<IFooterMapProps> = ({ center, zoom }) => {
  const defaultProps = {
    center: {
      lat: 50.474261,
      lng: 30.590020
    },
    zoom: 14
  };

  const iconCommonProps = {
    className: classes.mapIcon,
    type: 'markerGoogle',
    width: 21,
    height: 38,
    opacity: 1
  };

  return (
    <div className={classes.mapContainer}>
      <div className={classes.mapWrapper}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAniQiNgrAbCIDsBqcBSY0Iqyw0-qNBWRM' }}
          defaultCenter={center || defaultProps.center}
          defaultZoom={zoom || defaultProps.zoom}
          options={{ styles: mapColorOptions }}
        >
          <Icon {...iconCommonProps} {...(center || defaultProps.center)} />
          <Icon {...iconCommonProps} lat={49.831836} lng={24.023147} />
        </GoogleMapReact>
      </div>
    </div>
  );
};

export { OfficesMap };
