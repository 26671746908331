import classes from './FooterCopyright.module.scss';

const FooterCopyright = () => {
  const thisYear = new Date().getFullYear();
  const sinceYear = 2019;
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.wrapper}>
          &copy; {sinceYear}
          {sinceYear !== thisYear ? ` - ${thisYear}` : ''} Floroteka
        </div>
        <img src="/images/visa-master.webp" alt="Приймаємо Visa і MasterCard" className={classes.img} />
      </div>
    </div>
  );
};

export { FooterCopyright };
