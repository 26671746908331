import { FC, useEffect } from 'react';
import { useHistory } from 'react-router';

import { Footer } from 'components/layout/footer';
import Header from 'components/layout/header-main';

import { MainContacts } from '../../components/shared/contacts';
// import FeaturedSection from './featured-products-section';
import { MainFeatures } from './features/index';
import { MainHotOffers } from './hot-offers';
// import MainPromo from './promo';
// import MainSubscribe from './subscribe';
// import MainTestimonials from './testimonials';
import { MainTop } from './top/MainTop';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

// import {
//   catalogProductTypeAccessories,
//   catalogProductTypePotted,
//   catalogProductTypeShear,
//   catalogProductTypeStable
// } from 'shared/constants';

const IndexPage: FC = () => {
  const history = useHistory();
  useEffect(() => {
    const redirectAfterLogin = window.localStorage.getItem('redirect_after_login');
    if (redirectAfterLogin && redirectAfterLogin !== '/') {
      history.replace(redirectAfterLogin);
      window.localStorage.removeItem('redirect_after_login');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header main />
      <main>
        <MainTop />

        {/*<FeaturedSection productType={catalogProductTypeShear} />*/}

        <MainFeatures />

        <MainHotOffers />

        {/*<div>Кімнатні рослини</div>*/}
        {/*<FeaturedSection productType={catalogProductTypePotted} />*/}

        {/*<MainSubscribe />*/}

        {/*<div>Стабілізовані квіти </div>*/}
        {/*<FeaturedSection productType={catalogProductTypeStable} />*/}

        {/*<MainPromo />*/}

        {/*<div>Фурнітура </div>*/}
        {/*<FeaturedSection productType={catalogProductTypeAccessories} />*/}

        {/*<MainTestimonials />*/}

        <MainContacts />
      </main>
      <Footer />
    </>
  );
};

export default IndexPage;
