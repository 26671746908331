import { FC, useMemo } from 'react';
import { Button } from '@mui/material';

import MiniCartDetailsCard from 'components/cards/cart-mini/MiniCartDetailsCard';
import EmptyCart from 'components/EmptyCart';
import FlrLoader from 'components/loading/LoadingSpinner';
import { TitleH2 } from 'components/shared/text';
import { Cart, CartItem } from 'models';
import cartMessages from 'translations/cart/common';
import miniCartMessages from 'translations/cart/mini-cart';

import classes from './MiniCart.module.scss';

interface IProps {
  cart: Cart;
  onPurchaseClick: () => void;
  onEdit: (items: CartItem[]) => void;
  loading: boolean;
}

const MiniCart: FC<IProps> = ({ cart, onEdit, onPurchaseClick, loading }) => {
  const preOrderItems = useMemo(() => cart.items.filter((item) => item.offer.preOrder), [cart]);
  const transitOrderItems = useMemo(() => cart.items.filter((item) => item.offer.transit), [cart]);
  const regularItems = useMemo(
    () => cart.items.filter((item) => !item.offer.preOrder && !item.offer.transit && !item.offer.extraPrice),
    [cart]
  );
  const extraPriceItems = useMemo(() => cart.items.filter((item) => item.offer.extraPrice), [cart]);
  const hasItems = cart.items.length > 0;

  return (
    <div className={classes.container}>
      <TitleH2 component="h4" align={'center'} className={classes.cardTitle}>
        {miniCartMessages.productsInCart.defaultMessage}
      </TitleH2>
      {loading ? (
        <div className={classes.loadingContainer}>
          <FlrLoader />
        </div>
      ) : null}
      {!hasItems && !loading ? <EmptyCart /> : null}
      {regularItems.length > 0 && <MiniCartDetailsCard timerType="inStock" items={regularItems} onUpdate={onEdit} />}
      {transitOrderItems.length > 0 && (
        <MiniCartDetailsCard timerType="transit" items={transitOrderItems} onUpdate={onEdit} transit />
      )}
      {extraPriceItems.length > 0 && (
        <MiniCartDetailsCard items={extraPriceItems} onUpdate={onEdit} extraPrice extraPriceLabel={cart.extraPriceLabel.defaultMessage} />
      )}
      {preOrderItems.length > 0 && <MiniCartDetailsCard items={preOrderItems} onUpdate={onEdit} preOrder />}
      {hasItems && (
        <div className={classes.miniCartCardSummary}>
          <Button variant={'contained'} color={'primary'} fullWidth onClick={onPurchaseClick}>
            {cartMessages.buy.defaultMessage}
          </Button>
        </div>
      )}
    </div>
  );
};

export default MiniCart;
