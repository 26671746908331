import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';

import { hotOfferProductsAsync } from 'store/main/actions';
import { getHotOffersData } from 'store/main/selectors';

import { HotOfferItem } from './HotOfferItem';

import classes from './hotOffer.module.scss';

const defaultSettings = {
  dots: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  respondTo: 'min',
  infinite: false,
  initialSlide: 0,
  nextArrow: <Fragment />,
  prevArrow: <Fragment />
};

const MainHotOffers = () => {
  const dispatch = useDispatch();
  const hotOffers = useSelector(getHotOffersData);

  useEffect(() => {
    dispatch(hotOfferProductsAsync.request());
  }, []);

  if (
    !hotOffers ||
    (hotOffers && hotOffers.length && hotOffers.map((o) => Number(new Date(o.expiredTime)) < Date.now()))
  ) {
    return null;
  }

  return (
    <section className={classes.root}>
      <Slider {...defaultSettings}>
        {hotOffers.map((item) => (
          <HotOfferItem key={item._id} hotOffer={item} />
        ))}
      </Slider>
    </section>
  );
};

export { MainHotOffers };
