import Icon from 'components/shared/Icon';
import { PhoneLink } from 'components/shared/PhoneLink';
import { SocialLinks } from 'components/shared/SocialLinks';

import classes from './FooterBottom.module.scss';

const FooterBottom = () => (
  <div className={classes.container}>
    <div className={classes.wrapper}>
      <PhoneLink />
      <Icon type={'logoFull'} height={24} opacity={1} />
      <SocialLinks classNameWrapper={classes.socialLinks} />
    </div>
  </div>
);

export { FooterBottom };
