import React, { FC } from 'react';
import clsx from 'clsx';
import upperFirst from 'lodash/upperFirst';
import moment from 'moment';
import Button from '@mui/material/Button';

import { Text } from 'components/layout/content/Text';
import FlrLoader from 'components/loading/LoadingSpinner';
import FlrCard from 'components/shared/card/FlrCard';
import { TextSubTitle } from 'components/shared/text';
import { GTM } from 'controllers';
import { CartGroup, CartItem, Product } from 'models';
import { INFINITE } from 'shared/constants';
import { fetchAccountAsync } from 'store/account/actions';
import messages from 'translations/cart/common';
import messagesExpired from 'translations/cart/expired';
import messagesMiniDetails from 'translations/catalog/mini-details';
import { getMinMaxPreOrderDates } from 'utils/time';

import { NotEnoughBalanceAlert } from '../../../shared/Alert/NotEnoughBalanceAlert';
import CartGroupCardSubtotal from '../CartGroupCardSubtotal';
import CartGroupCardItemDevice from './CartGroupCardItem';

import classes from './CartGroupCardDevice.module.scss';

interface IProps {
  balance?: number;
  cartGroupName: string;
  cartGroup: CartGroup;
  selectedProductId: string;
  hasNext?: boolean;
  expired?: boolean;
  preOrder?: boolean;
  anchor: string;
  addBalance?: () => void;
  loadAccountData: typeof fetchAccountAsync.request;
  onQtyUpdate: (item: CartItem) => void;
  onRemoveItem: (item: CartItem) => void;
  onSelectItem: (item: CartItem) => void;
  onMoreInfo: (product: Product) => void;
  onUnselectItem: () => void;
  onSubmit: (item: CartGroup) => void;
}

const CartGroupCard: FC<IProps> = ({
  balance = 0,
  addBalance,
  loadAccountData,
  cartGroupName,
  cartGroup,
  selectedProductId,
  expired = false,
  preOrder = false,
  onRemoveItem,
  onSelectItem,
  onUnselectItem,
  onMoreInfo,
  onSubmit,
  onQtyUpdate
}) => {
  const notEnough = !expired && balance < 0;

  const handleQtyChange = (item: CartItem, qty: number) => {
    const newItem = new CartItem(item);
    newItem.qty = qty;
    onQtyUpdate(newItem);
  };

  const handleProcessButton = () => {
    if (expired && !preOrder) {
      GTM.trackRestoreExpiredButton(cartGroup);
    }
    if (notEnough) {
      loadAccountData();
      if (addBalance) {
        addBalance();
      }
    } else {
      onSubmit(cartGroup);
    }
  };

  const discount = cartGroup.discount;
  const totalQty = cartGroup.totalQty;
  const allArchived = cartGroup.allArchived;

  const deliveryOptions: any[] = getMinMaxPreOrderDates([cartGroup.items[0]], cartGroup.preOrder, cartGroup.endDate);

  return (
    <FlrCard id={cartGroupName} className={clsx(classes.cartGroupCard, { [classes.expired]: expired })}>
      {notEnough ? (
        <NotEnoughBalanceAlert
          addBalance={addBalance}
          amount={balance}
          withoutMarginTop
          className={classes.notEnoughBalanceAlert}
        />
      ) : null}
      <div className={classes.container}>
        <div className={classes.header}>
          <TextSubTitle>
            {cartGroup.preOrder || cartGroup.extraPrice
              ? 'Флоротека'
              : cartGroup.warehouse
              ? cartGroup.warehouse.displayName
              : ''}
          </TextSubTitle>
          <div className={classes.transitDeliveryTypeContainer}>
            <div className={classes.deliveryDatesContainer}>
              {cartGroup.extraPrice ? (
                <span>
                  <Text bold>{moment(cartGroup.startDate).format('dd DD.MM')}</Text>
                  {' - '}
                  {moment(cartGroup.endDate).format('dd DD.MM')}
                </span>
              ) : (
                <span>
                  <Text bold>{upperFirst(deliveryOptions[0] ? deliveryOptions[0].format('dd DD.MM') : '')}</Text>
                  {' - '}
                  {cartGroup.preOrder || cartGroup.extraPrice
                    ? INFINITE
                    : upperFirst(deliveryOptions[1] ? deliveryOptions[1].format('dd DD.MM') : '')}
                </span>
              )}
            </div>
            {cartGroup.transit ? (
              <div className={classes.transitGroup}>
                <span className={classes.transitDeliveryTime}>
                  {upperFirst(moment(cartGroup.endDate).format('dd DD.MM'))}
                </span>
                <div className={classes.transitDeliveryText}>
                  {messagesMiniDetails.transitDeliveryTextShort.defaultMessage}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className={classes.wrapper}>
          {cartGroup.items.map((item: CartItem, index: number) => (
            <CartGroupCardItemDevice
              expired={expired}
              max={item.offer.inStock}
              key={`cart-group-item-${index}`}
              item={item}
              selected={item.offer.productId === selectedProductId}
              onChangeAmount={(qty: number) => handleQtyChange(item, qty)}
              onRemove={() => onRemoveItem(item)}
              onSelect={() => onSelectItem(item)}
              onUnselect={() => onUnselectItem()}
            />
          ))}
        </div>
        <div className={classes.subTotalContainer}>
          <CartGroupCardSubtotal
            itemsNumber={totalQty}
            amount={cartGroup.subtotal - cartGroup.discount}
            discount={discount}
            isAvailable={!allArchived}
            hasPreOrder={preOrder}
          />
        </div>
        <Button
          fullWidth
          disabled={cartGroup.processing}
          color={'primary'}
          variant={!expired && preOrder ? 'outlined' : 'contained'}
          onClick={handleProcessButton}
          className={classes.checkoutOrderFooterBlock}
        >
          {cartGroup.processing && <FlrLoader size={16} />}
          {notEnough ? (
            messages.notEnoughFundsBtn.defaultMessage
          ) : (
            <>
              {expired && preOrder && messagesExpired.restoreAndPreOrder.defaultMessage}
              {expired && !preOrder && messagesExpired.restore.defaultMessage}
              {!expired && preOrder && messages.preOrder.defaultMessage}
              {!expired && !preOrder && messages.toDelivery.defaultMessage}
            </>
          )}
        </Button>
      </div>
    </FlrCard>
  );
};

export default CartGroupCard;
