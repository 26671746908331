import { CartItem } from './cart-item';
import { Warehouse } from './warehouse';

export type CartGroupsList = Record<string, CartGroup>;

export class CartGroup {
  public preOrder: boolean;
  public transit: boolean;
  public extraPrice: boolean;
  public supplier: string;
  public productType: string; // rewrite to some ENUM-like
  public warehouse: Warehouse;
  public items: CartItem[];
  public subtotal: number;
  public total: number;
  public discount: number;
  public expiresOn: string | Date;
  public endDate: string;
  public groupLabel: string;
  public startDate?: string;

  public processing: boolean;

  constructor(groupData: any, serverTimeDiff: number) {
    this.preOrder = !!groupData.preOrder;
    this.transit = !!groupData.transit;
    this.extraPrice = !!groupData.extraPrice;
    this.supplier = groupData.supplier;
    this.productType = groupData.productType;
    this.warehouse = groupData.warehouse;
    this.groupLabel = groupData.groupLabel;
    this.items = (groupData.items || []).map((item: any) => new CartItem(item));
    this.subtotal = parseFloat(groupData.subtotal);
    this.total = groupData.total;
    this.discount = parseFloat(groupData.discount);
    this.processing = false;
    this.expiresOn = new Date(new Date(groupData.expiresOn).getTime() + serverTimeDiff);
    this.endDate = groupData.endDate;
    this.startDate = groupData.startDate;
  }

  get totalQty(): number {
    return (this.items || []).reduce((qty: number, item: CartItem) => qty + item.qty, 0);
  }

  get allArchived(): boolean {
    return (this.items || []).reduce((allArch: boolean, item: CartItem) => allArch && item.product.archived, true);
  }
}
