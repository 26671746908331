import { FC } from 'react';
import { defineMessages } from 'react-intl';

import { workHoursArray } from '../../shared/constants';
import Icon from './Icon';

import classes from './WorkingHours.module.scss';

export const whMessages = defineMessages({
  weekends: {
    id: 'header.workingHours.weekends',
    description: 'weekends',
    defaultMessage: 'без вихідних'
  }
});

interface IProps {
  withoutIcon?: boolean;
}

const WorkingHours: FC<IProps> = ({ withoutIcon }) => (
  <div className={classes.container}>
    {!withoutIcon ? <Icon type="clock" opacity={1} size={18} /> : null}
    <div className={classes.wrapper}>
      <p className={classes.hours}>{`з ${workHoursArray[0]} по ${workHoursArray[1]}`}</p>
      <p className={classes.weekends}>{whMessages.weekends.defaultMessage}</p>
    </div>
  </div>
);

export { WorkingHours };
