import { staticPages } from 'pages/static';
import { baseUrl } from 'shared/constants';

import { FooterNavGroup, IFooterNavGroupData } from './FooterNavGroup';

import classes from './FooterNav.module.scss';

const FooterNav = () => {
  const navLinksData: IFooterNavGroupData[] = [];
  ['Про компанію' /*, "Логистика", "Покупателям", "Сотрудничество", "Контакты", "Сотрудничество"*/].map((groupItem) => {
    const navGroupData: IFooterNavGroupData = {
      title: `${groupItem}`,
      links: []
    };
    Object.keys(staticPages).forEach((key) => {
      const page = staticPages[key];
      if (page) {
        navGroupData.links.push({
          title: page.pageTitle,
          url: `${baseUrl}/${key}`
        });
      }
    });
    return navLinksData.push(navGroupData);
  });

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        {navLinksData.map((group, index) => (
          <FooterNavGroup key={index} title={group.title} links={group.links} />
        ))}
      </div>
    </div>
  );
};

export { FooterNav };
