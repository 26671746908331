import { generatePath } from 'react-router-dom';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { createAction, createAsyncAction, createReducer, PayloadAction } from 'typesafe-actions';
// @ts-ignore
import worker from 'workerize-loader!./worker'; // eslint-disable-line import/no-webpack-loader-syntax

import { IPanelsLayout } from 'components/layout/side-panels-layout';
import { GTM } from 'controllers';
import { DetailedProduct } from 'models/detailed-product';
import { IProductAlphabet, IProductAlphaLetter, Product } from 'models/product';
import {
  baseUrl,
  cartDrawerWidth,
  categories,
  filtersDrawerWidth,
  productCardMinWidth,
  productCardsMargin,
  productListPadding
} from 'shared/constants';
import {
  catalogDataWrapper as dataWrapper,
  defaultDataWrapper,
  IAsyncCatalogDataWrapper as IAsyncDataWrapper,
  IAsyncDataWrapper as IAsyncDataWrapperDefault,
  loadedDataWrapper as productDataWrapper,
  REQUEST_ACTIONS
} from 'store/actions';
import { breakpoints } from 'styles/theme/default';
import { filterJsonToString, filtersToString } from 'utils/filters';
import { chunk } from 'utils/helpers';

import { IDynamicStatus, IFilterOptions } from '../../models';
import { fetchFilterOptionsAsync, transformStockStatuses } from '../filter-options/actions';
import { FilterOptionsRepository } from '../filter-options/request';
import { getFilterOptionsType } from '../filter-options/selectors';
import { IApplicationState } from '../reducers';
import { CatalogRepository } from './request';
import {
  getCatalogData,
  getCatalogFilters,
  getCatalogLayout,
  getCatalogLoadingState,
  getCatalogSort,
  getSelectedProductData,
  getSelectedProductLoadingState
} from './selectors';

export const prefix = '@@catalog/';

export const CATALOG_AND_FILTERS_REQUEST = `${prefix}CATALOG_AND_FILTERS_REQUEST`;
export const CATALOG_REQUEST = `${prefix}${REQUEST_ACTIONS.REQUEST}`;
export const CATALOG_SUCCESS = `${prefix}${REQUEST_ACTIONS.SUCCESS}`;
export const CATALOG_FAILURE = `${prefix}${REQUEST_ACTIONS.FAILURE}`;

export const START_FETCHING_PRODUCTS = `${prefix}START_FETCHING_PRODUCTS`;
export const START_FETCHING_PRODUCT = `${prefix}START_FETCHING_PRODUCT`;
export const SET_SELECTED_PRODUCT = `${prefix}SET_SELECTED_PRODUCT`;

export const CATALOG_PRELOAD_PRODUCTS_REQUEST = `${prefix}PRELOAD_PRODUCTS_${REQUEST_ACTIONS.REQUEST}`;
export const CATALOG_PRELOAD_PRODUCTS_SUCCESS = `${prefix}PRELOAD_PRODUCTS_${REQUEST_ACTIONS.SUCCESS}`;
export const CATALOG_PRELOAD_PRODUCTS_FAILURE = `${prefix}PRELOAD_PRODUCTS_${REQUEST_ACTIONS.FAILURE}`;

export const CATALOG_SET_FILTER = `${prefix}SET_FILTER`;
export const CATALOG_FILTER_RESET = `${prefix}FILTER_RESET`;
export const CATALOG_LIST_RESET = `${prefix}LIST_RESET`;
export const CATALOG_UNSELECT_PRODUCT = `${prefix}UNSELECT_PRODUCT`;
export const INTERVAL_PRODUCT_DETAILS = `${prefix}INTERVAL_PRODUCT_DETAILS`;
export const CATALOG_SELECT_PRODUCT_REQUEST = `${prefix}SELECT_PRODUCT_${REQUEST_ACTIONS.REQUEST}`;
export const CATALOG_SELECT_PRODUCT_SUCCESS = `${prefix}SELECT_PRODUCT_${REQUEST_ACTIONS.SUCCESS}`;
export const CATALOG_SELECT_PRODUCT_FAILURE = `${prefix}SELECT_PRODUCT_${REQUEST_ACTIONS.FAILURE}`;

export const CATALOG_HOT_OFFER_REQUEST = `${prefix}HOT_OFFER_${REQUEST_ACTIONS.REQUEST}`;
export const CATALOG_HOT_OFFER_SUCCESS = `${prefix}HOT_OFFER_${REQUEST_ACTIONS.SUCCESS}`;
export const CATALOG_HOT_OFFER_FAILURE = `${prefix}HOT_OFFER_${REQUEST_ACTIONS.FAILURE}`;

export const CATALOG_SEARCH_REQUEST = `${prefix}SEARCH_${REQUEST_ACTIONS.REQUEST}`;
export const CATALOG_SEARCH_SUCCESS = `${prefix}SEARCH_${REQUEST_ACTIONS.SUCCESS}`;
export const CATALOG_SEARCH_FAILURE = `${prefix}SEARCH_${REQUEST_ACTIONS.FAILURE}`;

export const PRODUCT_WATCH_REQUEST = `${prefix}PRODUCT_WATCH_${REQUEST_ACTIONS.REQUEST}`;
export const PRODUCT_WATCH_SUCCESS = `${prefix}PRODUCT_WATCH_${REQUEST_ACTIONS.SUCCESS}`;
export const PRODUCT_WATCH_FAILURE = `${prefix}PRODUCT_WATCH_${REQUEST_ACTIONS.FAILURE}`;

// Data handling actions
export const CATALOG_FILTER_AND_SORT = `${prefix}FILTER_AND_SORT`;
export const CATALOG_SET_FILTERED = `${prefix}SET_FILTERED`;
export const SET_POST_FILTERED_FILTERS = `${prefix}SET_POST_FILTERED_FILTERS`;
export const CATALOG_UPDATE_ALPHABET = `${prefix}UPDATE_ALPHABET`;
export const CATALOG_UPDATE_HIGHLIGHTED_ALPHABET = `${prefix}UPDATE_HIGHLIGHTED_ALPHABET`;

// Catalog layout actions
export const CATALOG_SET_COLUMNS = `${prefix}SET_COLUMNS`;
export const CATALOG_TOGGLE_FILTERS = `${prefix}TOGGLE_FILTERS`;
export const CATALOG_TOGGLE_DETAILS = `${prefix}TOGGLE_DETAILS`;

export type ISelectedProduct = DetailedProduct | Product | null;

export interface ICatalogRequest {
  productType?: string;
  deliveryRegion?: string;
  inStock?: boolean;
  preOrder?: boolean;
  discount?: boolean;
  supplier?: string;
  watchOnly?: boolean;
  specialOffers?: boolean;
  transitOffers?: boolean;
  specialTransitOffers?: boolean;
  filters?: IFiltersData;
  fast?: IFilterProps;
  eventPrice?: string;
}

export interface ICatalog {
  list: ICatalogData;
  highlightedAlphabet: IProductAlphabet;
  sort: string;
  filters: IFiltersData;
  postFilteredFilters: Map<any, any>;
  layout: ICatalogLayout;
  selectedProduct: IAsyncDataWrapper<ISelectedProduct>;
  searchData: IAsyncDataWrapperDefault<ISearchData>;
  fetchingProducts: boolean;
}

export interface IFiltersData {
  productType: string;
  product?: string;
  inStock: boolean;
  preOrder?: boolean;
  supplier?: string;
  watchOnly?: boolean;
  discount?: boolean;
  specialOffers?: boolean;
  fast: IFilterProps;
  transitOffers?: boolean;
  specialTransitOffers?: boolean;
  deliveryRegion?: string;
  eventPrice?: string;
}

export interface ICatalogData {
  docs: Product[];
  rows: Product[][];
  productsMap: Map<string, Product>;
  alphabet: IProductAlphabet;
  docsShown: number;
  loaded: boolean;
}

export interface ICatalogLayout extends IPanelsLayout {
  columns: number;
}

export interface IFilterAndSortProps {
  filters?: IFilterProps;
  fast?: IFilterProps;
  sort?: string;
  locale?: string;
  watchOnly?: boolean;
  inStock?: boolean;
  preOrder?: boolean;
  supplier?: string;
  discount?: boolean;
  specialOffers?: boolean;
  transitOffers?: boolean;
  specialTransitOffers?: boolean;
  deliveryRegion?: string;
  productType?: string;
  dynamicStatuses?: Record<string, IDynamicStatus>;
}

export type FilterRule = 'eq' | 'range' | 'include';

export interface IFilterProp {
  path: string;
  value: string;
  time?: number;
  label?: string;
  rule?: FilterRule;
}

export interface ISearchQuery {
  searchString: string;
  productTypeCode: string;
  supplier?: string;
}
export interface IWatchQuery {
  productId: string;
  watch: boolean;
}
export interface ISearchResultItem {
  code: string;
  label: string;
  value: string;
  alias?: string;
}
export interface ISearchResults {
  products?: DetailedProduct[];
  catalogCategory?: ISearchResultItem[];
  color?: ISearchResultItem[];
  country?: ISearchResultItem[];
  height?: ISearchResultItem[];
  manufacturer?: ISearchResultItem[];
}
export interface ISearchData {
  results: ISearchResults;
  resultsOrder: object;
}

export interface IFilterCombined {
  value: string[];
  rule: FilterRule;
}
export type IFilterProps = IFilterProp[];
export type IFiltersCombined = Record<string, IFilterCombined>;

export type ICatalogState = IAsyncDataWrapper<ICatalog>;
const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
let openedPanelsWidth = 0;
if (screenWidth > breakpoints.md) {
  if (screenWidth > breakpoints.lg) {
    openedPanelsWidth += filtersDrawerWidth;
  }
  if (screenWidth > breakpoints.lg) {
    openedPanelsWidth += cartDrawerWidth;
  }
}

export const layoutListColumnsDefault = Math.max(
  2,
  Math.floor(
    (screenWidth - openedPanelsWidth - productCardsMargin - productListPadding) /
      (productCardMinWidth + productCardsMargin)
  )
);
export const layoutColumnsDefault =
  window.localStorage.getItem('layoutType') === 'table' ? 1 : layoutListColumnsDefault;

const searchDataDefault = {
  results: {},
  resultsOrder: {}
};

export const runPrepare = worker();

export const catalogInitialState: ICatalogState = dataWrapper({
  list: {
    docs: [],
    rows: [],
    alphabet: [],
    productsMap: new Map(),
    docsShown: 0,
    loaded: false
  } as ICatalogData,
  highlightedAlphabet: [],
  layout: {
    columns: layoutColumnsDefault,
    leftOpened: false,
    rightOpened: false
  } as ICatalogLayout,
  filters: {
    watchOnly: false,
    transitOffers: false,
    specialTransitOffers: false,
    inStock: false,
    productType: '',
    fast: []
  } as IFiltersData,
  postFilteredFilters: new Map(),
  sort: 'name_asc',
  selectedProduct: dataWrapper(null),
  searchData: defaultDataWrapper(searchDataDefault),
  loaded: false,
  fetchingProducts: false
});

const httpClient = new CatalogRepository();
const filtersHttpClient = new FilterOptionsRepository();

export const fetchCatalogAndFilters = createAction(CATALOG_AND_FILTERS_REQUEST)<any>();
export const filterAndSortCatalog = createAction(CATALOG_FILTER_AND_SORT)<IFilterAndSortProps>();
export const setFilteredCatalog = createAction(CATALOG_SET_FILTERED)<any>();
export const catalogSetPostFilteredFilters = createAction(SET_POST_FILTERED_FILTERS)<any>();
export const updateHighlightedAlphabet = createAction(CATALOG_UPDATE_HIGHLIGHTED_ALPHABET)<IProductAlphaLetter[]>();
export const toggleFilters = createAction(CATALOG_TOGGLE_FILTERS)<boolean>();
export const toggleDetails = createAction(CATALOG_TOGGLE_DETAILS)<boolean>();
export const unselectProduct = createAction(CATALOG_UNSELECT_PRODUCT)<void>();
export const resetFilter = createAction(CATALOG_FILTER_RESET)<void>();
export const resetCatalogList = createAction(CATALOG_LIST_RESET)<void>();
export const catalogSetFilter = createAction(CATALOG_SET_FILTER)<IFilterAndSortProps>();
export const intervalProductDetails = createAction(INTERVAL_PRODUCT_DETAILS)<void>();
export const startFetchingProducts = createAction(START_FETCHING_PRODUCTS)<void>();
export const startFetchingProduct = createAction(START_FETCHING_PRODUCT)<void>();
export const setSelectedProduct = createAction(SET_SELECTED_PRODUCT)<ISelectedProduct>();

export const catalogPreloadProductsAsync = createAsyncAction(
  CATALOG_PRELOAD_PRODUCTS_REQUEST,
  CATALOG_PRELOAD_PRODUCTS_SUCCESS,
  CATALOG_PRELOAD_PRODUCTS_FAILURE
)<any, any, Error>();

export const selectProductAsync = createAsyncAction(
  CATALOG_SELECT_PRODUCT_REQUEST,
  CATALOG_SELECT_PRODUCT_SUCCESS,
  CATALOG_SELECT_PRODUCT_FAILURE
)<Product, DetailedProduct, Error>();

export const hotOfferProductAsync = createAsyncAction(
  CATALOG_HOT_OFFER_REQUEST,
  CATALOG_HOT_OFFER_SUCCESS,
  CATALOG_HOT_OFFER_FAILURE
)<string, DetailedProduct, Error>();

export const searchAsync = createAsyncAction(CATALOG_SEARCH_REQUEST, CATALOG_SEARCH_SUCCESS, CATALOG_SEARCH_FAILURE)<
  ISearchQuery,
  ISearchData,
  Error
>();
export const watchProductAsync = createAsyncAction(PRODUCT_WATCH_REQUEST, PRODUCT_WATCH_SUCCESS, PRODUCT_WATCH_FAILURE)<
  IWatchQuery,
  any,
  Error
>();

export const fetchCatalogAsync = createAsyncAction(CATALOG_REQUEST, CATALOG_SUCCESS, CATALOG_FAILURE)<
  ICatalogRequest,
  any,
  Error
>();

function* catalogSaga(action: ReturnType<typeof fetchCatalogAsync.request>): Generator {
  try {
    const stateFilters: any = yield select(getCatalogFilters);
    const response: any = yield call(() => httpClient.getAll(Object.assign(stateFilters, action.payload)));
    if (response && (response.status === 401 || response.status === 403 || response.status === 503)) {
      window.localStorage.setItem('redirect_after_login', window.location.pathname);
      yield put({ type: '@@auth/LOGOUT' });
      window.location.href = '/login';
    } else {
      (response as IAsyncDataWrapper<ICatalogData>).data.docs = (
        response as IAsyncDataWrapper<ICatalogData>
      ).data.docs.map((doc) => new Product(doc));
      yield put(fetchCatalogAsync.success({ data: response.data, filters: action.payload.filters }));
    }
  } catch (err) {
    yield put(fetchCatalogAsync.failure(err as Error));
  }
}

function* fetchCatalogAndFiltersSaga(action: ReturnType<typeof fetchCatalogAndFilters>): Generator {
  try {
    // TODO get filters from filters
    const stateFilters: any = yield select(getCatalogFilters);
    const currentFilters = Object.assign(stateFilters, action.payload);
    // * fetch filter options
    const filtersOptionsType: any = yield select(getFilterOptionsType);
    if (!filtersOptionsType || !filtersOptionsType.length) {
      const filtersResponse: any = yield call(() => filtersHttpClient.fetch(currentFilters));
      filtersResponse.stockStatuses = transformStockStatuses(filtersResponse.stockStatuses);
      (filtersResponse as IFilterOptions).type.forEach((typeOption) => {
        for (const alias of Object.keys(categories)) {
          if (typeOption && categories[alias] === typeOption.name) {
            typeOption.alias = alias;
            return typeOption;
          }
        }
      });
      // sort regions by name
      filtersResponse.regions = (filtersResponse as IFilterOptions).regions.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      yield put(fetchFilterOptionsAsync.success(filtersResponse));
    }

    const catalogListData: any = yield select(getCatalogData);
    const catalogLoading: any = yield select(getCatalogLoadingState);
    if ((!catalogListData.docs || !catalogListData.docs.length) && !catalogLoading) {
      const catalogResponse: any = yield call(() => httpClient.getAll(currentFilters));
      if (
        catalogResponse &&
        (catalogResponse.status === 401 || catalogResponse.status === 403 || catalogResponse.status === 503)
      ) {
        window.localStorage.setItem('redirect_after_login', window.location.pathname);
        yield put({ type: '@@auth/LOGOUT' });
        window.location.href = '/login';
      } else {
        (catalogResponse as IAsyncDataWrapper<ICatalogData>).data.docs = (
          catalogResponse as IAsyncDataWrapper<ICatalogData>
        ).data.docs.map((doc) => new Product(doc));
        yield put(fetchCatalogAsync.success({ data: catalogResponse.data }));
      }
    }

    // uncomment for catalog_v2
    // * fetch catalog preview
    // const catalogListData: any = yield select(getCatalogData);
    // if (!catalogListData.docs || !catalogListData.docs.length) {
    //   const stateFilters: any = yield select(getCatalogFilters);
    //   const catalogResponse: any = yield call(() =>
    //     httpClient.getAllPreviews(Object.assign(stateFilters, action.payload))
    //   );
    //   if (
    //     catalogResponse &&
    //     (catalogResponse.status === 401 || catalogResponse.status === 403 || catalogResponse.status === 503)
    //   ) {
    //     window.localStorage.setItem('redirect_after_login', window.location.pathname);
    //     yield put({ type: '@@auth/LOGOUT' });
    //     window.location.href = '/login';
    //   } else {
    //     yield put(fetchCatalogAsync.success({ data: catalogResponse.data }));
    //   }
    // }
  } catch (err) {
    yield put(fetchCatalogAsync.failure(err as Error));
  }
}

// function* catalogSaga_v2(action: ReturnType<typeof fetchCatalogAsync.request>): Generator {
//   try {
//     const stateFilters: any = yield select(getCatalogFilters);
//     const response: any = yield call(() => httpClient.getAllPreviews(Object.assign(stateFilters, action.payload)));
//     if (response && (response.status === 401 || response.status === 403 || response.status === 503)) {
//       window.localStorage.setItem('redirect_after_login', window.location.pathname);
//       yield put({ type: '@@auth/LOGOUT' });
//       window.location.href = '/login';
//     } else {
//       yield put(fetchCatalogAsync.success({ data: response.data, filters: action.payload.filters }));
//     }
//   } catch (err) {
//     yield put(fetchCatalogAsync.failure(err as Error));
//   }
// }

// const preloadProductsQueue = new Set();

// function* catalogPreloadProductsSaga(action: ReturnType<typeof catalogPreloadProductsAsync.request>): Generator {
//   try {
//     const isFetching: any = yield select(getCatalogFetchingProducts);
//     if (isFetching) {
//       // add to queue
//       action.payload.productsIds.forEach((id: string) => preloadProductsQueue.add(id));
//       return;
//     }

//     yield put(startFetchingProducts());
//     const response: any = yield call(() => httpClient.getProducts(action.payload.productsIds.join(',')));
//     if (response && (response.status === 401 || response.status === 403 || response.status === 503)) {
//       window.localStorage.setItem('redirect_after_login', window.location.pathname);
//       yield put({ type: '@@auth/LOGOUT' });
//       window.location.href = '/login';
//     } else {
//       const docs = (response as IAsyncDataWrapper<ICatalogData>).data.docs || [];
//       (response as IAsyncDataWrapper<ICatalogData>).data.docs = docs.map(doc => {
//         preloadProductsQueue.delete(doc.id);
//         return new Product(doc);
//       });
//       yield put(catalogPreloadProductsAsync.success({ data: response.data.docs }));
//       if (action.payload.callback) {
//         action.payload.callback();
//       }
//       // check queue
//       if (preloadProductsQueue.size) {
//         yield put(catalogPreloadProductsAsync.request({ productsIds: Array.from(preloadProductsQueue) }));
//       }
//     }
//   } catch (err) {
//     yield put(catalogPreloadProductsAsync.failure(err as Error));
//   }
// }

function* selectProductSaga(action: ReturnType<typeof selectProductAsync.request>): Generator {
  try {
    const isSelectedProductLoading: any = yield select(getSelectedProductLoadingState);
    if (isSelectedProductLoading) {
      return;
    }

    yield put(startFetchingProduct());
    const stateFilters: any = yield select(getCatalogFilters);
    const supplierValue = stateFilters?.supplier || '';
    yield put(setSelectedProduct(new DetailedProduct(action.payload)));

    const response: any = yield call(() => httpClient.get(action.payload.id, action.payload.supplier || supplierValue));
    yield put(selectProductAsync.success(new DetailedProduct(response)));
    yield GTM.trackOpenProductDetail(response);
  } catch (err) {
    yield put(selectProductAsync.failure(err as Error));
  }
}

function* intervalGetProductDetailsSaga(action: ReturnType<typeof intervalProductDetails>): Generator {
  try {
    const selectedProductData: any = yield select(getSelectedProductData);
    if (selectedProductData) {
      const stateFilters: any = yield select(getCatalogFilters);
      const supplierValue = stateFilters?.supplier || '';
      const response: any = yield call(() =>
        httpClient.get(selectedProductData.id, selectedProductData.supplier || supplierValue)
      );
      yield put(selectProductAsync.success(new DetailedProduct(response)));
    }
  } catch (error) {
    // * silent error handling because we don't want to show an error to the user in a random time
    console.error(error);
  }
}

function* unselectProductSaga(action: ReturnType<typeof unselectProduct>): Generator {
  const currentUrl = window.location.href;

  // if catalog
  if (currentUrl.indexOf(`${baseUrl}/catalog`) !== -1) {
    const filters: any = yield select(getCatalogFilters);
    const filterMap = filtersToString(filters.fast);
    let str = filterJsonToString(filterMap);

    const {
      inStock,
      preOrder,
      discount,
      supplier: filterSupplier,
      specialOffers,
      transitOffers,
      specialTransitOffers,
      deliveryRegion,
      eventPrice
    } = filters;
    str = `${inStock ? 'inStock=1;' : ''}${preOrder ? 'preOrder=1;' : ''}${discount ? 'discount=1;' : ''}${
      filterSupplier ? 'supplier=' + filterSupplier + ';' : ''
    }${deliveryRegion ? 'deliveryRegion=' + deliveryRegion + ';' : ''}${specialOffers ? 'specialOffers=1;' : ''}${
      transitOffers ? 'transitOffers=1;' : ''
    }${specialTransitOffers ? 'specialTransitOffers=1;' : ''}${
      eventPrice ? 'eventPrice=' + eventPrice + ';' : ''
    }${str}`;

    const path = generatePath(`${baseUrl}/catalog/:productType/:filterUrl?`, {
      productType: filters.productType,
      filterUrl: str || undefined
    });

    yield put(push(path));
  }
}

// function* hotOfferProductSaga(action: ReturnType<typeof hotOfferProductAsync.request>): Generator {
//   try {
//     // const response: any = yield call(() => httpClient.getHotOffer());
//
//     yield put(hotOfferProductAsync.success(new DetailedProduct(fakeHotOfferProduct)));
//   } catch (err) {
//     yield put(hotOfferProductAsync.failure(err));
//   }
// }

function* searchSaga(action: ReturnType<typeof searchAsync.request>): Generator {
  try {
    const stateFilters: any = yield select(getCatalogFilters);
    const supplierValue = stateFilters?.supplier || '';

    const response: any = yield call(() =>
      httpClient.getSearchResult({
        searchString: action.payload.searchString,
        productTypeCode: action.payload.productTypeCode,
        supplier: supplierValue
      })
    );

    yield put(searchAsync.success(response));
  } catch (err) {
    yield put(searchAsync.failure(err as Error));
  }
}

function* watchProductSaga(action: ReturnType<typeof watchProductAsync.request>): Generator {
  try {
    yield call(() => httpClient.setWatch(action.payload.productId, action.payload.watch));

    yield put(watchProductAsync.success({ productId: action.payload.productId, watch: action.payload.watch }));
  } catch (err) {
    yield put(watchProductAsync.failure(err as Error));
  }
}

function* catalogFilterAndSortSaga(action: ReturnType<typeof filterAndSortCatalog>): Generator {
  try {
    const loading: any = yield select(getCatalogLoadingState);
    if (loading) {
      return;
    }
    const listData: any = yield select(getCatalogData);
    const layout: any = yield select(getCatalogLayout);

    const catalogFilters: any = yield select(getCatalogFilters);
    const catalogSort = yield select(getCatalogSort);
    const locale = yield select((state: IApplicationState) => state.locale);
    // TODO remove action.payload
    const preparedFilters: IFilterAndSortProps = { ...catalogFilters, sort: catalogSort, locale, ...action.payload };

    const preparedData: any = yield call(() =>
      runPrepare.prepareCatalogDocs({
        list: listData.docs,
        filters: preparedFilters,
        columns: layout.columns
      })
    );
    const { alphabet, docsShown, sortedProductRows, postFilteredFilterOptionsMap } = preparedData;

    // * if selected product is not in the filtered products -> unselect product
    let filteredSelectedProduct: any;
    const selectedProd: any = yield select(getSelectedProductData);
    const sorted = (sortedProductRows || []).map((arr: any[]) =>
      arr.map((i: any) => {
        if (selectedProd) {
          filteredSelectedProduct = i.id === selectedProd.id ? i : filteredSelectedProduct;
        }
        return new Product(i);
      })
    );

    if (window.localStorage.getItem('layoutType') === 'table' && sorted.length) {
      sorted.unshift([]);
    }

    if (!filteredSelectedProduct && selectedProd) {
      yield put(unselectProduct());
    }

    const { inStock, preOrder, fast, supplier, discount, sort } = preparedFilters;
    yield put(
      setFilteredCatalog({
        alphabet,
        docsShown,
        sortedProductRows: sorted,
        inStock,
        preOrder,
        fast,
        supplier,
        discount,
        sort
      })
    );
    yield put(catalogSetPostFilteredFilters(postFilteredFilterOptionsMap));
  } catch (error) {
    runPrepare.terminate();
    console.log(error);
  }
}

// function* setFiltersSaga(action: ReturnType<typeof catalogSetFilter>): Generator {
// yield console.log('subscribe to setFilters --->', action.payload);
// TODO make a request to get filter options and products
// make it only if filters are changed (not fast)
// TODO if fast is changed filter and sort catalog products (worker)
// TODO filter and sort catalog products
// yield put(filterAndSortCatalog(action.payload));
// }

function* successFetchCatalogData(action: ReturnType<typeof fetchCatalogAsync.request>) {
  yield put(filterAndSortCatalog({}));
}

export function* catalogRequestSaga() {
  // uncomment for catalog_v2
  // yield takeLatest(fetchCatalogAsync.request, catalogSaga_v2);
  // yield takeEvery(catalogPreloadProductsAsync.request, catalogPreloadProductsSaga);
  yield takeLatest(fetchCatalogAsync.request, catalogSaga);
  yield takeLatest(fetchCatalogAsync.success, successFetchCatalogData);
  yield takeEvery(selectProductAsync.request, selectProductSaga);
  yield takeEvery(intervalProductDetails, intervalGetProductDetailsSaga);
  yield takeEvery(unselectProduct, unselectProductSaga);
  // yield takeEvery(hotOfferProductAsync.request, hotOfferProductSaga);
  yield takeEvery(searchAsync.request, searchSaga);
  yield takeEvery(watchProductAsync.request, watchProductSaga);
  yield takeEvery(filterAndSortCatalog, catalogFilterAndSortSaga);
  yield takeEvery(fetchCatalogAndFilters, fetchCatalogAndFiltersSaga);
  // yield takeEvery(catalogSetFilter, setFiltersSaga);
}

export default createReducer(catalogInitialState)
  .handleAction(CATALOG_FILTER_RESET, (state: ICatalogState) =>
    dataWrapper({
      ...state.data,
      filters: {
        ...state.data.filters,
        fast: []
      }
    })
  )
  .handleAction(
    CATALOG_SET_FILTER,
    (state: ICatalogState, action: PayloadAction<typeof CATALOG_SET_FILTER, IFilterAndSortProps>) => ({
      ...state,
      data: {
        ...state.data,
        filters: {
          ...state.data.filters,
          ...action.payload
        }
      }
    })
  )
  .handleAction(
    SET_POST_FILTERED_FILTERS,
    (state: ICatalogState, action: PayloadAction<typeof SET_POST_FILTERED_FILTERS, any>) =>
      dataWrapper(
        {
          ...state.data,
          postFilteredFilters: action.payload
        },
        null,
        false,
        true
      )
  )
  .handleAction(
    CATALOG_SET_FILTERED,
    (state: ICatalogState, action: PayloadAction<typeof CATALOG_SET_FILTERED, any>) => {
      if (state.loading) {
        return state;
      }

      const { docsShown, sortedProductRows, alphabet, inStock, preOrder, fast, supplier, discount, sort } =
        action.payload;

      return dataWrapper(
        {
          ...state.data,
          filters: {
            ...state.data.filters,
            inStock,
            preOrder,
            fast: fast || [],
            supplier,
            discount
          },
          sort,
          list: {
            ...state.data.list,
            docsShown,
            rows: sortedProductRows,
            alphabet
          }
        },
        null,
        false,
        true
      );
    }
  )
  .handleAction(
    CATALOG_UPDATE_HIGHLIGHTED_ALPHABET,
    (
      state: ICatalogState,
      action: PayloadAction<typeof CATALOG_UPDATE_HIGHLIGHTED_ALPHABET, IProductAlphaLetter[]>
    ) => ({
      ...state,
      data: {
        ...state.data,
        highlightedAlphabet: action.payload
      }
    })
  )
  .handleAction(
    CATALOG_TOGGLE_DETAILS,
    (state: ICatalogState, action: PayloadAction<typeof CATALOG_TOGGLE_DETAILS, boolean>) => ({
      ...state,
      data: {
        ...state.data,
        layout: {
          ...state.data.layout,
          rightOpened: action.payload
        }
      }
    })
  )
  .handleAction(
    CATALOG_TOGGLE_FILTERS,
    (state: ICatalogState, action: PayloadAction<typeof CATALOG_TOGGLE_FILTERS, boolean>) => ({
      ...state,
      data: {
        ...state.data,
        layout: {
          ...state.data.layout,
          leftOpened: action.payload
        }
      }
    })
  )
  .handleAction(
    CATALOG_SET_COLUMNS,
    (state: ICatalogState, action: PayloadAction<typeof CATALOG_SET_COLUMNS, number>) =>
      state.loading && action.payload !== state.data.layout.columns
        ? state
        : dataWrapper(
            {
              ...state.data,
              layout: {
                ...state.data.layout,
                columns: action.payload
              }
            },
            null,
            false,
            false
          )
  )
  .handleAction(CATALOG_UNSELECT_PRODUCT, (state: ICatalogState) =>
    dataWrapper(
      {
        ...state.data,
        selectedProduct: productDataWrapper(null, null, false, false)
      },
      null,
      false,
      true
    )
  )
  .handleAction(
    START_FETCHING_PRODUCT,
    (state: ICatalogState, action: PayloadAction<typeof START_FETCHING_PRODUCT, Product>) =>
      dataWrapper(
        {
          ...state.data,
          selectedProduct: {
            ...state.data.selectedProduct,
            loading: true,
            error: null
          }
        },
        null,
        false,
        true
      )
  )
  .handleAction(
    SET_SELECTED_PRODUCT,
    (state: ICatalogState, action: PayloadAction<typeof SET_SELECTED_PRODUCT, DetailedProduct>) =>
      dataWrapper(
        {
          ...state.data,
          selectedProduct: {
            ...state.data.selectedProduct,
            data: action.payload
          }
        },
        null,
        false,
        true
      )
  )
  .handleAction(
    selectProductAsync.success,
    (state: ICatalogState, action: PayloadAction<typeof CATALOG_SELECT_PRODUCT_SUCCESS, DetailedProduct>) => {
      const responseProduct = action.payload;
      return dataWrapper(
        {
          ...state.data,
          selectedProduct: productDataWrapper(responseProduct)
        },
        null,
        false,
        true
      );
    }
  )
  .handleAction(
    selectProductAsync.failure,
    (state: ICatalogState, action: PayloadAction<typeof CATALOG_SELECT_PRODUCT_FAILURE, Product>) =>
      dataWrapper(
        {
          ...state.data,
          selectedProduct: {
            ...state.data.selectedProduct,
            loading: false,
            error: action.payload
          }
        },
        null,
        false,
        true
      )
  )
  .handleAction(
    hotOfferProductAsync.request,
    (state: ICatalogState, action: PayloadAction<typeof CATALOG_HOT_OFFER_REQUEST, Product>) => {
      return dataWrapper(
        {
          ...state.data,
          hotOfferProduct: productDataWrapper(null, null, true)
        },
        null,
        false,
        true
      );
    }
  )
  .handleAction(
    hotOfferProductAsync.success,
    (state: ICatalogState, action: PayloadAction<typeof CATALOG_HOT_OFFER_SUCCESS, DetailedProduct>) => {
      const responseProduct = action.payload;
      return dataWrapper(
        {
          ...state.data,
          hotOfferProduct: productDataWrapper(responseProduct, null, false)
        },
        null,
        false,
        true
      );
    }
  )
  .handleAction(
    searchAsync.request,
    (state: ICatalogState, action: PayloadAction<typeof CATALOG_SEARCH_REQUEST, string>) => {
      return dataWrapper(
        {
          ...state.data,
          searchData: productDataWrapper(state.data.searchData.data, null, true)
        },
        null,
        false,
        true
      );
    }
  )
  .handleAction(
    searchAsync.success,
    (state: ICatalogState, action: PayloadAction<typeof CATALOG_SEARCH_SUCCESS, ISearchData>) => {
      const response = action.payload;
      return dataWrapper(
        {
          ...state.data,
          searchData: productDataWrapper(response, null, false, true)
        },
        null,
        false,
        true
      );
    }
  )
  .handleAction(resetCatalogList, (state: ICatalogState) =>
    dataWrapper(
      {
        ...state.data,
        list: { ...catalogInitialState.data.list }
      },
      null,
      false,
      false
    )
  )
  .handleAction(
    fetchCatalogAsync.success,
    (
      state: ICatalogState,
      action: PayloadAction<typeof CATALOG_REQUEST, { data: ICatalogData; filters: Partial<IFiltersData> }>
    ) =>
      dataWrapper(
        {
          ...state.data,
          list: {
            ...state.data.list,
            ...action.payload.data,
            docs: action.payload.data.docs,
            rows: chunk(action.payload.data.docs, state.data.layout.columns),
            loaded: true
          }
        },
        null,
        false,
        false
      )
  )
  .handleAction(
    fetchCatalogAsync.request,
    (state: IAsyncDataWrapper<ICatalog>, action: PayloadAction<typeof CATALOG_REQUEST, any>) =>
      dataWrapper(
        {
          ...state.data,
          list: {
            ...state.data.list,
            loaded: false
          },
          filters: {
            ...state.data.filters,
            ...(action.payload.filters ? action.payload.filters : {}),
            fast: state.data.filters.fast
          }
        },
        null,
        true,
        false
      )
  )
  .handleAction(fetchCatalogAsync.failure, (state: IAsyncDataWrapper<ICatalog>) =>
    dataWrapper(state.data, new Error('Failed to load catalog'))
  )
  .handleAction(
    START_FETCHING_PRODUCTS,
    (state: IAsyncDataWrapper<ICatalog>, action: PayloadAction<typeof CATALOG_PRELOAD_PRODUCTS_REQUEST, any>) => ({
      ...state,
      data: {
        ...state.data,
        fetchingProducts: true
      }
    })
  )
  .handleAction(
    catalogPreloadProductsAsync.success,
    (state: IAsyncDataWrapper<ICatalog>, action: PayloadAction<typeof CATALOG_PRELOAD_PRODUCTS_SUCCESS, any>) => {
      // const updatedRows = [...state.data.list.rows];
      // for (let index = action.payload.start; index <= action.payload.end; index++) {
      //   const rows = (updatedRows[index] || []).map(r => action.payload.data.find((p: any) => p.id === r.id) || r);
      //   updatedRows[index] = rows;
      // }

      const newProductsMap = new Map(action.payload.data.reduce((acc: any, p: Product) => acc.concat([[p.id, p]]), []));
      state.data.list.productsMap.forEach((value: any, key: string) => {
        newProductsMap.set(key, value);
      });

      return {
        ...state,
        data: {
          ...state.data,
          fetchingProducts: false,
          list: {
            ...state.data.list,
            // rows: updatedRows,
            productsMap: newProductsMap
          }
        }
      };
    }
  )
  .handleAction(
    catalogPreloadProductsAsync.failure,
    (state: IAsyncDataWrapper<ICatalog>, action: PayloadAction<typeof CATALOG_PRELOAD_PRODUCTS_FAILURE, any>) => ({
      ...state,
      data: {
        ...state.data,
        fetchingProducts: false
      },
      error: action.payload
    })
  );
