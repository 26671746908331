import { Warehouse, PriceType } from 'models';
import { IDiscountCondition } from './discount-conditions';

export type OfferPrices = Record<number, string>;

export interface IOfferDiscountDetails {
  discount: number;
  discountDetails: IDiscountCondition;
}

export const getPriceByQty = (qty: number, prices: OfferPrices): number => {
  const pricesPoints = Object.keys(prices).map(point => parseInt(point, 10));
  let activePoint = pricesPoints[0];
  pricesPoints.forEach(point => {
    if (qty >= point) {
      activePoint = point;
    }
  });
  return +prices[activePoint];
};

export class Offer {
  public id: string;
  public productId: string;
  public warehouse: Warehouse;
  public inStock: number;
  public price: OfferPrices;
  public preOrder: boolean;
  public extraPrice: boolean;
  public priceType: PriceType;
  public discount: number;
  public discountDetails: IOfferDiscountDetails[];
  public special: boolean;
  public specialTransit: boolean;
  public transit: boolean;
  public endDate: string;
  public offerType: string;
  public priceDate: string;
  public groupLabel: string;
  public startDate?: string;

  constructor(data: any) {
    this.id = data.id;
    this.productId = data.productId;
    this.warehouse = data.warehouse as Warehouse;
    this.price = data.price;
    this.inStock = data.inStock;
    this.preOrder = data.preOrder;
    this.extraPrice = data.extraPrice;
    this.priceType = data.priceType;
    this.discount = data.discount ? data.discount / 100 : 0;
    this.discountDetails = data.discountDetails;
    this.special = data.special;
    this.transit = data.transit;
    this.endDate = data.endDate;
    this.startDate = data.startDate;
    this.specialTransit = data.specialTransit;
    this.offerType = data.offerType;
    this.priceDate = data.priceDate;
    this.groupLabel = data.groupLabel;
  }
}
