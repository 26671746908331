import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { isMobileDevice } from 'environment';

import { GTM } from 'controllers';
import { getPriceByQty, Offer } from 'models';
import { getTransitModalChecking } from 'store/cart/selectors';
import { getSelectedProductData } from 'store/catalog/selectors';
import { getMinMaxPreOrderDates } from 'utils/time';

import { SplitedPrice } from '../../prices';
import DeliveryCardInner from './DeliveryCardInner';

import classes from './DeliveryCardInnerContainer.module.scss';

interface IDeliveryCardInnerContainerProps {
  offer: Offer;
  onPurchase: (offer: Offer, qty: number) => void;
  setQtyStateProp: (value: number) => void;
}

const DeliveryCardInnerContainer: FC<IDeliveryCardInnerContainerProps> = ({ offer, onPurchase, setQtyStateProp }) => {
  const checkingTransit = useSelector(getTransitModalChecking);
  const selectedProduct = useSelector(getSelectedProductData);

  const [qtyState, setQtyState] = useState((selectedProduct && selectedProduct.inPackageCount) || 1);

  const {
    preOrder: isPreOrder,
    special: specialOffer,
    transit: transitOffer,
    endDate: transitDeliveryDate,
    price,
    inStock,
    discount = 0,
    extraPrice
  } = offer;

  const stock = isPreOrder || extraPrice ? Infinity : inStock;
  const handlePurchase = () => {
    GTM.trackBuyButton(qtyState, getPriceByQty(qtyState, price));

    if (onPurchase) {
      onPurchase(offer, qtyState);
    }
  };
  const inPackageCount = selectedProduct?.inPackageCount || 1;
  const min = inPackageCount;
  let max: number | undefined;
  if (selectedProduct?.inStock) {
    max = min;
  }

  const hasPreOrders = selectedProduct?.offers && selectedProduct?.offers.some((item) => item.preOrder);
  const isMobile = Boolean(isMobileDevice(window.navigator));

  const withDiscount = Boolean(discount);
  const activePrice = getPriceByQty(qtyState, price);
  const finalPrice = withDiscount ? Math.round(activePrice * (1 - discount) * 100) / 100 : activePrice;

  const deliveryOptions = getMinMaxPreOrderDates([selectedProduct], isPreOrder, transitDeliveryDate, transitOffer);

  const handleQtyState = (value: number) => {
    setQtyState(value);
    setQtyStateProp(value);
  };

  const specialOfferTotal = () => {
    const total = qtyState * getPriceByQty(qtyState, price);

    return (
      <div className={clsx(classes.cardFooterTotal)}>
        <div className={classes.totalPrice}>
          <SplitedPrice value={total} bold={true} />
        </div>
      </div>
    );
  };

  const isDisabled = () => {
    if (specialOffer) {
      const minQty = Object.keys(price).map(Number).sort(Number)[0];
      return qtyState < minQty;
    }
    if (transitOffer) {
      return checkingTransit;
    }
    return false;
  };

  const renderCalculation = () => {
    const total = (qtyState * getPriceByQty(qtyState, price) * ((1 - discount) * 100)) / 100;

    return (
      <div className={classes.totalPrice}>
        <SplitedPrice
          value={total}
          bold={true}
          prefix={isPreOrder || extraPrice ? <span className={classes.boldText}>~</span> : null}
        />
      </div>
    );
  };

  const renderTotal = () => <>{renderCalculation()}</>;

  const renderConditionalTotal = () => {
    const total = (qtyState * getPriceByQty(qtyState, price) * ((1 - discount) * 100)) / 100;
    const content = renderTotal();

    return <div className={clsx(classes.cardFooterTotal)}>{isPreOrder && extraPrice && !total ? null : content}</div>;
  };

  return (
    <DeliveryCardInner
      offer={offer}
      inPackageCount={inPackageCount}
      qtyState={qtyState}
      isMobile={isMobile}
      withDiscount={withDiscount}
      activePrice={activePrice}
      finalPrice={finalPrice}
      deliveryOptions={deliveryOptions}
      stock={stock}
      min={min}
      max={max}
      setQtyState={handleQtyState}
      hasPreOrders={hasPreOrders}
      handlePurchase={handlePurchase}
      isDisabled={isDisabled}
      specialOfferTotal={specialOfferTotal}
      renderConditionalTotal={renderConditionalTotal}
    />
  );
};

export default DeliveryCardInnerContainer;
