import { FC, useState } from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import CartGroupExpiredCardLabel from 'components/cards/cart/CartGroupExpiredCardLabel';
import { ExpandPanel } from 'components/shared';
import { TextCaption } from 'components/shared/text';
import { Cart } from 'models';
import { setActiveItem, toggleExpiredCart } from 'store/cart/actions';
import { getCartActiveItem, getExpiredCartLayoutExpanded } from 'store/cart/selectors';
import { IApplicationState } from 'store/reducers';
import messages from 'translations/cart/common';
import messagesExpired from 'translations/cart/expired';

import CartLeftSideBlockHeader from './CartLeftSideBlockHeader';
import CartLeftSideItem from './СartLeftSideItem';

import classes from './CartLeftSide.module.scss';

interface IProps {
  anchor?: string;
  anchorOffset?: number;
  cart: Cart;
  expiredCart: Cart;
  displayedGroup?: string;
}

interface IStateProps {
  locale: any;
  expiredCartExpanded: boolean;
}

interface IDispatchProps {
  setActiveCartItem: typeof setActiveItem;
  toggleExpiredExpanded: typeof toggleExpiredCart;
}

type CartLeftSideProps = IProps & IStateProps & IDispatchProps;

const CartLeftSide: FC<CartLeftSideProps> = ({
  anchor,
  anchorOffset = 0,
  cart,
  expiredCart,
  expiredCartExpanded,
  setActiveCartItem,
  toggleExpiredExpanded,
  displayedGroup
}) => {
  const [activeGroup, setActiveGroup] = useState('');
  if (displayedGroup && activeGroup !== displayedGroup) {
    setActiveGroup(displayedGroup);
  }

  const renderTabs = (groupKeys: string[], isExpired = false, preOrder = false) => {
    const renderGroup = isExpired ? expiredCart.groups || {} : cart.groups || {};
    return (
      <div className={classes.tab}>
        {groupKeys.map((groupKey: string, index: number) => (
          <div className={classes.tabContent} key={`${anchor}-left-side-${groupKey}`}>
            <CartLeftSideItem
              index={index}
              groupKey={groupKey}
              group={renderGroup[groupKey]}
              anchor={`${anchor}`}
              anchorOffset={isExpired ? anchorOffset : anchorOffset - 80}
              preOrder={preOrder}
              activeGroup={activeGroup}
              setActiveGroup={setActiveGroup}
            />
          </div>
        ))}
      </div>
    );
  };

  const hasRegularOrders = cart.regularGroups.length > 0;
  const hasPreOrders = cart.preOrderGroups.length > 0;
  const hasInTransitOrders = cart.inTransitGroups.length > 0;
  const hasExtraPriceOrders = cart.extraPriceGroups.length > 0;

  const orders = [hasRegularOrders, hasPreOrders, hasExtraPriceOrders, hasInTransitOrders];

  const shouldShowOrderLabel = orders?.length > 1;

  return (
    <>
      {expiredCart && expiredCart.items.length > 0 && (
        <>
          <CartLeftSideBlockHeader title={messagesExpired.expiredCartShort} />
          <ExpandPanel
            leftIcon
            expanded={expiredCartExpanded}
            onChange={() => toggleExpiredExpanded()}
            label={<CartGroupExpiredCardLabel isExpanded={expiredCartExpanded} />}
            className={classes.expiredItems}
          >
            {renderTabs(Object.keys(expiredCart.groups), true)}
          </ExpandPanel>
        </>
      )}
      {(hasRegularOrders || hasPreOrders || hasInTransitOrders || hasExtraPriceOrders) && (
        <>
          {shouldShowOrderLabel ? (
            <CartLeftSideBlockHeader title={messages.cart} />
          ) : hasRegularOrders ? (
            <CartLeftSideBlockHeader title={messages.orders} />
          ) : hasInTransitOrders ? (
            <CartLeftSideBlockHeader title={messages.transitOrders} />
          ) : hasExtraPriceOrders ? (
            <CartLeftSideBlockHeader title={cart.extraPriceLabel} />
          ) : (
            <CartLeftSideBlockHeader title={messages.preOrders} />
          )}
          {hasRegularOrders && (
            <>
              {shouldShowOrderLabel && (
                <TextCaption className={classes.cartLeftSideSubheader}>{messages.orders.defaultMessage}</TextCaption>
              )}
              {renderTabs(cart.regularGroups)}
            </>
          )}
          {hasInTransitOrders && (
            <>
              {shouldShowOrderLabel ? (
                <TextCaption className={classes.cartLeftSideSubheader}>
                  {messages.transitOrders.defaultMessage}
                </TextCaption>
              ) : null}
              {renderTabs(cart.inTransitGroups, false, true)}
            </>
          )}
          {hasExtraPriceOrders && (
            <>
              {shouldShowOrderLabel ? (
                <TextCaption className={classes.cartLeftSideSubheader}>
                  {cart.extraPriceLabel.defaultMessage}
                </TextCaption>
              ) : null}
              {renderTabs(cart.extraPriceGroups, false, true)}
            </>
          )}
          {hasPreOrders && (
            <>
              {shouldShowOrderLabel ? (
                <TextCaption className={classes.cartLeftSideSubheader}>{messages.preOrders.defaultMessage}</TextCaption>
              ) : null}
              {renderTabs(cart.preOrderGroups, false, true)}
            </>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, {}, IApplicationState> = (state: IApplicationState) => ({
  locale: state.locale,
  activeCartItem: getCartActiveItem(state),
  expiredCartExpanded: getExpiredCartLayoutExpanded(state)
});

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, {}> = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      setActiveCartItem: setActiveItem,
      toggleExpiredExpanded: toggleExpiredCart
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(CartLeftSide);
