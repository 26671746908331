import { HttpClient } from 'utils/http';

import { ICatalogRequest, ISearchQuery } from './actions';

export class CatalogRepository<T> {
  private client = new HttpClient(`${process.env.REACT_APP_API_ENDPOINT}/catalog`);

  public async getAll(request: ICatalogRequest) {
    this.client.setAuthHeader();
    const params: any = Object.keys(request).reduce(
      (acc, key) => (request[key] ? { ...acc, [key]: request[key] } : acc),
      {}
    );

    const { supplier, fast, ...filters } = params;

    // ? TODO why we need to pass "filters" filed ?
    return await this.client.get<T>('/', { params: { ...params, filters } });
  }

  public async getAllPreviews(request: ICatalogRequest) {
    this.client.setAuthHeader();
    const params: any = Object.keys(request).reduce(
      (acc, key) => (request[key] ? { ...acc, [key]: request[key] } : acc),
      {}
    );

    const { supplier, fast, ...filters } = params;

    // ? TODO why we need to pass "filters" filed ?
    return await this.client.get<T>('/', { params: { ...params, filters, preview: true } });
  }

  public async getProducts(productRowsList: any) {
    this.client.setAuthHeader();

    return await this.client.get<T>(`/?products=${productRowsList}`);
  }

  public async get(productId: string, supplier?: string) {
    return (await this.client.get(`/product/${productId}${supplier ? '?supplier=' + supplier : ''}`)).data;
  }

  public async setWatch(productId: string, watch: boolean = false) {
    return (
      watch
        ? await this.client.post(`/product/watch/${productId}`, {})
        : await this.client.delete(`/product/watch/${productId}`)
    ).data;
  }

  public async getSearchResult({ searchString, productTypeCode, supplier }: ISearchQuery) {
    return (
      await this.client.get(
        `/search?s=${searchString}&productTypeCode=${productTypeCode}${supplier ? '&supplier=' + supplier : ''}`
      )
    ).data;
  }

  public async getHotOffer() {
    return (await this.client.get(`/product/hot-offer`)).data;
  }

  public async getMainTopSlider() {
    return (await this.client.get(`/main/top-slider`)).data;
  }

  public async getMainContacts() {
    return (await this.client.get(`/contacts`)).data;
  }
}
