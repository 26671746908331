import { FC } from 'react';

import { IPhoneContact } from 'shared/constants';

import Icon from './Icon';

import classes from './PhonesList.module.scss';

interface IProps {
  phones?: IPhoneContact[];
}

export const PhonesListComponent: FC<IProps> = ({ phones }) => (
  <div className={classes.container}>
    <Icon type="phoneCall" size={16} opacity={1} />
    <div className={classes.content}>
      {phones
        ? phones.map((phoneItem, index) => (
            <div key={index} className={classes.phoneItem}>
              <span>{phoneItem.additionalText}</span>
              <a href={`tel:${phoneItem.phone}`} className={classes.phone}>
                {phoneItem.phone}
              </a>
            </div>
          ))
        : null}
    </div>
  </div>
);
