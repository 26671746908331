import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { Column } from 'material-table';
import moment from 'moment';

import { SplitedPrice } from 'components/prices';
import { Link1 } from 'components/shared/text';
import { isReturnOrRefundTransaction, ITransaction, Transaction, TransactionOperationType } from 'models/transaction';
// import { EmptyCellDash } from 'components/shared/table/EmptyCellDash';
import messages from 'translations/account/finance';

import { PAYMENT_TYPES_LABELS } from '../../../components/shared/modals/payment/PaymentModal';

import classes from './TableColumns.module.scss';


// interface ICellProps {
//   record: ITransaction;
// }

// enum CurrencyTypes {
//   UAH = '₴ ',
//   USD = '$ ',
//   EUR = '€ '
// }

const operationLabels = {
  replenishment: 'Поповнення',
  purchaseOrder: 'Оформлення замовлення',
  refund: 'Повернення коштів',
  refundPayments: 'Повернення коштів',
  defaultValueDebit: 'Поповнення балансу',
  defaultValueCredit: 'Списання з балансу',
  defaultValue: 'Операція з балансом'
};

function getOperationType(operationType: string, onModal: string) {
  switch (operationType) {
    case 'debit':
      switch (onModal) {
        case 'Payment':
          return 'replenishment';
        case 'Order || Return':
          return 'refund';
        default:
          return 'defaultValueDebit';
      }

    case 'credit':
      switch (onModal) {
        case 'Order':
          return 'purchaseOrder';
        case 'Payment':
          return 'refundPayments';
        default:
          return 'defaultValueCredit';
      }
  }

  return 'defaultValue';
}

function getDebitText(record: ITransaction): string {
  const paymentId: string = (record.operation && record.operation.on && record.operation.on.paymentId) || '';
  let postFix = '';
  if (record.operation && record.operation.on && record.operation.on.type) {
    switch (record.operation.on.type) {
      case 'liqpay':
        postFix = 'через liqpay';
        break;
      case 'cash':
        postFix = 'готівкою';
        break;
      default:
        postFix = 'рахунку';
        break;
    }
  }
  return `Поповнення № ${paymentId} ${postFix}`;
}

function getDebitReturnText(record: ITransaction, link = true): React.ReactElement | string {
  const orderId: string = (record.operation && record.operation.on && record.operation.on.orderId) || '';
  if (link) {
    return <Link1 to={`orders/${orderId}`}>Рекламація № {orderId}</Link1>;
  }
  return `Рекламація № ${orderId}`;
}

function getCreditText(record: ITransaction, link = true): React.ReactElement | string {
  const orderId = record.operation && record.operation.on ? record.operation.on.orderId : '';
  if (link) {
    return <Link1 to={`orders/${orderId}`}>Замовлення № {orderId}</Link1>;
  }
  return `Замовлення № ${orderId}`;
}

function getCreditReturnText(record: ITransaction): React.ReactElement | string {
  const paymentId: string = (record.operation && record.operation.on && record.operation.on.paymentId) || '';
  return `Повернення коштів № ${paymentId}`;
}

function nameSort(data1: Transaction, data2: Transaction) {
  return NameCell(data2, 'row', false).localeCompare(NameCell(data1, 'row', false));
}

function NameCell(record: Transaction, _: any, link?: boolean): any {
  const { operationType } = record;
  if (isReturnOrRefundTransaction(record)) {
    return operationType === 'debit' ? getDebitReturnText(record, link) : getCreditReturnText(record);
  } else {
    return operationType === 'debit' ? getDebitText(record) : getCreditText(record, link);
  }
}

function MobileNameCell(record: Transaction): ReactElement {
  const { operationType } = record;
  let content;
  if (isReturnOrRefundTransaction(record)) {
    content = operationType === 'debit' ? 'Рекламація' : 'Повернення коштів';
  } else {
    content = operationType === 'debit' ? 'Поповнення' : 'Замовлення';
  }
  return <span className={classes.field}>{content}</span>;
}

function MobileDetails(record: Transaction): ReactElement {
  const { operationType, operation } = record;
  let content;
  if (isReturnOrRefundTransaction(record)) {
    content = operationType === 'debit' ? 'Рекламація' : 'Повернення коштів';
  } else {
    content = operationType === 'debit' ? 'Поповнення' : 'Замовлення';
  }
  return (
    <div className={classes.wrapper}>
      <span className={classes.field}>{content}</span>
      <span className={classes.type}>
        {operation.onModel === 'Payment' ? PAYMENT_TYPES_LABELS[operation.on.type] : operation.on.orderType}
      </span>
      {operation.on.orderId ? (
        <Link1 to={`orders/${operation.on.orderId}`} className={classes.link}>
          {operation.on.orderId}
        </Link1>
      ) : null}
    </div>
  );
}

// const AmountCell = (rowData: Transaction): any => {
//   if (checkCurrencyEquality(rowData)) {
//     const currencyCode: string = rowData.operation && rowData.operation.on ? rowData.operation.on.currencyCode : '';
//     return (
//       <SplitedPrice
//         prefix={CurrencyTypes[currencyCode]}
//         value={rowData.amount}
//         fontSize="medium"
//         bold={false}
//         priceDisplayStyle={{ verticalAlign: 'text-bottom', lineHeight: '22px' }}
//         decimalStyles={{ lineHeight: '20px' }}
//         fontSizeDecimal="small"
//       />
//     );
//   }
//   return <EmptyCellDash />;
// };

// const CurrencyRateCell = (rowData: Transaction): any =>
//   checkCurrencyEquality(rowData) ? (
//     <SplitedPrice value={rowData.currencyRate} fontSize="medium" bold={false} fontSizeDecimal="small" />
//   ) : (
//     <EmptyCellDash />
//   );

const AmountBaseCell = (rowData: Transaction): ReactElement => {
  const isNegative = rowData.operationType === TransactionOperationType.credit;
  return (
    <SplitedPrice
      prefix={isNegative ? '- ' : '+ '}
      positive={!isNegative}
      value={rowData.amountBase}
      bold={false}
      className={clsx(classes.amountBase, { [classes.replenishment]: rowData.operationType === 'debit' })}
      negative={isNegative}
    />
  );
};

const BalanceCell = (rowData: Transaction): ReactElement => (
  <SplitedPrice negative={rowData.balance < 0} value={rowData.balance} bold={false} className={classes.balance} />
);

const OperationCell = (rowData: Transaction) => {
  const operationType = getOperationType(rowData.operationType, rowData.operation.onModel);
  return <span className={classes.value}>{operationLabels[operationType]}</span>;
};

const columns = (completedOnly: boolean): Array<Column<Transaction>> => [
  {
    title: messages.date.defaultMessage,
    field: 'createdAt',
    defaultSort: 'desc',
    render: (rowData) => <span className={classes.value}>{rowData.dateString}</span>
  },
  {
    title: messages.operation.defaultMessage,
    field: 'operationType',
    defaultSort: 'desc',
    render: OperationCell
  },
  {
    title: messages.type.defaultMessage,
    field: 'operation.on.orderType',
    customSort: nameSort,
    render: (rowData) => (
      <span className={classes.value}>
        {rowData.operation.onModel === 'Payment' ? PAYMENT_TYPES_LABELS[rowData.operation.on.type] : rowData.operation.on?.orderType}
      </span>
    )
  },
  {
    title: messages.filterOrder.defaultMessage,
    field: 'operation.on.orderType',
    customSort: nameSort,
    render: (rowData) => (
      <>
        {rowData.operation.on?.orderId ? (
          <Link1 to={`orders/${rowData.operation.on.orderId}`}>{rowData.operation.on.orderId}</Link1>
        ) : (
          '-'
        )}
      </>
    )
  },
  {
    title: messages.amountBase.defaultMessage,
    field: 'amountBase',
    type: 'numeric',
    render: AmountBaseCell
  },
  {
    title: completedOnly ? messages.factBalanceValue.defaultMessage : messages.totalBalanceValue.defaultMessage,
    field: 'balance',
    type: 'numeric',
    render: BalanceCell
  }
];

export const dashboardColumns: Array<Column<Transaction>> = [
  {
    title: messages.date.defaultMessage,
    field: 'date',
    render: (rowData) => <span className={classes.field}>{moment(rowData.operation.date).format('DD.MM.YY')}</span>
  },
  {
    title: messages.type.defaultMessage,
    field: 'details',
    render: MobileNameCell
  },
  {
    title: messages.amountBase.defaultMessage,
    field: 'amountBase',
    type: 'numeric',
    render: AmountBaseCell
  },
  {
    title: messages.tableBalance.defaultMessage,
    field: 'balance',
    type: 'numeric',
    render: BalanceCell
  }
];

export const columnsMobile: Array<Column<Transaction>> = [
  {
    title: messages.date.defaultMessage,
    field: 'date',
    render: (rowData) => <span className={classes.date}>{moment(rowData.operation.date).format('DD.MM.YY')}</span>
  },
  {
    title: messages.operation.defaultMessage,
    field: 'details',
    customSort: nameSort,
    render: MobileDetails
  },
  {
    title: `${messages.amountBase.defaultMessage} / ${messages.tableBalance.defaultMessage}`,
    field: 'balance',
    type: 'numeric',
    render: (rowData) => {
      const isNegative = rowData.operationType === TransactionOperationType.credit;
      return (
        <div className={classes.balanceContainer}>
          <SplitedPrice
            prefix={isNegative ? '- ' : '+ '}
            positive={!isNegative}
            value={rowData.amountBase}
            bold={false}
            className={clsx(classes.amountBase, { [classes.replenishment]: rowData.operationType === 'debit' })}
            negative={isNegative}
          />
          <SplitedPrice
            negative={rowData.balance < 0}
            value={rowData.balance}
            bold={false}
            className={classes.balance}
          />
        </div>
      );
    }
  }
];

export default columns;
