import { socialIconsDefault } from '../../../../shared/constants';
import Icon from '../../../shared/Icon';

import classes from './SocialLinks.module.scss';

const SocialLinks = () => {
  return (
    <div className={classes.wrapper}>
      {socialIconsDefault.map((item, index) => (
        <div className={classes.container} key={index}>
          <Icon type={item.icon} size={18} opacity={1} />
          <div>
            <a href={item.link} rel="noreferrer" className={classes.link} target="_blank">
              {item.title}
            </a>
            <p className={classes.additionalText}>{item.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SocialLinks;
