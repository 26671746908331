import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Tab, Tabs } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import CartBlockHeader from 'pages/cart/components/CartBlockHeader';
import { CART_LAYOUT_SET_TAB } from 'store/cart/actions';
import { getCart, getCartLoaded, getCartMobileTab } from 'store/cart/selectors';
import { getExpiredCart } from 'store/expired-cart/selectors';
import messages from 'translations/cart/common';
import messagesExpired from 'translations/cart/expired';
import { ITheme } from 'utils/styled';

import Breadcrumbs from '../breadcrumbs/Breadcrumbs';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    cartTabsContainer: {
      position: 'relative',
      top: 48,
      padding: '0 6px',
      background: 'white',

      '& .MuiTabs-flexContainer': {
        '@media (max-width: 850px)': {
          marginRight: -250
        }
      },

      [(theme as any).breakpoints.up('sm')]: {
        top: 0
      }
    },
    title: {
      margin: '8px 0 0 10px',
      fontSize: '24px',
      lineHeight: '29px'
    }
  })
);

const HeaderCartTabs: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cart = useSelector(getCart);
  const cartLoaded = useSelector(getCartLoaded);
  const expiredCart = useSelector(getExpiredCart);
  const cartTab = useSelector(getCartMobileTab);
  const setCartTab = (tab: number) => {
    if (cartTab === tab) {
      return;
    }
    return dispatch({ type: CART_LAYOUT_SET_TAB, payload: tab });
  };
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCartTab(newValue);
  };

  const a11yProps = (index: number) => ({
    id: `cart-tab-${index}`,
    'aria-controls': `cart-tab-${index}`
  });


  React.useEffect(() => {
    let defaultTab = 0;
    if (cartTab === -1 && cartLoaded) {
      if (cart && cart.regularGroups && cart.regularGroups.length > 0) {
        defaultTab = 0;
      } else if (cart && cart.inTransitGroups && cart.inTransitGroups.length > 0) {
        defaultTab = 1;
      } else if (cart && cart.extraPriceGroups && cart.extraPriceGroups.length > 0) {
        defaultTab = 2;
      } else if (cart && cart.preOrderGroups && cart.preOrderGroups.length > 0) {
        if (cart.extraPriceGroups.length) {
          defaultTab = 3;
        }
        defaultTab = 2;
      } else if (expiredCart && expiredCart.items.length > 0) {
        if (cart.extraPriceGroups.length) {
          defaultTab = 4;
        }
        defaultTab = 3;
      }
      if (defaultTab !== cartTab) {
        setCartTab(defaultTab);
      }
    }
    // eslint-disable-next-line
  }, [cart, cartLoaded, cartTab, setCartTab]);
  if (cartTab === -1) {
    return null;
  }
  return (
    <Grid container direction={'column'}>
      <Grid item xs container style={{ flexBasis: 'auto' }}>
        <Breadcrumbs
          // only for cart device page
          style={{ background: 'white' }}
          links={[
            {
              path: '/catalog/shear-flowers',
              label: 'Каталог'
            },
            {
              label: 'Кошик'
            }
          ]}
        />
      </Grid>
      <Grid item xs container className={classes.cartTabsContainer} style={{ flexBasis: 'auto' }}>
        <h3 className={classes.title}>Кошик</h3>
        <Tabs
          value={cartTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons={false}
          aria-label="cart-tabs"
        >
          <Tab
            disabled={cart.regularGroups.length === 0}
            label={
              <CartBlockHeader
                mobile
                preOrder={false}
                itemsNumber={cart.regularGroups.length}
                title={messages.ordersWithAmount}
              />
            }
            {...a11yProps(0)}
          />
          <Tab
            disabled={cart.inTransitGroups.length === 0}
            label={
              <CartBlockHeader
                mobile
                preOrder
                itemsNumber={cart.inTransitGroups.length}
                title={messages.inTransitWithAmount}
              />
            }
            {...a11yProps(1)}
          />
          {cart.extraPriceGroups.length ? (
            <Tab
              disabled={cart.extraPriceGroups.length === 0}
              label={
                <CartBlockHeader mobile preOrder itemsNumber={cart.extraPriceGroups.length} title={cart.extraPriceLabel} />
              }
            />
          ) : null}
          <Tab
            disabled={cart.preOrderGroups.length === 0}
            label={
              <CartBlockHeader
                mobile
                preOrder
                itemsNumber={cart.preOrderGroups.length}
                title={messages.preOrdersWithAmount}
              />
            }
            {...a11yProps(2)}
          />
          <Tab
            disabled={!(expiredCart && expiredCart.items && expiredCart.items.length > 0)}
            label={
              <CartBlockHeader
                mobile
                expired
                itemsNumber={expiredCart.items.length}
                title={messagesExpired.expiredCartShortWithNumber}
              />
            }
            {...a11yProps(3)}
          />
        </Tabs>
      </Grid>
    </Grid>
  );
};

export default HeaderCartTabs;
