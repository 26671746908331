import React from 'react';
import * as _ from 'lodash';
import { Collapse } from '@mui/material';

import { ExpandPanel } from 'components/shared';
import FlrCheckboxWithLabel from 'components/shared/form-elements/FlrCheckbox';
import { Link2 } from 'components/shared/text';
import { IFilterOptions, ProductAttributesDictionary } from 'models';
import { countriesMap } from 'shared/countriesMap';
import { ATTRIBUTE_COUNTRY_PATH } from 'shared/filters';
import { IFilterProp, IFilterProps, IFiltersData } from 'store/catalog/actions';
import { filtersMessages } from 'translations/catalog/filters';

import { FilterChangeHandler } from '../index';
import FilterLabel from './FilterLabel';
import { MAX_OPTIONS_SHOWN, sortByCheckedRank } from './options-tools';

function sortByName(a: any, b: any) {
  if (countriesMap[a.value] && countriesMap[b.value]) {
    return countriesMap[a.value].name.localeCompare(countriesMap[b.value].name);
  }
  return 0;
}

interface IProps {
  filters: IFiltersData;
  setFilters: (newFilters: IFilterProps) => void;
  label: string;
  classes: any;
  filterOptionsData: IFilterOptions;
  postFilteredFilterOptions: Map<any, any>;
}

const CountryOptions: React.FC<IProps> = ({
  label: labelSection,
  classes,
  filterOptionsData,
  postFilteredFilterOptions,
  setFilters,
  filters
}) => {
  const [allList, setAllList] = React.useState(false);
  // map filters on filterOptions
  const attributeOptions = _.find(filterOptionsData.attributes, { code: ProductAttributesDictionary.country });
  const attributeFilterValues: string[] =
    _.filter(filters.fast, { path: ATTRIBUTE_COUNTRY_PATH } as IFilterProp).map((filter) => filter.value) || [];

  if (!attributeOptions) {
    return null;
  }

  const filtersFast = filters.fast || [];

  const arraySelected = filtersFast.filter((item) => item.path === ATTRIBUTE_COUNTRY_PATH);
  const arraySelectedLength = (arraySelected && arraySelected.length) || 0;
  const arraySelectedLast = arraySelectedLength ? arraySelected[0] : null;

  const handleResetCountryFilters = (ev: React.SyntheticEvent | React.MouseEvent) => {
    if (arraySelectedLast) {
      ev.stopPropagation();
      setFilters(filtersFast.filter((item) => item.path !== ATTRIBUTE_COUNTRY_PATH));
    }
  };

  const handleCountryFilterChange: FilterChangeHandler<boolean> = (key: string, checked: boolean) => {
    const newFilterPropValue: IFilterProp = {
      rule: 'include',
      path: ATTRIBUTE_COUNTRY_PATH,
      value: key,
      label: countriesMap[key] ? countriesMap[key].name : key
    };
    if (!checked) {
      _.remove(filtersFast, newFilterPropValue);
    } else if (!_.includes(filtersFast, newFilterPropValue)) {
      newFilterPropValue.time = _.now();
      filtersFast.push(newFilterPropValue);
    }
    setFilters(filtersFast);
  };

  const allAttributeOptions = attributeOptions.options.map((o) => ({
    value: o,
    disabled: !postFilteredFilterOptions.get('country')?.has(o),
    rank: postFilteredFilterOptions.get('country')?.get(o),
    label: countriesMap[o] ? countriesMap[o].name : o,
    isChecked: attributeFilterValues.length > 0 && attributeFilterValues.includes(o)
  }));

  const uncollapsedOptionsNodes = allAttributeOptions
    .filter((o) => !o.disabled || o.isChecked)
    .sort(sortByCheckedRank)
    .filter((o, idx) => o.isChecked || idx < MAX_OPTIONS_SHOWN)
    .sort(sortByName)
    .map(({ isChecked, label, disabled, value }) => (
      <FlrCheckboxWithLabel
        key={value}
        checked={isChecked}
        label={label}
        onChange={(event: any, checked: boolean) => handleCountryFilterChange(value, checked)}
        disabled={disabled && !isChecked}
      />
    ));

  const allAttributeOptionsNodes = allAttributeOptions
    .sort(sortByName)
    .map(({ isChecked, label, disabled, value }) => (
      <FlrCheckboxWithLabel
        key={value}
        checked={isChecked}
        label={label}
        onChange={(event: any, checked: boolean) => handleCountryFilterChange(value, checked)}
        disabled={disabled && !isChecked}
      />
    ));

  const labelComp = (
    <FilterLabel
      classes={classes}
      label={labelSection}
      arraySelectedLast={arraySelectedLast}
      arraySelectedLength={arraySelectedLength}
      filterOptionsData={filterOptionsData}
      defaultCaption={filtersMessages.countryFilterAll.defaultMessage}
      handleReset={handleResetCountryFilters}
    />
  );
  const toggleAllList = () => setAllList((state) => !state);

  return (
    <ExpandPanel
      defaultExpanded={true}
      label={labelComp}
      className={classes.paddedExpandPanel}
      classNameSummary={classes.expansionSummary}
      classNameButton={classes.expansionButton}
    >
      <>{!allList ? uncollapsedOptionsNodes : null}</>

      <Collapse in={allList}>
        <div className={classes.expandedList}>
          <>{allAttributeOptionsNodes}</>
          <FlrCheckboxWithLabel
            checked={attributeFilterValues.length > 0 && attributeFilterValues.includes('')}
            label={'Інша'}
            key={'Other'}
            onChange={(event: any, checked: boolean) => handleCountryFilterChange('', checked)}
          />
        </div>
      </Collapse>

      {allAttributeOptionsNodes && !!allAttributeOptionsNodes.length && (
        <Link2 className={classes.collapseAllList} onClick={toggleAllList}>
          {allList ? filtersMessages.collapseIn.defaultMessage : filtersMessages.collapseOut.defaultMessage} (
          {allAttributeOptionsNodes.length - uncollapsedOptionsNodes.length})
        </Link2>
      )}
    </ExpandPanel>
  );
};

export default CountryOptions;
