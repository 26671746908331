import * as React from 'react';
import { connect, MapDispatchToProps, MapStateToProps, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { generatePath } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import clsx from 'clsx';
import { find, remove } from 'lodash';
import styled from '@emotion/styled/macro';
import { Chip, Theme, useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import FilterButton from 'components/layout/header/FilterButton';
import { usePriceType } from 'components/layout/header/usePriceType';
import Icon from 'components/shared/Icon';
import { Link2, TextBody2, TextSubTitle } from 'components/shared/text';
import { IFilterOptions } from 'models';
import { baseUrl, categories, UAH } from 'shared/constants';
import { ATTRIBUTE_COLOR_PATH } from 'shared/filters';
import {
  CATALOG_SET_COLUMNS,
  filterAndSortCatalog,
  IFilterAndSortProps,
  IFiltersData,
  layoutListColumnsDefault
} from 'store/catalog/actions';
import {
  getCatalogDocsShown,
  getCatalogFilters,
  getCatalogLoadingState,
  getCatalogSort,
  getCatalogSortedState
} from 'store/catalog/selectors';
import { getFilterOptionsData } from 'store/filter-options/selectors';
import { setPriceType } from 'store/layout';
import { IApplicationState } from 'store/reducers';
import messages from 'translations/catalog/common';
import { filtersMessages } from 'translations/catalog/filters';
import { filterJsonToString, filtersToString, getFilterLabel } from 'utils/filters';
import { IThemed } from 'utils/styled';

import { TopBarFiltersWrapper } from '../../styles';
import { AlphabetNav } from './AlphabetNav';
import { CatalogDelivery } from './catalog-delivery';
import { SortingSelect } from './SortingSelect';

import styles from './TopBar.module.scss';

export interface IProps {
  onNavClick: () => {};
}

interface IStateProps {
  filtersData: IFiltersData;
  filterOptionsData: IFilterOptions;
  locale: string;
  sort: string;
  docsShown: number;
  catalogLoading: boolean;
  catalogSorted: boolean;
}

interface IDispatchProps {
  filterAndSortCatalog: typeof filterAndSortCatalog;
}

type IComponentProps = IProps & IStateProps & IDispatchProps;

const CatalogTopBar: React.FC<any> = ({
  onNavClick,
  filtersData,
  filterOptionsData,
  filterAndSortCatalog: setFilterAndSort,
  sort,
  docsShown,
  locale,
  catalogLoading,
  catalogSorted
}: IComponentProps) => {
  const dispatch = useDispatch();
  const { updatePriceType, priceType } = usePriceType();
  const history = useHistory();
  const classes = useStyles();
  const layoutType = window.localStorage.getItem('layoutType');

  const setSort = (newSort: string) => {
    setFilterAndSort({ ...filtersData, sort: newSort, locale, fast: filtersData.fast } as IFilterAndSortProps);
  };

  const beautifyUrl = (fastFilters: any[]) => {
    const {
      inStock,
      productType,
      preOrder,
      supplier,
      discount,
      specialOffers,
      transitOffers,
      specialTransitOffers,
      deliveryRegion,
      eventPrice
    } = filtersData;
    const filterMap = filtersToString(fastFilters);
    let str = filterJsonToString(filterMap);
    if (inStock) {
      str = `inStock=${+!!inStock};${str}`;
    }

    str = `${preOrder ? 'preOrder=1;' : ''}${deliveryRegion ? 'deliveryRegion=' + deliveryRegion + ';' : ''}${
      discount ? 'discount=1;' : ''
    }${supplier ? 'supplier=' + supplier + ';' : ''}${specialOffers ? 'specialOffers=1;' : ''}${
      transitOffers ? 'transitOffers=1;' : ''
    }${specialTransitOffers ? 'specialTransitOffers=1;' : ''}${
      eventPrice ? 'eventPrice=' + eventPrice + ';' : ''
    }${str}`;

    const url = generatePath(`${baseUrl}/catalog/:productType/:filterUrl?`, {
      productType,
      filterUrl: str || undefined
    });
    history.push(url);
  };

  const handleDelete = (item: any) => () => {
    const { inStock } = filtersData;
    const filtersFast = filtersData.fast || [];
    remove(filtersFast, item);

    beautifyUrl(filtersData.fast || []);

    setFilterAndSort({ ...filtersData, sort, locale, fast: filtersFast, inStock });
  };

  const handleResetFilters = () => {
    const { inStock } = filtersData;
    setFilterAndSort({ ...filtersData, sort, locale, fast: [], inStock });
    beautifyUrl([]);
  };

  const handleLayoutChange = (type: string) => {
    const columns: number = type === 'table' ? 1 : layoutListColumnsDefault;
    window.localStorage.setItem('layoutType', type);
    dispatch({
      type: CATALOG_SET_COLUMNS,
      payload: columns
    });
    const { inStock } = filtersData;
    const filtersFast = filtersData.fast || [];
    setFilterAndSort({ ...filtersData, sort, locale, fast: filtersFast, inStock });
  };

  const isFilterExists = (path: string, value: string): boolean => {
    try {
      if (path.startsWith('attributes.')) {
        const attributeAlias = path.split('.')[1];
        const attributeFilter = find(filterOptionsData.attributes, { alias: attributeAlias });
        if (attributeFilter && attributeFilter.options && attributeFilter.options.length) {
          return attributeFilter.options.indexOf(value) === -1;
        }
      }
      return false;
    } catch (err) {
      return false;
    }
  };

  const theme: Theme = useTheme();
  const screenDevice = useMediaQuery(theme.breakpoints.down('lg'));
  const screenMobile = useMediaQuery(theme.breakpoints.down('md'));

  const getStatusLabel = () => {
    if (filtersData.inStock) {
      return messages.inStock.defaultMessage;
    } else if (filtersData.specialOffers) {
      return messages.specialOffers.defaultMessage;
    } else if (filtersData.transitOffers) {
      return messages.transitOffers.defaultMessage;
    } else if (filtersData.specialTransitOffers) {
      return messages.specialTransitOffers.defaultMessage;
    } else if (filtersData.preOrder) {
      return messages.preOrder.defaultMessage;
    } else if (
      filtersData.eventPrice &&
      filterOptionsData.stockStatuses?.dynamicStatuses &&
      filterOptionsData.stockStatuses.dynamicStatuses[filtersData.eventPrice]
    ) {
      return filterOptionsData.stockStatuses.dynamicStatuses[filtersData.eventPrice].label;
    }
    return '';
  };

  return (
    <div>
      <TopBarFiltersWrapper className={clsx('with-margin', { device: screenDevice, mobile: screenMobile })}>
        {!screenMobile ? (
          <React.Fragment>
            <SelectedFilters>
              {(docsShown > 0 || !!filtersData.fast.length) && (
                <FoundWrapper>
                  <FilterButton />
                  <TextBody2 component={'span'}>{messages.found.defaultMessage}</TextBody2>:
                  <FoundCount component={'span'}>
                    {catalogLoading || !catalogSorted ? <Skeleton variant="rectangular" width="40px" /> : docsShown}
                  </FoundCount>
                </FoundWrapper>
              )}
              <ChipList>
                <Chip
                  color="primary"
                  label={getStatusLabel()}
                  deleteIcon={<Icon type={'cross'} size={8} leftOffset={8} />}
                />
                <Chip
                  color="primary"
                  label={categories[filtersData.productType]}
                  deleteIcon={<Icon type={'cross'} size={8} leftOffset={8} />}
                />
                {filtersData.fast.map((item, index) => {
                  let isDisabled = isFilterExists(item.path, item.value);

                  let label = getFilterLabel(item, filterOptionsData);
                  if (item.path === 'height') {
                    label += ` ${filtersMessages.chipsHeight.defaultMessage}`;
                  }
                  if (item.path === 'price') {
                    label += ` ${UAH}${filtersMessages.chipsPricePerUnit.defaultMessage}`;
                  }
                  if (item.path === ATTRIBUTE_COLOR_PATH && item.value === '') {
                    isDisabled = false;
                  }

                  if (!label) {
                    return null;
                  }

                  return (
                    <Chip
                      key={index}
                      className={clsx(styles.chip, {
                        inactive: isDisabled
                      })}
                      color="primary"
                      label={label}
                      deleteIcon={<Icon type={'cross'} size={8} leftOffset={8} />}
                      onDelete={handleDelete(item)}
                    />
                  );
                })}
                {!!filtersData.fast.length && (
                  <Link2 className={classes.resetAllButton} onClick={handleResetFilters}>
                    {filtersMessages.resetFilters.defaultMessage}
                  </Link2>
                )}
              </ChipList>
            </SelectedFilters>
          </React.Fragment>
        ) : null}
      </TopBarFiltersWrapper>
      {!screenMobile && ['name_asc', 'name_desc'].indexOf(sort) > -1 && (
        <AlphabetNav onSelect={onNavClick} useDeviceStyle={screenDevice} isDesc={sort.indexOf('desc') > -1} />
      )}
      <div className={styles.container}>
        <CatalogDelivery />
        <SortOrder>
          <SortingSelect sortKey={sort} setFilter={setSort} />
        </SortOrder>
        <div className={styles.priceActions}>
          <IconButton
            className={clsx({ [styles.active]: priceType === 'unit' })}
            onClick={() => updatePriceType('unit')}
            size="large"
          >
            <Icon offset={4} type="flowerUnit" size={20} />
            <TextBody2 component="span">Штуки</TextBody2>
          </IconButton>
          <IconButton
            className={clsx({ [styles.active]: priceType === 'package' })}
            onClick={() => updatePriceType('package')}
            size="large"
          >
            <Icon offset={4} type="package" size={20} />
            <TextBody2 component="span">Упаковки</TextBody2>
          </IconButton>
        </div>
        <LayoutIcons className={styles.layoutIcons}>
          <IconButton
            className={clsx({ active: layoutType === 'list' })}
            onClick={() => handleLayoutChange('list')}
            size="large"
          >
            <Icon type="listLayout" size={32} />
          </IconButton>
          <IconButton
            className={clsx({ active: layoutType === 'table' })}
            onClick={() => handleLayoutChange('table')}
            size="large"
          >
            <Icon type="tableLayout" size={32} />
          </IconButton>
        </LayoutIcons>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    resetAllButton: {
      display: 'inline-block',
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      marginLeft: theme.spacing(2),
      whiteSpace: 'nowrap'
    }
  })
);

const FoundWrapper = styled<any>('div')({
  display: 'flex',
  alignItems: 'center'
});

const FoundCount = styled<any>(TextSubTitle)({
  '&&': {
    marginLeft: 6
  }
});

const SelectedFilters = styled<any>('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '4px',
  marginTop: '20px'
});

const SortOrder = styled<any>('div')({
  flexShrink: 0
});

const ChipList = styled<any>('div')({
  marginLeft: 40,
  maxHeight: 72,
  overflow: 'hidden',

  '& > div': {
    marginLeft: 8
  }
});

const LayoutIcons = styled<any>('div')(({ theme }: IThemed) => ({
  '& > button': {
    padding: 0,
    fill: 'black',
    fillOpacity: 0.6,

    '&:hover': {
      fill: theme.palette.primary.main,
      background: '#f1e8f7',
      fillOpacity: 1
    },

    '&:first-child': {
      marginLeft: 36
    },

    '&:last-child': {
      marginLeft: 8
    },

    '&.active': {
      fill: theme.palette.primary.main,
      background: '#f1e8f7',
      fillOpacity: 1
    }
  }
}));

const mapStateToProps: MapStateToProps<IStateProps, {}, IApplicationState> = (state: IApplicationState) => ({
  locale: state.locale,
  sort: getCatalogSort(state),
  filtersData: getCatalogFilters(state),
  filterOptionsData: getFilterOptionsData(state),
  docsShown: getCatalogDocsShown(state),
  catalogLoading: getCatalogLoadingState(state),
  catalogSorted: getCatalogSortedState(state)
});

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, {}> = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      filterAndSortCatalog,
      setPriceType
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(CatalogTopBar);
