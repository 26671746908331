import { FC } from 'react';
import { defineMessages } from 'react-intl';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';

import Icon from 'components/shared/Icon';
import { ITheme } from 'utils/styled';

import ButtonCommon from '../header-main/top/StyledButtonCommon';

interface IProps {
  dark?: boolean;
  classes?: any;
}

export const help = defineMessages({
  label: {
    id: 'catalog.header.helpLabel',
    description: 'help label',
    defaultMessage: 'Допомога'
  }
});

export const HelpWrapper: FC<IProps> = ({ classes, dark }) => (
  <div
    className={clsx(classes.wrapper, {
      [classes.wrapperDark]: dark
    })}
  >
    <a target="_blank" rel="noopener noreferrer" href="https://t.me/FlorotekaBot">
      <ButtonCommon dark={dark}>
        <Icon type={'support'} size={24} offset={8} />
        <span>{help.label.defaultMessage}</span>
      </ButtonCommon>
    </a>
  </div>
);

const styles = (theme: ITheme) => ({
  wrapper: {
    width: 'fit-content',
    fontFamily: theme.fonts.headings,
    letterSpacing: theme.letterSpacing,
    paddingLeft: 14,
    marginLeft: 7,
    color: theme.palette.text.secondary,
    borderLeft: `1px solid ${theme.palette.common.disabledBlack}`,
    gap: 11
  },
  wrapperDark: {
    marginRight: 'auto',
    borderLeft: `1px solid ${theme.palette.common.white}`
  }
});

export const Help = withStyles<any>(styles)(HelpWrapper);
