import { CartItem } from 'models';
import { SplitedPrice } from 'components/prices';
import FlrQuantity from 'components/shared/form-elements/quantity/FlrQuantity';

import messages from 'translations/cart/common';
import { FC } from 'react';
import CartItemQty from '../../../shared/product/product-details/CartItemQty';
import classes from './CartGroupCardItemData.module.scss';
import CartItemCategory from './CartItemCategory';

interface IProps {
  removeDisabled?: boolean;
  max?: number;
  item: CartItem;
  onChangeAmount?: (qty: number) => void;
  onRemove?: () => void;
  withItemRemove?: boolean;
}

interface ICartDataProps {
  label: string;
  item: number;
  bold?: boolean;
  hide?: boolean;
  prefix?: string;
}

const CartGroupCardItemDataDevice: FC<IProps> = ({ max, item, onChangeAmount, onRemove}) => {
  const isArchived = !item.offer || item.product.archived;
  const isPreOrder = item.offer.preOrder || item.offer.extraPrice;

  const cartData: ICartDataProps[] = [
    {
      label: messages.price.defaultMessage,
      item: item.price,
      prefix: isPreOrder ? '~' : ''
    },
    {
      label: messages.totalDiscount.defaultMessage,
      item: item.discount,
      hide: item.discount <= 0,
      prefix: '-'
    },
    {
      label: messages.total.defaultMessage,
      item: item.total,
      bold: true,
      prefix: isPreOrder ? '~' : ''
    }
  ];

  const {catalogCategory, productGroup, inPackageCount, code} = item.product
  return (
    <>

      <CartItemCategory
        catalogCategory={catalogCategory}
        productGroup={productGroup}
        inPackageCount={inPackageCount}
        manufacturerName={item.product?.attributes?.manufacturer?.value}
        country={item.product?.attributes?.country?.value}
        code={code}
      />
      <CartItemQty id={item.product.id} className={classes.itemQtyContainer}/>
      {isArchived ? (
        <FlrQuantity value={item.qty} disabled />
      ) : (
        <div className={classes.quantityContainer}>
          <span className={classes.label}>{messages.unit.defaultMessage}</span>
          <FlrQuantity
            value={item.qty}
            inPackageCount={item.product.inPackageCount}
            min={item.getMinQty}
            onDecrement={item.qty === item.product.inPackageCount ? () => onRemove && onRemove() : undefined}
            max={max || item.qty}
            onChange={onChangeAmount}
            disableMaxLimit={!isPreOrder}
            isSelect
          />
          <span className={classes.label}>{item.product.inPackageCount} уп.</span>
        </div>
      )}
      {cartData.map((cart, index) =>
        !cart.hide ? (
          <div key={index} className={classes.content}>
            <span className={classes.label}>{cart.label}</span>
            <SplitedPrice
              value={cart.item}
              fontColor={isArchived || cart.prefix === '~' ? 'helperBlack' : 'textSecondary'}
              fontSize={'h3'}
              fontSizeDecimal={'h5'}
              prefix={cart.prefix}
            />
          </div>
        ) : null
      )}
    </>
  );
};

export default CartGroupCardItemDataDevice;
