import React, { useEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router';
import clsx from 'clsx';
import { iOS } from 'environment';
import { debounce } from 'lodash';
import { Drawer, Theme, useMediaQuery } from '@mui/material';
import { useTheme, withStyles } from '@mui/styles';

import Header from 'components/layout/header';
import { baseUrl, hideLeftPanelWidth, hideRightPanelWidth } from 'shared/constants';

import { Footer } from '../footer';
import styles, { Layout } from './styles';

const isIOS = iOS(window.navigator.userAgent);

interface IProps {
  classes?: any;
  fullScreen?: boolean;
  hideMargin?: boolean;
  mirrored?: boolean;
  noScroll?: boolean;
  topPanel?: (props: any) => React.ReactNode;
  leftPanel: (props: IToggleablePanel) => React.ReactNode;
  rightPanel: (props: IToggleablePanel) => React.ReactNode;
  leftPanelToggleable?: boolean;
  leftPanelOpened?: boolean;
  onLeftPanelClose?: () => void;
  rightPanelToggleable?: boolean;
  rightPanelOpened?: boolean;
  rightPanelVariant?: 'permanent' | 'temporary' | 'persistent';
  onRightPanelClose?: () => void;
  trackAnchor?: string;
  anchorIndex?: number;
  setAnchorIndex?: (index: number) => void;
  catalogPanel?: boolean;
  accountLeftSideWidth?: boolean;
  isCheckout?: boolean;
  smallWidth?: boolean;
  isFixed?: boolean;
  isFixedRightPanel?: boolean;
}

export interface IPanelsLayout {
  leftOpened: boolean;
  rightOpened: boolean;
}

export interface IToggleablePanel {
  isToggleable?: boolean;
  onClose: () => void;
}

const SidePanelsLayout: React.FC<IProps> = (props) => {
  const {
    children,
    classes,
    topPanel,
    leftPanel,
    rightPanel,
    fullScreen = true,
    leftPanelOpened = true,
    rightPanelOpened = true,
    leftPanelToggleable = false,
    rightPanelToggleable = false,
    rightPanelVariant,
    onLeftPanelClose,
    onRightPanelClose,
    noScroll,
    trackAnchor,
    mirrored,
    catalogPanel,
    accountLeftSideWidth,
    smallWidth,
    isFixed,
    isFixedRightPanel,
    hideMargin,
    isCheckout
    /*    anchorIndex = 0,
    setAnchorIndex*/
  } = props;

  const layoutRef: React.RefObject<any> = useRef(null);
  const theme: Theme = useTheme();
  const screenFullHD = useMediaQuery(theme.breakpoints.up('xl'));
  const screenHD = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
  const screenDevice = useMediaQuery(theme.breakpoints.down('lg'));
  const hideRightPanel = useMediaQuery(theme.breakpoints.down(hideRightPanelWidth));
  const hideLeftPanel = useMediaQuery(theme.breakpoints.down(hideLeftPanelWidth));

  const isCatalog = useRouteMatch(`${baseUrl}/catalog/:type?/:filter?`);
  const isCart = useRouteMatch(`${baseUrl}/cart`);
  const isHeaderBottomExists = isCatalog || isCart;
  // const isTableLayout: boolean = window.localStorage.getItem('layoutType') === 'table';

  // textInput должна быть объявлена здесь, чтобы реф мог иметь к ней доступ
  const container = useRef(null);
  useEffect(() => {
    if (container.current) {
      const containerEl = container.current as unknown as HTMLElement;
      if (containerEl) {
        const firstEl = containerEl.firstElementChild;
        if (firstEl) {
          (firstEl as HTMLElement).id = `${containerEl.id}-inner`;
        }
      }
    }
  }, [container]);

  useEffect(() => {
    const chatBtn = document.querySelector('.rngst_phone_button') as HTMLElement;
    if (chatBtn && screenDevice && (leftPanelOpened || rightPanelOpened)) {
      chatBtn.style.display = 'none';
    }
    return () => {
      if (chatBtn) {
        chatBtn.style.display = 'block';
      }
    };
  }, [leftPanelOpened, rightPanelOpened, screenDevice]);

  useEffect(() => {
    if (isIOS) {
      const appHeight = () => {
        if (layoutRef.current && window.location.pathname.includes('catalog')) {
          layoutRef.current.style.setProperty('min-height', `${window.innerHeight}px`);
        }
      };

      // initial execute and subscribe to resize event
      setTimeout(appHeight, 1000);
      window.addEventListener('resize', appHeight);
      return () => {
        window.removeEventListener('resize', appHeight);
      };
    }
  }, []);

  /* TODO for reference -> make scroll up with no changing min-height (if it is top of the page)
  elem.addEventListener('touchstart', function(event){
    this.allowUp = (this.scrollTop > 0);
    this.allowDown = (this.scrollTop < this.scrollHeight - this.clientHeight);
    this.prevTop = null;
    this.prevBot = null;
    this.lastY = event.pageY;
  });

  elem.addEventListener('touchmove', function(event){
      var up = (event.pageY > this.lastY),
          down = !up;

      this.lastY = event.pageY;

      if ((up && this.allowUp) || (down && this.allowDown))
          event.stopPropagation();
      else
          event.preventDefault();
  });
  */

  const defaultHandle = () => ({});
  const handleLeftAsideClose = onLeftPanelClose || defaultHandle;
  const handleRightAsideClose = onRightPanelClose || defaultHandle;

  const cl = (name: string) => {
    const key = `${fullScreen ? '' : 'f_'}${name}`;
    return classes[key];
  };

  const topBarContent = topPanel && topPanel({});
  const rightBarContent =
    rightPanel && rightPanel({ onClose: handleRightAsideClose, isToggleable: rightPanelToggleable });

  const [isScrolling, setIsScrolling] = useState(false);
  const hideScroll = debounce(() => {
    setIsScrolling(false);
  }, 500);

  const handleScroll = () => {
    if (isScrolling) {
      return;
    }
    setIsScrolling(true);
    hideScroll();
  };

  return screenDevice ? (
    <>
      <Header />
      <Layout
        ref={layoutRef}
        className={clsx(classes.mobLayoutContainer, classes[`mobLayoutContainer-${trackAnchor}`], {
          [classes.mobileDrawer]: rightPanelOpened,
          [classes.mobLayoutContainerIOS]: isIOS
        })}
      >
        <div className={clsx(classes[`mobLayout-${trackAnchor}`])}>
          {topBarContent}
          <div>
            <Drawer
              onClose={onLeftPanelClose}
              className={cl('leftDrawer')}
              variant={screenFullHD || (screenHD && !mirrored) ? 'permanent' : 'temporary'}
              anchor="left"
              open={leftPanelOpened}
              classes={{
                paper: cl('leftDrawerPaper')
              }}
              PaperProps={{
                style: {
                  // transition: "top 0.5s ease-in-out, left 0.5s ease-in-out",
                  left: leftPanelOpened ? 0 : '-100%'
                }
              }}
              ModalProps={{
                disableScrollLock: isIOS
              }}
            >
              {leftPanel && leftPanel({ onClose: handleLeftAsideClose, isToggleable: leftPanelToggleable })}
            </Drawer>
            <div
              ref={container}
              id={`${trackAnchor}-container`}
              className={clsx(cl('content'), classes.mobContent, classes[`mobContent-${trackAnchor}`], {
                [classes.contents]: isCheckout || isCart
              })}
              onScroll={!noScroll ? handleScroll : undefined}
            >
              {children}
            </div>
          </div>
        </div>
        <Drawer
          onClose={onRightPanelClose}
          className={clsx(cl('rightDrawer'), {
            temporary: !(screenFullHD || (screenHD && !mirrored))
          })}
          variant={
            rightPanelVariant
              ? rightPanelVariant
              : screenFullHD || (screenHD && (fullScreen || mirrored))
              ? 'permanent'
              : 'temporary'
          }
          anchor="right"
          open={rightPanelOpened}
          classes={{
            paper: cl('rightDrawerPaper')
          }}
          transitionDuration={{ appear: 0, enter: 0, exit: 0 }}
          style={{ display: rightBarContent ? 'block' : 'none' }}
          PaperProps={{
            square: true
          }}
          ModalProps={{
            disableScrollLock: isIOS
          }}
        >
          {rightBarContent}
        </Drawer>
      </Layout>
      <Footer showOnlyDevice={fullScreen} />
    </>
  ) : (
    <>
      <Layout className={clsx(cl('layout'), { 'with-footer': !fullScreen })}>
        <Header />
        <main
          className={clsx(cl('rightDrawerLayout'), {
            [cl('rightDrawerLayoutShift')]: rightPanelOpened,
            [cl('hideRightMargin')]: hideMargin,
            [cl('rightDrawerCheckout')]: isCheckout,
            'header-bottom': isHeaderBottomExists
          })}
        >
          <div className={clsx(cl('rightDrawerLayoutInner'), { noShadow: rightBarContent === null })}>
            {topBarContent}
            <div
              className={clsx(cl('leftDrawerLayout'), {
                [cl('leftDrawerLayoutShift')]: leftPanelOpened,
                [cl('leftDrawerLayoutShiftAccount')]: accountLeftSideWidth,
                [classes.leftDrawerLayoutShiftCart]: smallWidth,
                [cl('hideLeftMargin')]: hideMargin,
                withTopBar: topBarContent,
                mirrored
              })}
            >
              <Drawer
                onClose={onLeftPanelClose}
                className={clsx(cl('leftDrawer'), {
                  [cl('leftDrawerAccount')]: accountLeftSideWidth,
                  [cl('leftPanelCart')]: smallWidth
                })}
                // variant={screenFullHD || (screenHD && !mirrored) ? 'permanent' : 'temporary'}
                variant={
                  hideLeftPanel && catalogPanel
                    ? 'temporary'
                    : screenFullHD || (screenHD && !mirrored)
                    ? 'permanent'
                    : 'temporary'
                }
                anchor="left"
                open={leftPanelOpened}
                classes={{
                  paper: clsx(cl('leftDrawerPaper'), {
                    [classes.leftDrawerPaperCart]: smallWidth,
                    [classes.drawerFixed]: isFixed,
                    [cl('leftDrawerAccount')]: accountLeftSideWidth
                  })
                }}
                PaperProps={{
                  style: {
                    // transition: "top 0.5s ease-in-out, left 0.5s ease-in-out",
                    left: leftPanelOpened ? 0 : '-100%'
                  }
                }}
              >
                {leftPanel && leftPanel({ onClose: handleLeftAsideClose, isToggleable: leftPanelToggleable })}
              </Drawer>
              <div
                ref={container}
                id={`${trackAnchor}-container`}
                className={clsx(cl('content'), { 'no-scroll': !isScrolling, [classes.checkoutContainer]: isCheckout })}
                onScroll={!noScroll ? handleScroll : undefined}
              >
                {children}
              </div>
            </div>
          </div>
          <Drawer
            onClose={onRightPanelClose}
            className={clsx(cl('rightDrawer'), {
              temporary: !(screenFullHD || (screenHD && !mirrored))
            })}
            // variant={rightPanelVariant ? rightPanelVariant : !hideRightPanel ? 'permanent' : 'temporary'}
            variant={
              hideRightPanel && catalogPanel
                ? 'temporary'
                : screenFullHD || (screenHD && (fullScreen || mirrored))
                ? 'permanent'
                : 'temporary'
            }
            anchor="right"
            open={rightPanelOpened}
            classes={{
              paper: clsx(cl('rightDrawerPaper'), {
                [classes.rightDrawerPaperCart]: smallWidth,
                [classes.drawerFixed]: isFixedRightPanel,
                [classes.rightDrawerPaperCheckout]: isCheckout
              })
            }}
            style={{ display: rightBarContent ? 'block' : 'none' }}
            PaperProps={{
              square: true
            }}
          >
            {rightBarContent}
          </Drawer>
        </main>
      </Layout>
      <Footer showOnlyDevice={fullScreen} />
    </>
  );
};

export default withStyles<any>(styles)(SidePanelsLayout);
