import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { throttle, upperFirst } from 'lodash';
import moment from 'moment';
import { Button, Grid, Hidden, Theme, useMediaQuery } from '@mui/material';
import { useTheme, withStyles } from '@mui/styles';

import AsideDeviceBottomPanel from 'components/layout/aside-device/footer';
import AsideDeviceTopPanel from 'components/layout/aside-device/header';
import SidePanelsLayout from 'components/layout/side-panels-layout';
import FullHeightSkeleton from 'components/loading/FullHeightSkeleton';
import FlrLoader from 'components/loading/LoadingSpinner';
import { SplitedPrice } from 'components/prices';
import Icon from 'components/shared/Icon';
import { TextBody1, TextSubTitle, TitleH1 } from 'components/shared/text';
import { GTM, useTrackInitialPageLoad } from 'controllers';
import { Cart, Order, ORDER_TYPE_PREORDER, Outlet } from 'models';
import { baseUrl, cartGroupCheckoutStorageKey, hideRightPanelWidth } from 'shared/constants';
import { ISetActiveItem } from 'store/cart/actions';
import { getCart, getCartLoaded, getCartLoadingState } from 'store/cart/selectors';
import {
  cancelCheckout,
  fetchCheckoutAsync,
  finalizeAllOrdersAsync,
  finalizeOrderAsync,
  selectOrderItem,
  setActiveOrder,
  unselectOrderItem,
  updateOrdersAsync
} from 'store/checkout/actions';
import {
  getCheckoutActiveOrder,
  getCheckoutFinalizedOrders,
  getCheckoutFinalizingOrders,
  getCheckoutLoaded,
  getCheckoutLoadingState,
  getCheckoutOrders,
  getCheckoutSelectedOrderItem
} from 'store/checkout/selectors';
import { getOutletsData, getOutletsLoadingState } from 'store/outlets/selectors';
import { IApplicationState } from 'store/reducers';
import messages from 'translations/checkout/common';

import HoldCart from '../../components/timer/HoldCart';
import CheckoutBreadcrumbs from './components/CheckoutBreadcrumbs';
import CheckoutMain from './components/CheckoutMain';
import CheckoutRightSide from './components/CheckoutRightSide';
import styles from './styles';

import classes from './Checkout.module.scss';

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}

interface IStateProps {
  locale: any;
  cartLoaded: boolean;
  cartLoadingState: boolean;
  cart: Cart;
  checkoutLoaded: boolean;
  checkoutLoadingState: boolean;
  checkoutOrders: Order[];
  checkoutFinalizingOrders: boolean;
  checkoutFinalizedOrders: Order[];
  outletsLoadingState: boolean;
  outlets: Outlet[];
  activeOrder: ISetActiveItem;
  activeOrderItem: string;
}

interface IDispatchProps {
  fetchCheckoutOrders: typeof fetchCheckoutAsync.request;
  updateCheckoutOrders: typeof updateOrdersAsync.request;
  submitCheckoutOrder: typeof finalizeOrderAsync.request;
  submitAllCheckoutOrders: typeof finalizeAllOrdersAsync.request;
  cancelOrders: typeof cancelCheckout;
  setActiveOrderIndex: typeof setActiveOrder;
  setActiveOrderItem: typeof selectOrderItem;
  unsetActiveOrderItem: typeof unselectOrderItem;
}

type CheckoutProps = IStateProps & IDispatchProps;

// redirect time for inactive user 10 min
const INACTIVE_USER_TIME_THRESHOLD = 10 * 60 * 1000;

let userActivityTimeout: any = null;

function setUserActivityTimeout() {
  clearTimeout(userActivityTimeout);
  userActivityTimeout = setTimeout(() => {
    window.location.replace(`${baseUrl}/cart`);
  }, INACTIVE_USER_TIME_THRESHOLD);
}

const UnconnectedCheckout: React.FC<CheckoutProps> = ({
  locale,
  outlets,
  cart,
  cartLoaded,
  cartLoadingState,
  checkoutOrders,
  checkoutLoaded,
  checkoutLoadingState,
  outletsLoadingState,
  checkoutFinalizingOrders,
  checkoutFinalizedOrders,
  fetchCheckoutOrders,
  updateCheckoutOrders,
  submitCheckoutOrder: onSubmitAction,
  submitAllCheckoutOrders,
  cancelOrders,
  activeOrder,
  setActiveOrderIndex,
  activeOrderItem,
  setActiveOrderItem,
  unsetActiveOrderItem
}) => {
  useTrackInitialPageLoad();

  const theme: Theme = useTheme();
  const screenFullHD = useMediaQuery(theme.breakpoints.up('xl'));
  const screenHD = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
  const hideRightPanel = useMediaQuery(theme.breakpoints.down(hideRightPanelWidth));

  const [openRightPanel, setOpenRightPanel] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isRightPanel, setIsRightPanel] = useState(false);
  const [displayedGroup, setDisplayedGroup] = useState<string | undefined>('');

  const checkoutGroup = String(window.localStorage.getItem(cartGroupCheckoutStorageKey));
  const isCheckoutPage = !!useRouteMatch(`${baseUrl}/checkout`);

  /** mobile header */
  const history = useHistory();
  const handleMobileClose = () => {
    cancelOrders();
    history.push(`${baseUrl}/cart`);
  };

  useEffect(() => {
    setUserActivityTimeout();
    window.addEventListener('click', setUserActivityTimeout);
    window.addEventListener('keydown', setUserActivityTimeout);
    return () => {
      clearTimeout(userActivityTimeout);
      window.removeEventListener('click', setUserActivityTimeout);
      window.removeEventListener('keydown', setUserActivityTimeout);
    };
  }, []);

  useEffect(() => {
    if (cartLoadingState || !checkoutGroup) {
      return;
    }
    if (
      checkoutGroup &&
      checkoutGroup !== 'null' &&
      cartLoaded &&
      !cart.groups[checkoutGroup] &&
      cart.items.length > 0
    ) {
      cancelOrders();
    }
  }, [cart, cartLoadingState, cartLoaded, checkoutGroup, cancelOrders]);

  useEffect(() => {
    if (!cart.items || !cart.items.length) {
      return;
    }
    GTM.trackEnterToCheckoutPage(cart);
    // eslint-disable-next-line
  }, [cartLoaded]);

  useEffect(() => {
    if (checkoutGroup) {
      if (!checkoutLoaded && !checkoutLoadingState) {
        fetchCheckoutOrders(checkoutGroup);
      }
    } else if (checkoutLoaded) {
      cancelOrders();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (screenHD || screenFullHD) {
      setOpenRightPanel(true);
    }
    if (hideRightPanel) {
      setOpenRightPanel(false);
    }
  }, [screenHD, screenFullHD, hideRightPanel]);

  const handleScroll = () => {
    if (window.scrollY > 120) {
      setIsRightPanel(true);
    } else {
      setIsRightPanel(false);
    }

    const containerEl = document.getElementById(`${anchor}-wrapper`);
    if (!containerEl) {
      return;
    }

    const allAnchors = containerEl.querySelectorAll(`.${anchor}-item`);
    let currentActiveGroup = displayedGroup;

    Array.from(allAnchors).forEach((item, index) => {
      const rect = item.getBoundingClientRect();
      const heightPage = window.innerHeight;
      const nextItem = allAnchors[index + 1];

      if (rect.height <= heightPage && rect.top >= 0 && rect.bottom <= heightPage) {
        const firstElementId = item.children[0]?.id;
        if (firstElementId) {
          currentActiveGroup = `${anchor}-${firstElementId}`;
        }
      } else if (rect.bottom - 100 <= 0 && nextItem) {
        const nextElementId = nextItem.children[0]?.id;
        if (nextElementId) {
          currentActiveGroup = `${anchor}-${nextElementId}`;
        }
      } else if (rect.top <= 0 && rect.bottom > 0) {
        const firstElementId = item.children[0]?.id;
        if (firstElementId) {
          currentActiveGroup = `${anchor}-${firstElementId}`;
        }
      }
    });

    if (currentActiveGroup !== displayedGroup) {
      setDisplayedGroup(currentActiveGroup);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', throttle(handleScroll, 50));
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const anchor = 'order-card';

  const offset = useRef(0);
  useEffect(() => {
    const checkElementsLoaded = () => {
      const containerEl = document.getElementById(`${anchor}-wrapper`);

      if (!checkoutLoaded || checkoutLoadingState || !containerEl) {
        return;
      }

      const firstAnchor = containerEl.querySelector(`.${anchor}-item`);
      const allAnchors = containerEl.querySelectorAll(`.${anchor}-item`);
      const lastAnchor = allAnchors.length > 1 && (allAnchors.item(allAnchors.length - 1) as HTMLElement);

      if (firstAnchor) {
        const el = firstAnchor as HTMLElement;
        offset.current = -(el.offsetTop + 100);

        if (lastAnchor) {
          lastAnchor.style.marginBottom = `${Math.abs(
            offset.current + Math.max(0, containerEl.offsetHeight - lastAnchor.offsetHeight)
          )}px`;
        }

        const firstValidAnchor = Array.from(allAnchors).find((item) => {
          return item.children[0]?.id;
        });

        if (firstValidAnchor) {
          const firstElementId = firstValidAnchor.children[0]?.id;
          if (firstElementId) {
            setDisplayedGroup(`${anchor}-${firstElementId}`);
          }
        }
      }
    };

    setTimeout(checkElementsLoaded, 10);
  }, [offset, checkoutLoaded, checkoutLoadingState]);
  const isPreOrder = checkoutOrders && checkoutOrders[0] && checkoutOrders[0].orderType === ORDER_TYPE_PREORDER;

  const nowHours = moment().hours();
  const createDeliveryOptions = () => {
    return [
      isPreOrder ? moment().add(5, 'days') : moment().add(moment().hours() >= 18 ? 1 : 0, 'days'),
      isPreOrder ? moment().add(7, 'days') : moment()
    ];
  };
  // eslint-disable-next-line
  const deliveryOptions = useMemo(createDeliveryOptions, [isPreOrder, nowHours]);

  if (!checkoutOrders.length) {
    return null;
  }

  const warehouseName = checkoutOrders && checkoutOrders[0] && checkoutOrders[0].warehouse.displayName;
  const finalizedOrder = checkoutFinalizedOrders[0];
  const order = checkoutOrders[0];

  const hasMultiOrders = checkoutOrders.length + checkoutFinalizedOrders.length > 1;

  const orderToExtractDate =
    (order && order.deliveryDate && order) || (finalizedOrder && finalizedOrder.deliveryDate && finalizedOrder);
  // @ts-ignore
  const orderDate = orderToExtractDate && moment(orderToExtractDate.deliveryDate).format('dd DD.MM');


  const onSubmit = (orderToSubmit: Order) => {
    setSubmitted(true);
    // tslint:disable-next-line:no-unused-expression
    if (orderToSubmit.isValid()) {
      onSubmitAction(orderToSubmit);
    } else {
      scrollToTop();
    }
  };

  const firstOrder = checkoutOrders && checkoutOrders[0];
  const isFirstOrderPreOrder = firstOrder && firstOrder.orderType === ORDER_TYPE_PREORDER;

  const checkoutOrderOnSubmit = (orderToSubmit: Order) => {
    if (checkoutOrders.length === 1) {
      GTM.trackCheckoutAllOrders(cart);
      setSubmitted(true);
      if (firstOrder.isValid()) {
        return submitAllCheckoutOrders(orderToSubmit.orderId);
      }
      scrollToTop();
      return;
    }
    GTM.trackCheckoutOrder(orderToSubmit);
    return onSubmit(orderToSubmit);
  };

  const onSubmitAllCheckoutOrders = () => {
    GTM.trackCheckoutAllOrders(cart);
    submitAllCheckoutOrders(checkoutOrders[0].orderId);
  };

  // TODO: change delivery date for preOrder and etc
  const contentCheckout = (
    <div className={classes.checkoutMain}>
      <TitleH1 className={classes.title}>Оформлення доставки</TitleH1>
      <div className={classes.checkoutContent}>
        <TextSubTitle className={classes.warehouse}>{warehouseName}</TextSubTitle>
        <div className={classes.deliveryContainer}>
          <Icon type="delivery" opacity={1} className={classes.icon} />
          &nbsp;
          <TextBody1>
            {order.orderSubType !== 'extraPrice' ? (
              <>
                {orderDate || (
                  <>
                    <span className={classes.deliveryDate}>{upperFirst(deliveryOptions[0].format('dd DD.MM'))}</span>
                    {isPreOrder ? (
                      <>
                        {' - '}
                        <span className={classes.preOrderDate}>
                          {upperFirst(deliveryOptions[1].format('dd DD.MM'))}
                        </span>
                      </>
                    ) : null}
                  </>
                )}
              </>
            ) : (
              <>
                <span className={classes.deliveryDate}>
                  {moment(order.shippingData.shippingMinDate).format('dd DD.MM')}
                </span>
                {' - '}
                <span className={classes.deliveryDate}>
                  {moment(order.shippingData.shippingMaxDate).format('dd DD.DD')}
                </span>
              </>
            )}
          </TextBody1>
        </div>
      </div>
      {(checkoutLoadingState || outletsLoadingState || cartLoadingState) && !checkoutLoaded ? (
        <FullHeightSkeleton />
      ) : (
        <>
          <CheckoutMain
            anchor={anchor}
            checkoutGroup={checkoutGroup}
            locale={locale}
            cart={cart}
            orders={checkoutOrders}
            finalizedOrders={checkoutFinalizedOrders}
            outlets={outlets}
            updateOrders={updateCheckoutOrders}
            submitOrder={checkoutOrderOnSubmit}
            cancelCheckout={() => cancelOrders()}
            selectedOrderItem={activeOrderItem}
            onSelectOrderItem={setActiveOrderItem}
            onUnselectOrderItem={() => unsetActiveOrderItem()}
            submitted={submitted}
            submitting={checkoutFinalizingOrders}
          />

          <Grid item xs={12} className={`${anchor}-item`}>
            {checkoutOrders.length > 0 && hasMultiOrders && (
              <Hidden xlUp smDown>
                <Grid container item alignItems="center" justifyContent={'space-evenly'} style={{ marginTop: 50 }}>
                  <Button color={'primary'} onClick={() => cancelOrders()} style={{ paddingLeft: 0 }}>
                    {messages.cancelBtnLabel.defaultMessage}
                  </Button>
                  <Button
                    disabled={checkoutFinalizingOrders}
                    color={'primary'}
                    variant={'contained'}
                    onClick={onSubmitAllCheckoutOrders}
                  >
                    {isPreOrder
                      ? messages.submitPreOrderAllBtnLabel.defaultMessage
                      : messages.submitAllBtnLabel.defaultMessage}
                  </Button>
                </Grid>
              </Hidden>
            )}
          </Grid>
        </>
      )}
    </div>
  );

  return (
    <Grid className={classes.mobileRoot}>
      <Hidden smUp>
        <AsideDeviceTopPanel
          classes={{
            root: classes.checkoutHeader
          }}
          title={messages.mobileTitle.defaultMessage}
          onClose={handleMobileClose}
        />
        <>
          <CheckoutBreadcrumbs />
          {contentCheckout}
        </>

        {isCheckoutPage && Object.keys(cart?.groups).length ? <HoldCart cart={cart} /> : null}

        <AsideDeviceBottomPanel
          classes={{
            root: classes.checkoutFooter
          }}
          info={
            <div>
              {firstOrder && (
                <span className={classes.mobileFooterContent}>
                  <span className={classes.mobileTotalLabel}>{messages.mobileTotal.defaultMessage}</span>
                  <SplitedPrice
                    value={firstOrder.totalSumAll()}
                    prefix={isFirstOrderPreOrder ? '~' : undefined}
                    className={classes.sumPrice}
                  />
                </span>
              )}
            </div>
          }
          action={
            firstOrder ? (
              <Button
                disabled={
                  firstOrder.processing ||
                  !(firstOrder.outlet && firstOrder.outlet.id) ||
                  (submitted && !firstOrder.isValid()) ||
                  checkoutFinalizingOrders
                }
                color={'primary'}
                variant={'contained'}
                onClick={() => {
                  return checkoutOrderOnSubmit(firstOrder);
                }}
                className={classes.checkoutOrderFooterBlock}
                startIcon={firstOrder.processing && <FlrLoader size={16} />}
              >
                {messages.submitPreOrderBtnLabel.defaultMessage}
              </Button>
            ) : null
          }
        />
      </Hidden>

      <Hidden smDown>
        <SidePanelsLayout
          mirrored
          isFixedRightPanel={isRightPanel}
          isCheckout
          fullScreen={false}
          topPanel={() => <CheckoutBreadcrumbs />}
          leftPanel={() => null}
          leftPanelToggleable={screenFullHD}
          leftPanelOpened={screenFullHD}
          // rightPanelOpened={screenHD || screenFullHD}
          rightPanelOpened={openRightPanel}
          rightPanelToggleable={openRightPanel}
          rightPanel={() =>
            checkoutLoadingState && !checkoutLoaded ? (
              <FullHeightSkeleton skeletonHeight={100} withPadding />
            ) : hasMultiOrders && openRightPanel ? (
              <CheckoutRightSide
                preOrder={isPreOrder}
                anchor={anchor}
                anchorOffset={offset.current}
                orders={checkoutOrders}
                finalizedOrders={checkoutFinalizedOrders}
                onCancel={() => cancelOrders()}
                onSubmitAll={submitAllCheckoutOrders}
                submitting={checkoutFinalizingOrders}
                displayedGroup={displayedGroup}
              />
            ) : null
          }
          anchorIndex={activeOrder.index}
          trackAnchor={anchor}
          setAnchorIndex={(index: number) => setActiveOrderIndex({ index })}
        >
          {contentCheckout}
        </SidePanelsLayout>
      </Hidden>
    </Grid>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, {}, IApplicationState> = (state: IApplicationState) => ({
  locale: state.locale,
  cart: getCart(state),
  cartLoadingState: getCartLoadingState(state),
  cartLoaded: getCartLoaded(state),
  checkoutOrders: getCheckoutOrders(state),
  checkoutFinalizedOrders: getCheckoutFinalizedOrders(state),
  checkoutFinalizingOrders: getCheckoutFinalizingOrders(state),
  checkoutLoadingState: getCheckoutLoadingState(state),
  checkoutLoaded: getCheckoutLoaded(state),
  outlets: getOutletsData(state),
  outletsLoadingState: getOutletsLoadingState(state),
  activeOrder: getCheckoutActiveOrder(state),
  activeOrderItem: getCheckoutSelectedOrderItem(state)
});

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, {}> = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      fetchCheckoutOrders: fetchCheckoutAsync.request,
      updateCheckoutOrders: updateOrdersAsync.request,
      submitCheckoutOrder: finalizeOrderAsync.request,
      submitAllCheckoutOrders: finalizeAllOrdersAsync.request,
      cancelOrders: cancelCheckout,
      setActiveOrderIndex: setActiveOrder,
      setActiveOrderItem: selectOrderItem,
      unsetActiveOrderItem: unselectOrderItem
      // makeOffer: addCartItemAsync.request,
      // selectProduct: (product: Product) => selectProductAsync.request(product),
      // openMiniCart
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles<any>(styles)(UnconnectedCheckout as any));
